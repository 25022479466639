import { Validators } from '@angular/forms';
import { patronValidacion } from 'src/app/onboarding/enums/validaciones';
import { Validation } from 'src/app/onboarding/utils/validation';

export class InformacionTomadorSoatForm{
    static getForm(): any{
        return [
            {
              label: 'Tipo de documento *',
              value: '',
              disabled: false,
              readonly: false,
              visible: true,
              formControlName: 'tipoDocumento',
              placeholder: '',
              opciones: [],
              bindValue: 'idTipoDocumento',
              bindLabel: 'descripcionTipoDocumento',
              type: 'select',
              col: 'col-12',
              validations:  [ Validators.required ],
              errors: [
                { error: 'required', message: 'Campo requerido'}
              ],
            },
            {
              label: 'Número de documento *',
              value: '',
              disabled: false,
              readonly: false,
              visible: true,
              formControlName: 'numeroDocumento',
              placeholder: '',
              type: 'number',
              col: 'col-12',
              validations:  [ Validators.required, Validators.pattern(patronValidacion.soloNumeros) ],
              errors: [
                { error: 'required', message: 'Campo requerido'},
                { error: 'pattern', message: 'Se aceptan solo números' },
                { error: 'minlength', message: 'Debe tener mínimo {{minlength}} caracteres'},
                { error: 'maxlength', message: 'Debe tener máximo {{maxlength}} caracteres'}
              ],
            },
          //   {
          //       label: 'Primer nombre *',
          //       value: '',
          //       disabled: false,
          //       readonly: false,
          //       visible: true,
          //       formControlName: 'primerNombre',
          //       placeholder: '',
          //       type: 'text',
          //       col: 'col-12',
          //       validations:  [ Validators.required,  Validators.pattern(patronValidacion.soloTexto), Validators.minLength(2)],
          //       errors: [
          //         { error: 'required', message: 'Campo requerido'},
          //         { error: 'pattern', message: 'No se aceptan números o caracteres especiales'},
          //         { error: 'minlength', message: 'Debe tener más de dos letras'}
          //       ],
          //   },
          //   {
          //     label: 'Segundo nombre',
          //     value: '',
          //     disabled: false,
          //     readonly: false,
          //     visible: true,
          //     formControlName: 'segundoNombre',
          //     placeholder: '',
          //     type:  'text',
          //     col: 'col-12',
          //     validations: [Validators.pattern(patronValidacion.soloTexto), Validators.minLength(2) ],
          //     errors: [
          //       { error: 'pattern', message: 'No se aceptan números o caracteres especiales'},
          //       { error: 'minlength', message: 'Debe tener más de dos letras'}
          //     ],
          // },
          //   {
          //     label: 'Primer apellido *',
          //     value: '',
          //     disabled: false,
          //     readonly: false,
          //     visible: true,
          //     formControlName: 'primerApellido',
          //     placeholder: '',
          //     type: 'text',
          //     col: 'col-12',
          //     validations:  [ Validators.required, Validators.pattern(patronValidacion.soloTexto), Validators.minLength(2) ],
          //     errors: [
          //       { error: 'required', message: 'Campo requerido'},
          //       { error: 'pattern', message: 'No se aceptan números o caracteres especiales'},
          //       { error: 'minlength', message: 'Debe tener más de dos letras'}
          //     ],
          //   },
          //   {
          //     label: 'Segundo apellido',
          //     value: '',
          //     disabled: false,
          //     readonly: false,
          //     visible: true,
          //     formControlName: 'segundoApellido',
          //     placeholder: '',
          //     type: 'text',
          //     col: 'col-12',
          //     validations: [Validators.pattern(patronValidacion.soloTexto), Validators.minLength(2) ],
          //     errors: [
          //       { error: 'pattern', message: 'No se aceptan números o caracteres especiales'},
          //       { error: 'minlength', message: 'Debe tener más de dos letras'}
          //     ],
          //   },
            // {
            //   label: 'Razón social *',
            //   value: '',
            //   disabled: false,
            //   readonly: false,
            //   visible: false,
            //   formControlName: 'razonSocial',
            //   placeholder: '',
            //   type: 'text',
            //   col: 'col-12',
            //   validations:  [ Validators.required ],
            //   errors: [
            //     { error: 'required', message: 'Campo requerido'}
            //   ],
            // },
            {
              label: 'Fecha de nacimiento',
              value: "",
              disabled: false,
              readonly: false,
              visible: true,
              formControlName: 'fechaNacimiento',
              placeholder: '',
              type: 'date',
              col: 'col-12',
              validations:  [ Validators.required, Validation.rangeDate ],
              errors: [
                { error: 'required', message: 'Campo requerido'},
                { error: 'rangeDate', message: 'Tiene que ser mayor de 16 años'}
              ]
            },
            {
                label: 'Celular *',
                value: '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'numeroCelular',
                placeholder: '',
                type: 'number',
                col: 'col-12',
                validations:  [ Validators.required, Validators.minLength(10), Validators.maxLength(10) ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'minlength', message: 'Debe tener al menos 10 caracteres'},
                    { error: 'maxlength', message: 'Debe tener  máximo 10 caracteres'}
                ],
            },
            {
                label: 'Correo electrónico *',
                value: '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'email',
                placeholder: '',
                type: 'email',
                col: 'col-12',
                validations:  [ Validators.required , Validators.pattern(patronValidacion.email)],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Formato incorrecto'}
                ],
            },
            {
              label: 'Departamento *',
              value: '',
              disabled: false,
              readonly: false,
              visible: true,
              formControlName: 'codDepartamento',
              placeholder: '',
              type: 'select',
              col: 'col-12',
              opciones: [],
              bindValue: 'idDepartamento',
              bindLabel: 'nombreDepartamento',
              validations:  [ Validators.required ],
              errors: [
                { error: 'required', message: 'Campo requerido'}
              ],
            },
            {
              label: 'Ciudad *',
              value: '',
              disabled: true,
              readonly: false,
              visible: true,
              formControlName: 'codMunicipio',
              placeholder: '',
              type: 'select',
              col: 'col-12',
              opciones: [],
              bindValue: 'idCiudad',
              bindLabel: 'nombreCiudad',
              validations:  [ Validators.required ],
              errors: [
                { error: 'required', message: 'Campo requerido'}
              ],
            },
            {
              label: 'Dirección de residencia',
              value: '',
              disabled: false,
              readonly: false,
              visible: true,
              formControlName: 'direccion',
              placeholder: '',
              type: 'text',
              col: 'col-12',
              validations:  [ Validators.required, Validators.maxLength(100)],
              errors: [
                  { error: 'required', message: 'Campo requerido'},
                  { error: 'maxlength', message: 'No sobrepasar los 100 caracteres de longitud'}
              ],
            }
        ];
    }
}
