import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { GeneralCoreService } from '../../services/general-core/general-core.service';

export const generalInterceptor: HttpInterceptorFn = (req, next) => {
  let countRequest = 0;
  const generalCoreService = inject(GeneralCoreService);
  const urlBlackList = validateBlackList(req.url);

  if (countRequest === 0 && urlBlackList === false) {
    generalCoreService.openSpinner(true);
  }

  if (!urlBlackList) {
    countRequest++;
  }

  return next(req).pipe(
    finalize(() => {
      if (!validateBlackList(req.url)) {
        countRequest--;
      }
      if (countRequest === 0) {
        generalCoreService.openSpinner(false);
      }
    })
  );
};

const validateBlackList = (url: string): boolean => {
  let contieneUrlLista = false;

  const lista: string[] = ['Cotizacion/Coberturas', 'tucotizacion/Polizas'];

  lista.forEach((element: any) => {
    if (url.includes(element)) {
      contieneUrlLista = true;
    }
  });

  return contieneUrlLista;
};
