<div class="mt-5 mb-4 text-center">
  <h3 class="text-primary w-75 fw-light mx-auto title__onboarding mb-0" [innerHTML]="getPasoVisible().titulo"></h3>
  <small class="w-50" [innerHTML]="getPasoVisible().subtitulo"></small>
</div>

<div *ngFor="let item of pasosNew">
  <app-informacion-bici 
    (eventoGuardar)="guardar($event)" 
    *ngIf="item.visible && item.paso == 'datosBici'">
  </app-informacion-bici>
  <app-informacion-tomador-bici
    (eventoVolver)="volver($event)"
    (eventoGuardar)="guardar($event)" 
    *ngIf="item.visible && item.paso == 'datosTomador'"
  ></app-informacion-tomador-bici>
  <app-informacion-planes
    (eventoVolver)="volver($event)"
    (eventoGuardar)="guardar($event)" 
    *ngIf="item.visible && item.paso == 'datosPlanes'"
  ></app-informacion-planes>
  <app-informacion-pago
    (eventoVolver)="volver($event)"
    (eventoGuardar)="guardar($event)"
    *ngIf="item.visible && item.paso == 'datosPago'"
  ></app-informacion-pago>
</div>