import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalTerminosComponent } from '../modal-terminos/modal-terminos.component';
import { MsgErrorComponent } from '../msg-error/msg-error.component';

@Component({
    selector: 'app-terminos-politicas',
    templateUrl: './terminos-politicas.component.html',
    styleUrls: ['./terminos-politicas.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, MsgErrorComponent],
})
export class TerminosPoliticasComponent implements OnInit {
  @Input() form: UntypedFormGroup;

  errors: any[] = [{ error: 'required', message: 'Campo requerido' }];

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    this.form.addControl(
      'politicas',
      new UntypedFormControl('', Validators.requiredTrue)
    );
  }

  openModal() {
    const dialogRef = this.dialog.open(ModalTerminosComponent);
    dialogRef.afterClosed().subscribe((result) => {});
  }
}
