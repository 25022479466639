export class OnboardingAccidentes {
  public plan: any;
  public tomador: any;
  public asegurado: any;
  public tarifa: any;
  public siguienteComponente: string;
  public tuCotizacionId: number;

  constructor(data: any) {
    this.plan = data?.plan ?? null;
    this.tomador = data?.tomador ?? null;
    this.asegurado = data?.asegurado ?? null;
    this.tarifa = data?.tarifa ?? null;
    this.siguienteComponente = data?.siguienteComponente ?? '';
    this.tuCotizacionId = data?.tuCotizacionId ?? null;
  }
}