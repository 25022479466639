import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { AccidentesService } from 'src/app/onboarding/services/accidentes/accidentes.service';
import { ViajesService } from 'src/app/onboarding/services/viajes/viajes.service';
import { InformacionPasajerosForm } from '../components/informacion-pasajeros/informacion-pasajeros.form';
import { PasarelaService } from 'src/app/onboarding/services/pasarela/pasarela.service';
import { IpService } from 'src/app/shared/services/ip/ip.service';
import { GeneralCoreService } from 'src/app/core/services/general-core/general-core.service';
import { AuthService } from 'src/app/sesion/services/auth/auth.service';
import { CardResumenCompraComponent } from '../components/card-resumen-compra/card-resumen-compra.component';
import { DetallePlanViajeComponent } from '../components/detalle-plan-viaje/detalle-plan-viaje.component';
import { InformacionPlanPersonalizadoComponent } from '../../informacion-plan-personalizado/informacion-plan-personalizado.component';
import { BotonesOnboardingComponent } from '../../botones-onboarding/botones-onboarding.component';
import { InformacionPasajerosComponent } from '../components/informacion-pasajeros/informacion-pasajeros.component';
import { PlanViajesComponent } from '../components/plan-viajes/plan-viajes.component';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-onboarding-viajes',
    templateUrl: './onboarding-viajes.component.html',
    styleUrls: ['./onboarding-viajes.component.scss'],
    standalone: true,
    imports: [NgIf, PlanViajesComponent, ReactiveFormsModule, NgFor, InformacionPasajerosComponent, BotonesOnboardingComponent, InformacionPlanPersonalizadoComponent, DetallePlanViajeComponent, NgClass, CardResumenCompraComponent]
})
export class OnboardingViajesComponent implements OnInit {
  public pasosNew: any[] = [
    {
      paso: 'planViajes',
      estado: false,
      visible: true,
      titulo: 'Elige el plan o <b class="fw-bold">modifica las coberturas</b>'
    },
    {
      paso: 'informacionPasajeros',
      estado: false,
      visible: true,
      titulo: 'Agrega la <b class="fw-bold">información de los pasajeros</b>'
    },
    {
      paso: 'modificarPlan',
      estado: false,
      visible: true,
      titulo: 'Puedes <b class="fw-bold">modificar tu plan</b>'
    },
    {
      paso: 'resumenPlan',
      estado: false,
      visible: true,
      titulo: 'Resumen de tu <b class="fw-bold">seguro de viaje</b>'
    },

  ];
  public pasoActual = this.pasosNew[0]
  public coberturasViajes: any;
  public dataViaje: any;
  public pasajerosForm: UntypedFormGroup;
  public listaFormulario: any[] = [];
  public mostrarDetallePlan = false;
  public mostrarTotalPlan = false;
  public resumenPasajeros = [];
  public idMetodo = 0;
  public dataLocalTomador: any;
  public valorTarifa = '';

  constructor(private viajesService: ViajesService,
              private fb: UntypedFormBuilder,
              private accidentesService: AccidentesService,
              private pasarelaService: PasarelaService,
              private ipService: IpService,
              public generalCoreService: GeneralCoreService,
              private authService: AuthService
              ) 
    {
      this.listaFormulario = InformacionPasajerosForm.getForm();
    }

  ngOnInit(): void {
    this.dataLocalTomador = JSON.parse(localStorage.getItem('tomadorViajes'))
    
    this.obtenerCoberturas()
    this.pasajerosForm = this.fb.group({
      pasajeros: this.fb.array([])
    });

    for (let i = 0; i < this.dataViaje?.pasajeros; i++) {
      this.pasajeros.push(this.crearFormulario()); 
    }
    this.obtenerDepartamentos();
    this.obtenerTipoDocumento();
  }

  get pasajeros(): UntypedFormArray{
    return this.pasajerosForm.get('pasajeros') as UntypedFormArray
  }

  public obtenerDepartamentos() {
    this.accidentesService.obtenerDepartamentos().subscribe( (resp: any) => {
      this.listaFormulario.forEach( el => {
        if (el.formControlName === 'codDepartamento') {
            el.opciones = resp.data;
        }
      });
    });
  }

  public obtenerTipoDocumento() {
    this.accidentesService.obtenerTipoDocumento().subscribe( (resp: any) => {
      this.listaFormulario.forEach( el => {
        if (el.formControlName === 'tipoDocumento') {
            el.opciones = resp.data;
        }
      });
    });
  }

  private crearFormulario(): UntypedFormGroup {
    const formulario = new UntypedFormGroup({});
    this.listaFormulario.forEach((element) => {
      formulario.addControl(
        element.formControlName,
        new UntypedFormControl(
          {
            value: element.value,
            disabled: element.disabled,
          },
          element.validations
        )
      );
    });

    return formulario;
  }

  public obtenerCoberturas() {
    this.coberturasViajes = JSON.parse(localStorage.getItem('onboardingViajes'))?.coberturas
    this.dataViaje = JSON.parse(localStorage.getItem('onboardingViajes'))?.dataViaje
  }

  public eventoCambiarValor(valor) {
    this.viajesService.consultarTarifa(valor).subscribe((resp: any) => {
      localStorage.setItem('tarifaViaje', JSON.stringify(resp.data))
      this.valorTarifa = resp.data.primaTotalImpuestos
    })
  }

  public cambiarPasoActual() {
    this.pasoActual = this.pasosNew[0]
    this.mostrarDetallePlan = false
    this.mostrarTotalPlan = false;
  }

  public eventoInfoPasajeros() {
    this.pasoActual = this.pasosNew[1]
    this.mostrarDetallePlan = true;
    this.mostrarTotalPlan = true;
  }

  public eventoPlanViajes() {
    this.pasoActual = this.pasosNew[2]
    this.mostrarDetallePlan = false;
    this.mostrarTotalPlan = true;
  }

  public enviarPasajeros(){
    if (this.pasajerosForm.invalid) {
      this.pasajerosForm.markAllAsTouched()
    } else {
      const data = {
        tuCotizacionId: this.coberturasViajes.tuCotizacionId,
        asegurados: this.pasajeros.value.map((pasajero: any) => {
          return {
            persona: {
              ...pasajero,
              direccion: {
                  ciudad: pasajero.codMunicipio?.descripcion,
                  departamento: pasajero.codMunicipio?.dsdpto,
                  direccion: pasajero.direccion,
                  nombreCiudad: pasajero.codMunicipio?.descripcion,
              },
          }
          }
        })
      } 
      this.resumenPasajeros = data.asegurados
      localStorage.setItem('tomadorViajes', JSON.stringify(data))
      
      this.viajesService.enviarDatosTomadorViajes(data).subscribe((resp: any) => {        
        this.pasoActual = this.pasosNew[3]
      })
    }
    
  }

  public eventoSiguiente() {
    if (this.pasoActual === this.pasosNew[1]) {
      this.enviarPasajeros()
      return 
    }
    if (this.pasoActual === this.pasosNew[2]) {
      this.pasoActual = this.pasosNew[1]
      
    }
  }

  public changeMetodo(pago): void {
    this.idMetodo = pago;
  }

  public pagar() {
    this.pasarelaService.realizarPagoSura(
      {
        tuCotizacionId: this.coberturasViajes.tuCotizacionId,
        ipAddr: "127.0.0.1",
      }
    ).subscribe((resp: any) => {
      localStorage.removeItem('onboardingViajes');
      localStorage.removeItem('tomadorViajes');
      localStorage.removeItem('tarifaViaje');
      window.open(resp.data.urlPago, '_self');
    })
  }
  
  public existeUsuario() {
    this.accidentesService
      .existeUsuario(this.coberturasViajes.tuCotizacionId)
      .subscribe((resp) => {
        if (resp.data) {
          this.generalCoreService.openDialog({
            titulo: 'Usuario registrado',
            mensaje: resp.message,
            tipo: 'warning',
            confirmButtonText: 'Si, vincular',
            cancelButtonText: 'No, cancelar',
            hasCancelButton: true,
            callbackConfirm: () => {
              this.enviarCodigoEmail();
            },
            callbackCancel: () => {
              this.cambiarTokenCliente();
            },
          });
        } else {
          this.cambiarTokenCliente();
        }
      });
  }
  
  public enviarCodigoEmail() {
    this.accidentesService
      .enviarCodigoEmail(this.coberturasViajes.tuCotizacionId)
      .subscribe((resp) => {
        this.generalCoreService.openDialog({
          componente: 'otp',
          titulo: 'Código de verificación',
          mensaje: `Se ha enviado un código al correo ${this.dataLocalTomador?.datosTomador?.email}`,
          tipo: 'warning',
          confirmButtonText: 'Verificar código',
          cancelButtonText: 'Cancelar',
          hasCancelButton: true,
          callbackConfirm: (value) => {
            this.cambiarTokenCliente(value.otp);
          },
        });
      });
  }
  
  public cambiarTokenCliente(codigo = null) {
    const dataSession = this.authService.getSession();
    if (dataSession.roles[0] === 'Onbording') {
      this.authService
        .getTokenCliente({
          tuCotizacionId: this.coberturasViajes.tuCotizacionId,
          codigo: codigo,
        })
        .subscribe((resp) => {
          this.authService.setSession(resp.data);
          this.pagar();
        });
    } else {
      this.pagar();
    }
  }
  
  public finalizar(): void {
    const dataSession = this.authService.getSession();
    if (dataSession.roles[0] == 'Onbording') {
      this.existeUsuario();
    } else {
      this.cambiarTokenCliente();
    }
  }
}

