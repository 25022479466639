<footer class="footer">
  <div class="container mt-5 pt-5 pb-3">
    <div class="row text-center text-lg-start">
      <div class="col-lg-6 mb-3 pe-lg-5">
        <div class="d-flex flex-column flex-lg-row text-center">
          <div class="me-4 mb-3">
            <img
              src="assets/images/logo.webp"
              alt="logo TuPrimero"
              height="55"
              width="60"
            />
          </div>
          <div class="text-lg-start mx-3 mx-sm-0">
            <strong>Facilitando el acceso a seguros en Latam</strong>
            <p class="fw-light">Por la inclusión y el desarrollo sostenible</p>
          </div>
        </div>
        <div
          class="d-flex justify-content-lg-start justify-content-around pe-2 mb-3"
        >
          <div *ngFor="let red of listRedes" class="me-lg-4">
            <a [href]="red.url" target="_blank">
              <div class="bg-primary rounded-circle icon-social">
                <img [src]="red.imagen" [alt]="red.nombre" class="img-fluid" />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="col mb-3">
        <div>
          <h6 class="text-primary footer-list-title">Empresa</h6>
          <ul class="list-unstyled footer-list-menu fw-light">
            <li class="cursor-pointer" [routerLink]="['/nosotros']">
              Nosotros
            </li>
            <li class="cursor-pointer" [routerLink]="['/blog']">Noticias</li>
            <li class="cursor-pointer" [routerLink]="['/blog']">Blog</li>
            <li class="cursor-pointer" [routerLink]="['/glosario']">
              Glosario
            </li>
            <li class="cursor-pointer" [routerLink]="['/login']">Mi cuenta</li>
          </ul>
        </div>
      </div>
      <div class="col mb-3">
        <div>
          <h6 class="text-primary footer-list-title">Seguros</h6>
          <ul class="list-unstyled footer-list-menu fw-light">
            <li
              class="cursor-pointer"
              [routerLink]="['/productos/movilidad/auto']"
            >
              Auto
            </li>
            <li
              class="cursor-pointer"
              [routerLink]="['/productos/movilidad/moto']"
            >
              Moto
            </li>
            <li
              class="cursor-pointer"
              [routerLink]="['/productos/movilidad/bicicleta']"
            >
              Bici y patineta
            </li>
            <li
              class="cursor-pointer"
              [routerLink]="['/productos/movilidad/accidentes']"
            >
              Accidentes personales
            </li>
            <li
              class="cursor-pointer"
              [routerLink]="['/productos/movilidad/viajes']"
            >
              Viajes
            </li>
            <li class="cursor-pointer" [routerLink]="['/tailor-made']">
              Pymes
            </li>
            <li class="cursor-pointer" [routerLink]="['/agro']">Agro</li>
          </ul>
        </div>
      </div>

      <div class="col mb-3">
        <div>
          <h6 class="text-primary footer-list-title">Contacto</h6>
          <ul class="list-unstyled footer-list-menu fw-light">
            <li class="cursor-pointer">
              <a href="mailto:info@tuprimero.com.co"
                >info&#64;tuprimero.com.co
              </a>
            </li>
            <li class="cursor-pointer">
              <a href="tel:+573204843918"></a> (+57) 320 484 3918, 704 6418
            </li>
            <li class="cursor-pointer">
              Calle 117 # 6a - 60 oficina 411 Bogotá, Colombia
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
  <div class="container-fluid bg-light-50 pb-5 pb-lg-0">
    <div
      class="container py-3 d-flex align-items-center justify-content-lg-start flex-column-reverse flex-md-row"
    >
      <p class="fw-light mb-0 small me-lg-5">
        Copyright © {{ year }} TuPrimero
      </p>
      <div class="d-flex flex-column flex-md-row text-center text-md-start">
        <p
          class="text-politicas fw-light mb-0 me-md-5 small cursor-pointer mb-2 mb-md-0"
          [routerLink]="['/politicas']"
        >
          Política de tratamiento de datos
        </p>
        <p
          class="fw-light mb-0 me-md-5 small cursor-pointer mb-2 mb-md-0"
          [routerLink]="['/terminos']"
        >
          Términos y condiciones
        </p>
        <a
          class="fw-light mb-0 me-md-5 small cursor-pointer mb-2 mb-md-0 text-decoration-none text-body"
          href="https://www.sic.gov.co"
          target="_black"
          >Superintendencia de Industria y Comercio</a
        >
      </div>
    </div>
  </div>
</footer>
<!-- Footer end -->
