<div class="card mb-4">
  <div class="card-body">
    <div class="d-flex justify-content-between align-items-center">
      <h5 class="text-primary mb-4">Pasajero {{index + 1}}</h5>
    </div>
    <form  [formGroup]="formulario"  class="row " *ngIf="formulario">
      <div [class]="item.col" *ngFor="let item of listaFormulario" class="mb-3 pe-4">
        <div class="form-floating" *ngIf="item.type != 'radio' && item.type != 'select' && item.type != 'date'" >
          <input class="form-control" id="input-{{item.formControlName}}" [formControlName]="item.formControlName" placeholder="item.placeholder" [type]="item.type"> 
          <label for="input-{{item.formControlName}}">{{item.label}}</label>
        </div>
        <div *ngIf="item.type == 'date'"  class="d-flex flex-column">
          <label id="label-{{item.formControlName}}" for="input-{{item.formControlName}}" class="form-label label-select">{{item.label}}</label>
          <p-calendar ngDefaultControl 
                      class="d-grid mt-1" 
                      inputStyleClass="form-control" 
                      ariaLabelledBy="label-{{item.formControlName}}" 
                      dateFormat="dd/mm/yy" 
                      inputId="{{item.formControlName}}" 
                      [type]="item.type"  
                      [formControlName]="item.formControlName"
                      placeholder="dd/mm/yyyy" 
                      [monthNavigator]="true" 
                      [yearNavigator]="true"
                      [maxDate]="maxDate"
                      [yearRange]="yearRange"
          ></p-calendar>
        </div>
        <div *ngIf="item.type == 'radio'">
          <label for="input-{{item.formControlName}}" class="form-label w-100">{{item.label}}</label>
          <div class="form-check form-check-inline" *ngFor="let op of item.options">
            <input 
              id="input-{{item.formControlName}}-{{op.descripcion}}"
              [formControlName]="item.formControlName"
              class="form-check-input" 
              [value]="op.codigo | lowercase"
              type="radio">
            <label class="form-check-label" for="flexRadioDefault1">
              {{op.descripcion | titlecase}}
            </label>
          </div>       
        </div>
        <div *ngIf="item.type == 'select'">
          <label for="input-{{item.formControlName}}" id="{{item.formControlName}}" class="form-label label-select">{{item.label}}</label>
          <ng-select 
            ngDefaultControl
            [formControlName]="item.formControlName"
            id="item.formControlName"
            [items]="item.opciones"
            [bindLabel]="item.bindLabel" 
            [bindValue]="item.bindValue" 
            placeholder="Seleccionar"
            [searchable]="true"
            [notFoundText]="'No hay datos'"
            aria-labelledby="item.formControlName">
        </ng-select>
        </div> 
          <app-msg-error 
            [messages]="item.errors"
            [control]="formulario.controls[item.formControlName]"
            ></app-msg-error>
      </div>
  </form>
  </div>
</div>