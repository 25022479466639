import { Validators } from '@angular/forms';
import { patronValidacion } from 'src/app/onboarding/enums/validaciones';
import { Validation } from 'src/app/onboarding/utils/validation';

export class InformacionTomadorMotosForm{
    static getForm(): any{
        return [
            {
                label: 'Primer nombre *',
                value: '',
                disabled: false,
                readonly: false,
                formControlName: 'primerNombre',
                placeholder: '',
                type: 'text',
                col: 'col-md-6',
                validations:  [ Validators.required,  Validators.pattern(patronValidacion.soloTexto), Validators.minLength(2)],
                errors: [
                  { error: 'required', message: 'Campo requerido'},
                  { error: 'pattern', message: 'No se aceptan números o caracteres especiales'},
                  { error: 'minlength', message: 'Debe tener más de dos letras'}
                ],
            },
            {
              label: 'Segundo nombre',
              value: '',
              disabled: false,
              readonly: false,
              formControlName: 'segundoNombre',
              placeholder: '',
              type:  'text',
              col: 'col-md-6',
              validations: [Validators.pattern(patronValidacion.soloTexto), Validators.minLength(2) ],
              errors: [
                { error: 'pattern', message: 'No se aceptan números o caracteres especiales'},
                { error: 'minlength', message: 'Debe tener más de dos letras'}
              ],
          },
            {
              label: 'Primer apellido *',
              value: '',
              disabled: false,
              readonly: false,
              formControlName: 'primerApellido',
              placeholder: '',
              type: 'text',
              col: 'col-md-6',
              validations:  [ Validators.required, Validators.pattern(patronValidacion.soloTexto), Validators.minLength(2) ],
              errors: [
                { error: 'required', message: 'Campo requerido'},
                { error: 'pattern', message: 'No se aceptan números o caracteres especiales'},
                { error: 'minlength', message: 'Debe tener más de dos letras'}
              ],
            },
            {
              label: 'Segundo apellido',
              value: '',
              disabled: false,
              readonly: false,
              formControlName: 'segundoApellido',
              placeholder: '',
              type: 'text',
              col: 'col-md-6',
              validations: [Validators.pattern(patronValidacion.soloTexto), Validators.minLength(2) ],
              errors: [
                { error: 'pattern', message: 'No se aceptan números o caracteres especiales'},
                { error: 'minlength', message: 'Debe tener más de dos letras'}
              ],
            },
            {
              label: 'Tipo de documento *',
              value: '',
              disabled: false,
              readonly: false,
              formControlName: 'tipoDoumento',
              placeholder: '',
              opciones: [],
              type: 'select',
              col: 'col-md-6',
              validations:  [ Validators.required ],
              errors: [
                { error: 'required', message: 'Campo requerido'}
              ],
            },
            {
              label: 'Número de documento *',
              value: '',
              disabled: false,
              readonly: false,
              formControlName: 'numeroDocumento',
              placeholder: '',
              type: 'number',
              col: 'col-md-6',
              validations:  [ Validators.required, Validators.pattern(patronValidacion.soloNumeros) ],
              errors: [
                { error: 'required', message: 'Campo requerido'}
              ],
            },
            {
              label: 'Fecha de nacimiento',
              value: '',
              disabled: false,
              readonly: false,
              formControlName: 'fechaNacimiento',
              placeholder: '',
              type: 'date',
              col: 'col-md-6',
              validations:  [ Validators.required, Validation.rangeDate ],
              errors: [
                { error: 'required', message: 'Campo requerido'},
                { error: 'rangeDate', message: 'Tiene que ser mayor de 16 años'}
              ]
            },
            {
                label: 'Género *',
                value: '',
                disabled: false,
                readonly: false,
                formControlName: 'genero',
                placeholder: '',
                type: 'radio',
                col: 'col-md-6',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'}
                ],
                options: [
                    {
                        codigo: 'F',
                        descripcion: 'femenino'
                    },
                    {
                        codigo: 'M',
                        descripcion: 'masculino'
                    }
                ]
            },
            {
                label: 'Celular *',
                value: '',
                disabled: false,
                readonly: false,
                formControlName: 'numeroCelular',
                placeholder: '',
                type: 'number',
                col: 'col-md-6',
                validations:  [ Validators.required, Validators.minLength(10), Validators.maxLength(10) ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'minlength', message: 'Debe tener al menos 10 caracteres'},
                    { error: 'maxlength', message: 'Debe tener  máximo 10 caracteres'}
                ],
            },
            {
                label: 'Correo electrónico *',
                value: '',
                disabled: false,
                readonly: false,
                formControlName: 'email',
                placeholder: '',
                type: 'email',
                col: 'col-md-6',
                validations:  [ Validators.required , Validators.pattern(patronValidacion.email)],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Formato incorrecto'}
                ],
            },
            {
              label: 'Ciudad de residencia *',
              value: '',
              disabled: false,
              readonly: false,
              formControlName: 'codMunicipio',
              placeholder: '',
              type: 'select',
              col: 'col-md-6',
              opciones: [],
              validations:  [ Validators.required ],
              errors: [
                { error: 'required', message: 'Campo requerido'}
              ],
            },
            {
              label: 'Tipo dirección *',
              value: '',
              disabled: false,
              readonly: false,
              formControlName: 'tipoDireccion',
              placeholder: '',
              type: 'select',
              col: 'col-md-6',
              opciones: [],
              validations:  [ Validators.required ],
              errors: [
                { error: 'required', message: 'Campo requerido'}
              ],
            },
            {
              label: 'Dirección de residencia',
              value: '',
              disabled: false,
              readonly: false,
              formControlName: 'descripcion',
              placeholder: '',
              type: 'email',
              col: 'col-md-6',
              validations:  [ Validators.required ],
              errors: [
                  { error: 'required', message: 'Campo requerido'}
              ],
            }
        ];
    }
}
