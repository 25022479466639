import { Component, Input, OnInit } from '@angular/core';
import { FeatherModule } from 'angular-feather';
import { NgClass, TitleCasePipe } from '@angular/common';

@Component({
    selector: 'app-card-blog-main',
    templateUrl: './card-blog-main.component.html',
    styleUrls: ['./card-blog-main.component.scss'],
    standalone: true,
    imports: [NgClass, FeatherModule, TitleCasePipe]
})
export class CardBlogMainComponent implements OnInit {
  @Input() detalleCard: any;

  constructor() { }

  ngOnInit(): void {
  }

  getColorTipo(tipo) {
    return {
      'badge-warning': tipo === 'bicicleta', 'badge-primary': tipo === 'auto', 'badge-secondary': tipo === 'moto', 'badge-success': tipo === 'seguros'
    }
  }

}
