import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { GeneralCoreService } from 'src/app/core/services/general-core/general-core.service';
import { OnboardingService } from 'src/app/onboarding/services/onboarding/onboarding.service';

import { OnBoardingSoat } from 'src/app/onboarding/models/onboardingSoat.model';
import { InformacionTomadorSoatComponent } from '../informacion-tomador-soat/informacion-tomador-soat.component';
import { InformacionSoatComponent } from '../informacion-soat/informacion-soat.component';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-onboarding-soat',
    templateUrl: './onboarding-soat.component.html',
    styleUrls: ['./onboarding-soat.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, InformacionSoatComponent, InformacionTomadorSoatComponent]
})
export class OnboardingSoatComponent implements OnInit {
  onboardingData: OnBoardingSoat;
  pasoActual = '';

  pasos: any = {
    datosSoat: true,
    datosTomador: false,
    datosFinalizar: false,
  };

  pasosNew: any[] = [
    {
      paso: 'datosSoat',
      estado: false,
      visible: true,
      titulo: 'Para empezar vamos a necesitar la <b class="fw-bold">placa de tu vehículo</b>'
    },
    {
      paso: 'datosTomador',
      estado: false,
      visible: false,
      titulo: 'Para poder cotizar necesitamos los datos faltantes - Para cotizar tu SOAT ingresa los datos faltantes de tu vehículo'
    },
    {
      paso: 'datosFinalizar',
      estado: false,
      visible: false,
    },
  ];

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private generalCoreService: GeneralCoreService,
              private onboardingService: OnboardingService) {
                this.validaParametros();
               }

  ngOnInit(): void {
  }

  private validarPasoActual(paso: string, siguiente: boolean = false): void {

    if (siguiente){
      this.router.navigate(['/onboarding/soat'], { queryParams: { paso } });
    }else{
      if (!this.onboardingData[paso]){
        this.router.navigate(['/onboarding/soat'], { queryParams: { paso: 'datosSoat' } });
      }
    }
  }

  private validaParametros() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.paso) {
        let tempo = 0;
        this.pasosNew.forEach(e => {
          if (e.paso === params.paso) {
            tempo++;
          }
        });

        if (params.paso === 'datosPago') {
          if (!this.onboardingService.planSeleccionado) {
            this.router.navigate(['/onboarding/soat'], { queryParams: { paso: 'datosPlanes' } });
          }
        }

        if (tempo === 1) {
          if (this.onboardingData) {
            this.pasosNew.forEach(element => {
              element.visible = element.paso === params.paso ? true : false;
            });
          } else {
            this.router.navigate(['/onboarding/soat'], { queryParams: { paso: 'datosSoat' } });
          }

          this.pasosNew.forEach(element => {
            element.visible = element.paso === params.paso ? true : false;
          });
        } else {
          this.router.navigate(['/onboarding/soat'], { queryParams: { paso: 'datosSoat' } });
        }
      } else {
        if (this.onboardingData) {
          this.router.navigate(['/onboarding/soat'], { queryParams: { paso: this.onboardingData.siguienteComponente } });
        } else {
          this.router.navigate(['/onboarding/soat'], { queryParams: { paso: 'datosSoat' } });
        }
      }
    });
  }

  getPasoVisible() {
    return this.pasosNew.find(paso => paso.visible )
  }

  guardar(data: any): void{
    
    if (!this.onboardingData){
      this.onboardingData = new OnBoardingSoat({});
    }else{
      const dataOnb = JSON.parse(localStorage.getItem('onboarding'));
      this.onboardingData = new OnBoardingSoat(dataOnb);
    }

    this.onboardingData = {... this.onboardingData,  ... data};
    this.onboardingData.tuCotizacionId =  this.onboardingData.tuCotizacionId;

    localStorage.setItem('onboarding', JSON.stringify(this.onboardingData));
    this.validarPasoActual(data.siguienteComponente, true);
  }

  volver(data: any): void {
    this.router.navigate(['/onboarding/soat'], { queryParams: { paso: data.componente } });
  }


}
