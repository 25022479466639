import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GeneralCoreService } from 'src/app/core/services/general-core/general-core.service';

@Component({
    selector: 'app-spinner-loading',
    templateUrl: './spinner-loading.component.html',
    styleUrls: ['./spinner-loading.component.scss'],
    standalone: true,
})
export class SpinnerLoadingComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SpinnerLoadingComponent>,
    private generalCoreService: GeneralCoreService
  ) {}

  ngOnInit(): void {
    this.generalCoreService.spinner$.subscribe((resp) => {
      if (!resp) {
        this.dialogRef.close();
      }
    });
  }
}
