import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
    imports: [NgFor, RouterLink]
})
export class FooterComponent implements OnInit {
  year = new Date().getFullYear();

  public listRedes = [
    {
      imagen: '/assets/images/instagram.svg',
      nombre: 'Instagram',
      url: 'https://www.instagram.com/segurostuprimero/'
    },
    {
      imagen: '/assets/images/facebook.svg',
      nombre: 'Facebook',
      url: 'https://www.facebook.com/Segurostuprimero/'
    },
    {
      imagen: '/assets/images/linkedin.svg',
      nombre: 'Linkedind',
      url: 'https://www.linkedin.com/company/seguros-t%C3%BAprimero/'
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
