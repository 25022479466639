<div class="container">
  <div class="row">
    <div class="col-12 col-md-6 pt-md-5 mt-md-5 pe-md-5">
      <h1 class="pt-5 mt-md-5 text-primary fw-bold">Iniciar sesión</h1>
      <form action="" [formGroup]="formulario">
        <div class=" pe-lg-5">
          <div class="col-12 mt-5 form-floating">
            <input type="email" class="form-control" id="inputEmail4" formControlName="userName" placeholder="correo">
            <label for="inputEmail4" class="form-label">Correo electrónico</label>
          </div>
          <app-msg-error 
          [messages]="mensajes['userName']"
          [control]="formulario.controls['userName']"
          ></app-msg-error>
          <div class="col-12 mt-4 form-floating">
            <input [type]="tipoInput" class="form-control" id="inputPassword4" formControlName="passWord" placeholder="contraseña">
            <label for="inputPassword4" class="form-label">Contraseña</label>
            <i-feather name="eye" *ngIf="tipoInput === 'text'" class="iconEye cursor-pointer" (click)="cambiarTipoInput('password')"></i-feather>
            <i-feather name="eye-off" *ngIf="tipoInput === 'password'" class="iconEye cursor-pointer" (click)="cambiarTipoInput('text')"></i-feather>
          </div>
          <app-msg-error 
          [messages]="mensajes['passWord']"
          [control]="formulario.controls['passWord']"
          ></app-msg-error>
          <div class="d-flex justify-content-end mt-4">
            <a routerLink="/recuperar-clave">Olvidé mi contraseña</a>
          </div>
          <div class="d-flex align-items-center flex-column my-5">
            <button class="btn btn-primary mb-4 w-100" (click)="submit()">Iniciar sesión</button>
            <a routerLink="/registro" class="text-decoration-none">Crear cuenta</a>
          </div>
        </div>
      </form>
    </div>
    <div class="col-6 d-none d-md-inline-block position-fixed end-0">
      <img src="/assets/images/img-sesion.webp" alt="Seguros 100% digital y simple" class="h-100">
    </div>
  </div>
</div>