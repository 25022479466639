import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-detalle-plan-viaje',
    templateUrl: './detalle-plan-viaje.component.html',
    styleUrls: ['./detalle-plan-viaje.component.scss'],
    standalone: true,
    imports: [DatePipe]
})
export class DetallePlanViajeComponent implements OnInit {
  @Input() pasajero ;
  @Input() index = 0;

  constructor() { }

  ngOnInit(): void {
  }

}
