import { Component, OnInit } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { GeneralCoreService } from '../../services/general-core/general-core.service';
import { AuthService } from 'src/app/sesion/services/auth/auth.service';
import { NgIf } from '@angular/common';
import { FeatherModule } from 'angular-feather';

@Component({
    selector: 'app-onboarding-layout',
    templateUrl: './onboarding-layout.component.html',
    styleUrls: ['./onboarding-layout.component.scss'],
    standalone: true,
    imports: [FeatherModule, RouterLink, NgIf, RouterOutlet]
})
export class OnboardingLayoutComponent implements OnInit {
  public session: boolean;

  constructor(
    private router: Router,
    private generalCoreService: GeneralCoreService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    const dataSession = this.authService.getSession();
    this.session = dataSession?.roles[0] === 'Cliente' ? true : false;
  }

  salirCotizacion(): void{
    // this.generalCoreService.openDialog({
    //   titulo: 'Atención',
    //   mensaje: '¿Estas seguro que deseas salir de tu cotización?',
    //   tipo: 'error',
    //   hasCancelButton: true,
    //   callbackConfirm: () => {
    //     localStorage.removeItem('onboarding');
    //     this.router.navigate(['home']);
    //   }
    // });
  }

}
