import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { OnBoardingAutos } from '../../models/onboarding.model';
import { OnboardingService } from '../../services/onboarding/onboarding.service';
import { ViajesService } from 'src/app/onboarding/services/viajes/viajes.service';
import { BotonesOnboardingComponent } from '../botones-onboarding/botones-onboarding.component';
import { MsgErrorComponent } from '../../../shared/components/msg-error/msg-error.component';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-informacion-plan-personalizado',
    templateUrl: './informacion-plan-personalizado.component.html',
    styleUrls: ['./informacion-plan-personalizado.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, NgFor, MsgErrorComponent, BotonesOnboardingComponent]
})
export class InformacionPlanPersonalizadoComponent implements OnInit {

  @Output() eventoVolver: EventEmitter<any> = new EventEmitter();
  @Output() eventoGuardar: EventEmitter<any> = new EventEmitter();
  @Output() eventoCambiarValor: EventEmitter<any> = new EventEmitter();
  @Input() coberturasViajes: any;

  coberturas: any[] = [];
  formulario: UntypedFormGroup = new UntypedFormGroup({});
  dataLocalFormulario: OnBoardingAutos;
  dataValorCotizacion: any;

  errors: any[] =  [
    { error: 'required', message: 'Campo requerido'}
  ];

  constructor(private viajesService: ViajesService) {}

  ngOnInit(): void {
    // this.formulario = new FormGroup({});
    this.cargarDatosFormulario();
    // this.obtenerCoberturas()
    
    this.crearFormulario(this.coberturasViajes)
  }

  private cargarDatosFormulario(): void {
    // this.dataLocalFormulario = new OnBoardingAutos(JSON.parse(localStorage.getItem('onboarding')));

    // this.dataValorCotizacion = this.dataLocalFormulario.valorCotizacion;

    // if (this.dataLocalFormulario && this.dataLocalFormulario.datosCobertura) {
    //     this.coberturas = this.dataLocalFormulario.datosCobertura;
    //     this.crearFormulario(this.coberturas);
    //   }else{
    //     this.obtenerCoberturas();
    //   }
  }

  private crearFormulario(data: any[]): void {    
    data?.forEach(grupo => {      
      grupo.coberturas.forEach(cobertua => {        
        cobertua.terminos.forEach(opcionCobertura => {
          let valorCampo = opcionCobertura.cdOpcionSeleccionada;
          if (opcionCobertura.opciones.length === 1) {
            valorCampo = opcionCobertura.opciones[0].publicId;
          }
          if (opcionCobertura.requerido) {
            this.formulario.addControl(opcionCobertura.publicId, new UntypedFormControl(valorCampo, [Validators.required]));
          } else {
            this.formulario.addControl(opcionCobertura.publicId, new UntypedFormControl(valorCampo, []));
          }
      });
        });
    });
  }

  submit(): void{
    if (this.formulario.invalid){
      this.formulario.markAllAsTouched();
    }else{
      this.guardarSeleccion(this.formulario.getRawValue());
      const data: any = {
        siguienteComponente: 'datosPago',
        datosCobertura: this.coberturas,
        datosPago: true
      };
      this.eventoGuardar.emit(data);
    }
  }

  guardarSeleccion(dataForm: any): void {
    this.coberturas.forEach( grupo => {
      grupo.coberturas.forEach(cobertua => {
        cobertua.gruposOpciones.forEach( opcionCobertura => {
          for (const key in dataForm) {
            if (key === opcionCobertura.publicId){
              opcionCobertura.cdOpcionSeleccionada = dataForm[key];
            }
          }
        });
      });
    });
  }

  onCategoriaPressed(item: any, checked: boolean): void{
    this.coberturas.forEach( grupo => {
      grupo.coberturas.forEach(cobertua => {
        if (cobertua.publicId === item.publicId){
          cobertua.gruposOpciones.forEach( opcionCobertura => {
            if (checked){
              this.formulario.controls[opcionCobertura.publicId].setValidators([Validators.required]);
              this.formulario.controls[opcionCobertura.publicId].updateValueAndValidity();
            }else{
              this.formulario.controls[opcionCobertura.publicId].setValidators(null);
              this.formulario.controls[opcionCobertura.publicId].updateValueAndValidity();
            }
          });
        }
      });
    });
  }

  volver(): void {
    this.eventoVolver.emit();
  }

  onChangeSelect(data: any, cobertura: any, item: any): void{
    const dataViaje = JSON.parse(localStorage.getItem('onboardingViajes')).coberturas
    this.eventoCambiarValor.emit({
      tuCotizacionId: dataViaje.tuCotizacionId,
      GrupoCoberturas: [{
        CdGrupo: item.cdGrupo,
        Coberturas: [
          {
            CdCobertura: cobertura.cdCobertura,
            Terminos: [
              {
                CdGrupo: cobertura.terminos[0].publicId,
                CdOpcionSeleccionada: data,
              }
            ]
          }
        ]
      }]
    })

    // this.onboardingService.cotizacionCoberturas({
    //   tuCotizacionId: this.dataLocalFormulario.idCotizacion,
    //   grupoCoberturas: this.coberturas
    // }).subscribe( (resp:any) => {
    //   this.dataValorCotizacion  = resp.data;
    //   const data: any = {
    //     siguienteComponente:'datosCobertura',
    //     valorCotizacion: resp.data
    //   }
    //   this.eventoGuardar.emit(data);
    // });
  }

  getRandomArbitrary(min, max): number {
    return Math.random() * (max - min) + min;
  }
}
