import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GeneralCoreService } from 'src/app/core/services/general-core/general-core.service';
import { CiudadesCirculacion } from 'src/app/onboarding/models/ciudades-circulacion.interfaz';

import { InformacionVehiculo } from 'src/app/onboarding/models/informacion-vehiculo.interfaz';
import { OnboardingService } from 'src/app/onboarding/services/onboarding/onboarding.service';
import { ParametricosService } from 'src/app/onboarding/services/parametricos/parametricos.service';
import { AuthService } from 'src/app/sesion/services/auth/auth.service';

import { InformacionVehiculoForm } from './informacion-vehiculo.form';
import { BotonesOnboardingComponent } from '../../botones-onboarding/botones-onboarding.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FeatherModule } from 'angular-feather';
import { NgIf } from '@angular/common';
import { TerminosPoliticasComponent } from '../../../../shared/components/terminos-politicas/terminos-politicas.component';
import { MsgErrorComponent } from '../../../../shared/components/msg-error/msg-error.component';

@Component({
    selector: 'app-informacion-vehiculo',
    templateUrl: './informacion-vehiculo.component.html',
    styleUrls: ['./informacion-vehiculo.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MsgErrorComponent,
        TerminosPoliticasComponent,
        NgIf,
        FeatherModule,
        NgSelectModule,
        BotonesOnboardingComponent,
    ],
})
export class InformacionVehiculoComponent implements OnInit {
  @Output() eventoVolver: EventEmitter<any> = new EventEmitter();
  @Output() eventoGuardar: EventEmitter<any> = new EventEmitter();

  formulario: UntypedFormGroup;
  listaCiudades: CiudadesCirculacion[] = [];
  estadoConsultaPlaca = false;
  informacionVehiculo: InformacionVehiculo;
  dataFormulario: any;
  idCotizacion: number;
  usuarioOnBoarding = true;

  constructor(
    private onboardingService: OnboardingService,
    private parametricosService: ParametricosService,
    private generalCoreService: GeneralCoreService,
    private authService: AuthService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.dataFormulario = InformacionVehiculoForm.getForm();
    this.cargarDatosFormulario();
    this.obtenerCiudadesCirculacion();
  }

  private cargarDatosFormulario(): void {
    let valorPoliticas = false;
    const dataLocalFormulario = JSON.parse(localStorage.getItem('onboarding'));
    this.usuarioOnBoarding = this.authService.esUsuarioOnboarding();
    if (!this.usuarioOnBoarding) {
      valorPoliticas = true;
    }

    if (dataLocalFormulario && dataLocalFormulario.datosVehiculo) {
      this.informacionVehiculo = new InformacionVehiculo(
        dataLocalFormulario.datosVehiculo.informacionVehiculo
      );
      this.idCotizacion = dataLocalFormulario.datosVehiculo.idCotizacion;

      this.formulario = new UntypedFormGroup({
        placa: new UntypedFormControl(
          dataLocalFormulario.datosVehiculo.informacionVehiculo.placa,
          [Validators.required]
        ),
        ciudadCirculacion: new UntypedFormControl(
          dataLocalFormulario.datosVehiculo.ciudadCirculacion.id,
          [Validators.required]
        ),
        politicas: new UntypedFormControl(valorPoliticas, [
          Validators.requiredTrue,
        ]),
      });
    } else {
      this.formulario = new UntypedFormGroup({
        placa: new UntypedFormControl('', [Validators.required]),
        ciudadCirculacion: new UntypedFormControl('', [Validators.required]),
        politicas: new UntypedFormControl(valorPoliticas, [
          Validators.requiredTrue,
        ]),
      });
    }
  }

  onKeydown(event): void {
    if (event.key === 'Enter') {
      this.consultarPlaca();
    }
  }

  submit(): void {
    if (this.formulario.invalid) {
      this.formulario.markAllAsTouched();
    } else {
      const ciudad = this.listaCiudades.find(
        (m) => m.id === this.formulario.controls.ciudadCirculacion.value
      );
      const data: any = {
        siguienteComponente: 'datosTomador',
        idCotizacion: this.idCotizacion,
        datosPlanes: this.informacionVehiculo.planes,
        datosVehiculo: {
          informacionVehiculo: this.informacionVehiculo,
          ciudadCirculacion: ciudad,
          idCotizacion: this.idCotizacion,
        },
      };

      this.eventoGuardar.emit(data);
    }
  }

  consultarPlaca(): void {
    if (
      this.formulario.controls.placa.valid &&
      this.formulario.controls.politicas.valid
    ) {
      this.estadoConsultaPlaca = true;
      this.onboardingService
        .consultarPlacaVehiculo(this.formulario.controls.placa.value)
        .subscribe(
          (resp: any) => {
            this.estadoConsultaPlaca = false;
            this.informacionVehiculo = new InformacionVehiculo(resp.data);
            this.idCotizacion = resp.data.tuCotizacionId;
          },
          (err) => {
            this.estadoConsultaPlaca = false;
          }
        );
    } else {
      this.formulario.markAllAsTouched();
    }
  }

  obtenerCiudadesCirculacion(): void {
    this.parametricosService
      .obtenerParametricas('CiudadesCirculacion')
      .subscribe((resp: any) => (this.listaCiudades = resp));
  }

  nuevaCotizacion(): void {
    this.generalCoreService.openDialog({
      titulo: 'Nueva cotización',
      mensaje: `¿Deseas realizar una nueva cotización?`,
      tipo: 'warning',
      confirmButtonText: 'Si, cotizar',
      cancelButtonText: 'No, volver',
      hasCancelButton: true,
      callbackConfirm: () => {
        localStorage.removeItem('onboarding');
        location.reload();
      },
      callbackCancel: () => {},
    });
  }
}
