<div class="row">
  <div class="col-12 col-md-7 order-2 order-md-1">
    <div class="card">
      <div class="card-body row">
        <div>
          <h5 class="card-title mb-3 fw-bold">
            <i-feather class="text-primary me-2" name="calendar"></i-feather>
            Vigencia de la póliza
          </h5>
          <div class="col-12 mb-2 d-flex justify-content-between">
            <small class="text-grey-dark fw-bold">Fecha de inicio</small>
            <p class="card-text">{{data?.tarifa?.fechaInicio | date}}</p>
          </div>
          <div class="col-12 mb-2 d-flex justify-content-between">
            <small class="text-grey-dark fw-bold">Fecha de fin</small>
            <p class="card-text">{{data?.tarifa?.fechaFin | date}}</p>
          </div>
          <h5 class="card-title my-4 text-primary fw-bold">
            <i-feather class="text-primary me-2" name="user"></i-feather>
            Datos de tomador
          </h5>
          <div class="col-12 mb-2 d-flex justify-content-between">
            <small class="text-grey-dark fw-bold">Nombre completo</small>
            <p class="card-text">{{datosTomador?.nombres | titlecase }} {{datosTomador?.primerApellido | titlecase}} {{datosTomador?.segundoApellido | titlecase}}</p>
          </div>
          <div class="col-12 mb-2 d-flex justify-content-between">
            <small class="text-grey-dark fw-bold">Documento de identidad</small>
            <p class="card-text">{{datosTomador?.numeroDocumento}}</p>
          </div>
          <div class="col-12 mb-2 d-flex justify-content-between">
            <small class="text-grey-dark fw-bold">Correo</small>
            <p class="card-text">{{datosTomador?.email}}</p>
          </div>
          <div class="col-12 mb-2 d-flex justify-content-between">
            <small class="text-grey-dark fw-bold">Número celular</small>
            <p class="card-text">{{datosTomador?.numeroCelular}}</p>
          </div>
          <div *ngIf="datosAsegurado">
            <h5 class="card-title my-4 text-primary fw-bold">
              <i-feather class="text-primary me-2" name="user-plus"></i-feather>
              Datos de asegurado
            </h5>
            <div class="col-12 mb-2 d-flex justify-content-between">
              <small class="text-grey-dark fw-bold">Nombre completo</small>
              <p class="card-text">{{datosAsegurado?.nombres | titlecase }} {{datosAsegurado?.primerApellido | titlecase}} {{datosAsegurado?.segundoApellido | titlecase}}</p>
            </div>
            <div class="col-12 mb-2 d-flex justify-content-between">
              <small class="text-grey-dark fw-bold">Documento de identidad</small>
              <p class="card-text">{{datosAsegurado?.numeroDocumento}}</p>
            </div>
            <div class="col-12 mb-2 d-flex justify-content-between">
              <small class="text-grey-dark fw-bold">Correo</small>
              <p class="card-text">{{datosAsegurado?.email}}</p>
            </div>
            <div class="col-12 mb-2 d-flex justify-content-between">
              <small class="text-grey-dark fw-bold">Número celular</small>
              <p class="card-text">{{datosAsegurado?.numeroCelular}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-5 order-1 order-md-2">
    <div class="card mb-4">
      <div class="card-footer bg-primary text-white d-flex justify-content-between align-items-center px-4">
        <p class="mb-0">Valor total</p>
        <div class="d-flex align-items-end">
          <h3 class="mb-0">{{data?.tarifa?.costoTotal | currency:'$':'code':'1.0':'en'}}</h3>
          <small class="mb-1 text-white fw-light"> / semestral</small>
        </div>
      </div>
      <div class="card-body card-valor p-4">
        <p class="mb-2">Cobertura principal</p>
        <div class="d-md-flex">
          <div class="d-flex align-items-center justify-content-between col-12">
            <div class="d-flex align-items-center">
              <img class="me-2 card__image" [src]="coberturaPrincipal.url_img" alt="Cobertura de seguros" height="60" width="60">
              <p class="fw-normal mb-0 text-grey-dark" [innerHTML]="coberturaPrincipal.titulo"></p>
            </div>
            <div class="ps-2" (click)="verDetalle(coberturaPrincipal)">
              <i-feather class="card__icon" name="info" ></i-feather>
            </div>
          </div>
        </div>
        <p class="mb-2">Coberturas adicionales</p>
        <div class="d-flex align-items-center justify-content-between col-12" *ngFor="let item of data?.plan?.coberturasSeleccionadas">
          <div class="d-flex align-items-center">
            <img class="me-2 card__image" [src]="item.url_img" alt="Cobertura de seguros" height="60" width="60">
            <p class="fw-normal mb-0 text-grey-dark" [innerHTML]="item.titulo"></p>
          </div>
          <div class="ps-2" (click)="verDetalle(item)">
            <i-feather class="card__icon" name="info" ></i-feather>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-bottom-sheet [id]="'cover'" [isActive]="isActiveModal" (closeBottomSheet)="closeBottomSheet()">
  <div class="mx-3" *ngIf="selectedCover">
    <img *ngIf="selectedCover.url_img" [src]="selectedCover.url_img" alt="Cobertura de seguros">
    <p *ngIf="selectedCover.titulo" class="fw-normal fs-4 text-primary mt-4" [innerHTML]="selectedCover.titulo"></p>
    <p *ngIf="selectedCover.descripcion" class="fw-light mt-2 fs-5" [innerHTML]="selectedCover.descripcion"></p>
  </div>
</app-bottom-sheet>



<div class="py-4 ms-2">
  <h5 class="mb-4 text-primary">Selecciona la opción de pago</h5>
  <div>
    <div class="text-center mb-4">
      <ul class="nav nav-pills radio-tabs mb-4">
        <li class="nav-item me-md-4 mb-3 mb-md-0">
          <button type="button" class="nav-link border-1 active py-4" [ngClass]="{active: idMetodo === 1 }"
            (click)="changeMetodo(1)">
            <img src="/assets/images/logos/coink.svg" alt="Logo de Coink" height="40">
          </button>
        </li>
        <li class="nav-item mb-3 mb-md-0">
          <button type="button" class="nav-link py-4" [ngClass]="{active: idMetodo === 2 }" (click)="changeMetodo(2)">
            <img src="/assets/images/logos/mundial.svg" alt="Logo Seguros Mundial" height="40">
          </button>
        </li>
      </ul>
    </div>
    <div class="card p-0 mt-4" *ngIf="idMetodo === 1">
      <div class="card-header bg-primary d-md-flex justify-content-between text-center align-items-center py-3">
        <h5 class="text-white mb-0">Número de celular en Coink</h5>
      </div>
      <div class="card-body pt-4">
        <form [formGroup]="form">
          <input formControlName="celularCoink" placeholder="Número celular" type="number" id="celularCoink" class="form-control">
          <app-msg-error 
              [messages]="errorsCelularCoink"
              [control]="form.controls.celularCoink">
          </app-msg-error>
        </form>
      </div>
    </div>
    <app-botones-onboarding *ngIf="idMetodo" class="d-flex justify-content-center" [activarBotonVolver]="true"
    (eventoVolver)="volver($event)" (eventoSiguiente)="irAPagar(idMetodo)" labelBtnContinuar="Ir a pagar">
    </app-botones-onboarding>
  </div>