import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
export interface SalirOnboarding {
  permitirSalir: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class OnboardingGuard  {
  constructor(){}

  canDeactivate(
    component: SalirOnboarding): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return component.permitirSalir ? component.permitirSalir() : true;
  }
}
