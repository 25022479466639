<div class="my-4 ms-2">
  <div class="form-group">
    <div class="text-center mb-4">
      <ul class="nav nav-pills radio-tabs mb-4">
        <li class="nav-item me-md-4 mb-md-0">
          <button type="button" class="nav-link active py-3 d-flex justify-content-center align-items-center" 
          [ngClass]="{active: idProducto === 1 }" (click)="changeProducto(1)">
            <img src="/assets/images/bici.webp" alt="Seguro de bicicleta" height="50">
            <h5 class="mb-0 ms-4 text-dark">Bicicleta</h5>
          </button>
        </li>
        <li class="nav-item">
          <button type="button" class="nav-link border-1 py-3 d-flex justify-content-center align-items-center" 
          [ngClass]="{active: idProducto === 2 }" (click)="changeProducto(2)">
            <img src="/assets/images/patineta.webp" alt="Seguro de patineta" height="50">
            <h5 class="mb-0 ms-4 text-dark">Patineta</h5>
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>


<p class="fw-light mb-4">Recuerda que tiene que tener menos de {{oldYears}} años de antigüedad</p>
<form  [formGroup]="formulario"  class="row g-3 ">
  <div [class]="item.col" *ngFor="let item of listaFormulario" class="pe-4">
    <div class="mb-0" *ngIf="item.type == 'title'">
      <h4 class="text-primary fw-light">Ingresa el <b class="fw-bold">valor de tu bici o patineta</b> según factura</h4>
      <p class="fw-light mb-0">Para asegurarla el valor debe ser desde $600.000 mil pesos hasta ${{valorMax}} millones de pesos </p>
    </div>
    <div *ngIf="item.type != 'title' && item.type != 'select' && item.type != 'selectMarca' && item.type != 'date'" class="form-floating">
      <input 
      id="input-{{item.formControlName}}"  
      [formControlName]="item?.formControlName"
      [type]="item.type" 
      class="form-control" 
      [placeholder]='item.placeholder'
      mask="separator.0" thousandSeparator="." > 
      <label for="input-{{item.formControlName}}" class="form-label">{{item.label}}</label>
    </div>
    <div *ngIf="item.type == 'date'"  class="d-flex flex-column">
      <label id="label-{{item.formControlName}}" for="input-{{item.formControlName}}" class="form-label label-select">{{item.label}}</label>
      <p-calendar ngDefaultControl 
                  class="d-grid" 
                  inputStyleClass="form-control" 
                  ariaLabelledBy="label-{{item.formControlName}}" 
                  dateFormat="dd/mm/yy" 
                  inputId="{{item?.formControlName}}" 
                  [type]="item?.type"  
                  [formControlName]="item?.formControlName"
                  placeholder="dd/mm/yyyy" 
                  monthNavigator="true" 
                  yearNavigator="true"
                  [maxDate]="maxDate"
                  [yearRange]="yearRange"
      ></p-calendar>
    </div>
    <div *ngIf="item.type == 'select'" class="form-floating">
      <select 
        [formControlName]="item?.formControlName"
        (change)="changeTipoMarca($event.target.value, item.formControlName)"
        class="form-select form-select-lg mb-2" 
        aria-label=".form-select-lg example">
        <option value="">Seleccionar</option>
        <option *ngFor="let item of item.opciones" [value]="item.id">{{item.descripcion.toString() | titlecase}}</option>
      </select>
      <label for="input-{{item.formControlName}}" class="form-label label-select">{{item.label}}</label>
    </div>
    <div *ngIf="item.type == 'selectMarca'" class="form-floating">
      <select 
        [formControlName]="item.formControlName"
        (change)="changeMarca($event.target.value)"
        class="form-select form-select-lg mb-2" 
        aria-label=".form-select-lg example">
        <option value="">Seleccionar</option>
        <option *ngFor="let item of item.opciones" [value]="item.marca">{{item.marca | titlecase}}</option>
      </select>
      <label for="input-{{item.formControlName}}" class="form-label label-select">{{item.label}}</label>
    </div>
    <app-msg-error 
      [messages]="item?.errors"
      [control]="formulario.controls[item.formControlName]"
      ></app-msg-error>   
  </div>
</form>
<app-botones-onboarding 
[activarBotonVolver]="false"
(eventoSiguiente)="submit()"
></app-botones-onboarding>