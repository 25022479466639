
<form [formGroup]="formulario" #form="ngForm">   
  <div class="card mb-3 p-2" *ngFor="let item of coberturasViajes">
      <div class="card-header mb-3 text-primary d-flex align-items-center bg-lightblue">
        <h5 class="mb-0">{{item.dsGrupo}}</h5>
      </div >
      <div class="card-body py-2 px-3" *ngFor="let itemCobertura of item.coberturas">
        <div class="row">
          <div class="col-md-4">
            <p class="text-grey-dark fw-bold">{{itemCobertura.dsCobertura}}</p>
          </div>
          <!-- <div class="col-md-6">
            <label class="form-check-label" for="flexCheckDefault"> Requerida </label>
            <input 
              (change)="onCategoriaPressed(itemCobertura, $event.target.checked)"
              class="form-check-input" type="checkbox" 
              [checked]="itemCobertura.seleccionada"
              [disabled]="itemCobertura.obligatoria"  
              >
          </div> -->
          <div *ngFor="let gopciones of itemCobertura.terminos" class="col">
              <label for="exampleInputEmail1" class="form-label fw-light small">{{gopciones.titulo}}</label>
              <select 
                [formControlName]="gopciones.publicId" 
                (change)="onChangeSelect($event.target.value, itemCobertura, item)"
                class="form-select form-select-lg mb-3" 
                aria-label=".form-select-lg example">
                  <option value="">Seleccionar</option>
                  <option *ngFor="let itemOpciones of gopciones.opciones" [value]="itemOpciones.cdOpcion">{{itemOpciones.dsOpcion}}</option>
                </select>
                <!-- <ng-select 
                    ngDefaultControl
                    [formControlName]="gopciones.publicId"
                    id="gopciones.publicId"
                    [items]="gopciones.opciones"
                    bindLabel="dsOpcion" 
                    bindValue="cdOpcion"
                    placeholder="Seleccionar"
                    [searchable]="true"
                    [notFoundText]="'No hay datos'"
                    aria-labelledby="gopciones.publicId"
                    (change)="onChangeSelect($event.target.value, itemCobertura)">
                </ng-select> -->
                <!-- <pre>{{gopciones.opciones | json}}</pre> -->
                <app-msg-error 
                  [messages]="errors"
                  [control]="formulario.controls[gopciones.publicId]"
                ></app-msg-error>
          </div>
        </div>
      </div>
    </div>
  </form>
  <app-botones-onboarding (eventoVolver)="volver()" (eventoSiguiente)="submit()" [activarBotonContinuar]="false"></app-botones-onboarding>
  