<!-- <app-hero [page]="'blog'"></app-hero> -->
<div class="bg-primary hero-blog text-center d-flex justify-content-center align-items-center flex-column">
  <div class="box text-center mb-2 p-1 rounded-radius">
    <ul class="nav nav-pills radio-tabs ">
      <li class="nav-item mb-md-0">
        <button type="button" class="nav-link active d-flex py-2 justify-content-center align-items-center w-100 px-3" 
        [ngClass]="{active: idTipoContenido === 1 }" (click)="changeTipoContenido(1)">
          <p class="fs-5 mb-0 text-primary">Blogs</p>
        </button>
      </li>
      <li class="nav-item rounded-radius">
        <button type="button" class="nav-link border-1 d-flex py-2 justify-content-center align-items-center w-100 px-3" 
        [ngClass]="{active: idTipoContenido === 2 }" (click)="changeTipoContenido(2)">
          <p class="fs-5 mb-0 text-body">Noticias</p>
        </button>
      </li>
    </ul>
  </div>
</div>
<div class="container">
  <div class="row position-relative blog-wrapper">
    <app-card-blog-main class="col-12 mb-3" [detalleCard]="listBlog[0]" (click)="redirectDetalleBlog(listBlog[0])"></app-card-blog-main>
    <app-card-blog *ngFor="let blog of listBlog | slice: 1" class="col-12 col-md-6 col-lg-4" [detalleCard]="blog" (click)="redirectDetalleBlog(blog)"></app-card-blog>
  </div>
</div>