import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-verificar-cuenta',
    templateUrl: './verificar-cuenta.component.html',
    styleUrls: ['./verificar-cuenta.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink]
})
export class VerificarCuentaComponent implements OnInit {

  email: string;
  token: string;
  estadoValidacion = 'pendiente';

  constructor(private authService: AuthService, private activatedRoute: ActivatedRoute) {

    this.activatedRoute.queryParams.subscribe(params => {
      this.email = params['email'];
      this.token = params['token'];
  });
   }

  ngOnInit(): void {
    this.authService.verificarCuenta(this.email, this.token).subscribe( resp => {
      this.estadoValidacion = 'validado';
    }, err => {
      this.estadoValidacion = 'error';
    });
  }

}
