import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';

import { GeneralCoreService } from 'src/app/core/services/general-core/general-core.service';
import { OnboardingAccidentes } from 'src/app/onboarding/models/onboardingAccidentes.model';
import { AccidentesService } from 'src/app/onboarding/services/accidentes/accidentes.service';
import { AuthService } from 'src/app/sesion/services/auth/auth.service';
import { TerminosPoliticasService } from 'src/app/shared/services/terminos-politicas/terminos-politicas.service';
import { InformacionTomadorAccidentesForm } from '../informacion-tomador-accidentes/informacion-tomador-accidentes.form';
import { BotonesOnboardingComponent } from '../../botones-onboarding/botones-onboarding.component';
import { FeatherModule } from 'angular-feather';
import { TerminosPoliticasComponent } from '../../../../shared/components/terminos-politicas/terminos-politicas.component';
import { InformacionTomadorAccidentesComponent } from '../informacion-tomador-accidentes/informacion-tomador-accidentes.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-informacion-plan',
    templateUrl: './informacion-plan.component.html',
    styleUrls: ['./informacion-plan.component.scss'],
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, InformacionTomadorAccidentesComponent, TerminosPoliticasComponent, FeatherModule, BotonesOnboardingComponent]
})
export class InformacionPlanComponent implements OnInit {
  @Output() eventoVolver: EventEmitter<any> = new EventEmitter();
  @Output() eventoGuardar: EventEmitter<any> = new EventEmitter();

  public listaPlanes: [] = [];
  public formulario: UntypedFormGroup;
  public listaFormulario: any[] = [];
  public tieneBeneficiario = false;
  public dataLocalFormulario: OnboardingAccidentes;
  public session: boolean;

  constructor(private accidentesService: AccidentesService,
              private fb: UntypedFormBuilder,
              private generalCoreService: GeneralCoreService,
              private terminosPoliticasService: TerminosPoliticasService,
              private authService: AuthService) 
  { 
    this.listaFormulario = InformacionTomadorAccidentesForm.getForm();
    this.formulario = this.fb.group({ 
      tomador: this.crearFormulario(),
      asegurado: this.crearFormulario(),
    })
  }

  ngOnInit(): void {
    this.cargarDatosFormulario();
    const dataSession = this.authService.getSession();
    this.session = dataSession?.roles[0] === 'Cliente' ? true : false;
  }

  public consultarPlanes() {
    this.accidentesService.obtenerPlanes().subscribe(resp => {
      this.listaPlanes = resp.data
    })
  }

  private cargarDatosFormulario(): void {
    this.dataLocalFormulario = new OnboardingAccidentes(JSON.parse(localStorage.getItem('onboardingAccidentes')));
    
    if (this.dataLocalFormulario && this.dataLocalFormulario.tomador) {
      for (const key in this.dataLocalFormulario.tomador) {
        if (key === 'fechaNacimiento') {
          this.tomador.controls[key].setValue(new Date(this.dataLocalFormulario.tomador[key]));
        } else if (key === 'codDepartamento') {
          this.obtenerCiudades(this.dataLocalFormulario.tomador.codDepartamento)
          this.tomador.controls[key].setValue(this.dataLocalFormulario.tomador[key]);
        }
        else {
          this.tomador.controls.codMunicipio.enable()
          this.tomador.controls[key].setValue(this.dataLocalFormulario.tomador[key]);
        }
      }
    }

    if (this.dataLocalFormulario && this.dataLocalFormulario.asegurado) {
      this.tieneBeneficiario = true
      for (const key in this.dataLocalFormulario.asegurado) {
        if (key === 'fechaNacimiento') {
          this.asegurado.controls[key].setValue(new Date(this.dataLocalFormulario.asegurado[key]));
        } else if (key === 'codDepartamento') {
          this.obtenerCiudades(this.dataLocalFormulario.asegurado.codDepartamento)
          this.asegurado.controls[key].setValue(this.dataLocalFormulario.asegurado[key]);
        } 
        else {
          this.asegurado.controls[key].setValue(this.dataLocalFormulario.asegurado[key]);
        }
      }
    }
  }

  public obtenerCiudades(id): void{
    this.accidentesService.obtenerMunicipios(id).subscribe( (resp: any) => {
      this.listaFormulario.forEach( el => {
        if (el.formControlName === 'codMunicipio') {
          el.opciones = resp.data;
        }
      });
    });
  }

  private crearFormulario(): UntypedFormGroup {
    const formulario = new UntypedFormGroup({});
    this.listaFormulario.forEach( element => {
      formulario.addControl(element.formControlName,  new UntypedFormControl(
        {
          value: element.value,
          disabled: element.disabled
        },
        element.validations ));
    });
    return formulario;
  }

  public agregarFormularioBeneficiario() {
    if (this.tieneBeneficiario) {
      this.generalCoreService.openDialog({
        titulo: 'Eliminar beneficiario',
        mensaje: `¿Deseas eliminar a tu beneficiario?`,
        tipo: 'warning',
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'No, volver',
        hasCancelButton: true,
        callbackConfirm: () => {
          this.asegurado.reset()
          this.tieneBeneficiario = false;
          this.eventoGuardar.emit({asegurado: null, siguienteComponente: 'datosTomador'})
        }
      });
    } else {
      this.tieneBeneficiario = true;

    }
  }

  public enviarDatos() {
    const dataLocal = JSON.parse(localStorage.getItem('onboardingAccidentes'));
    let data: any = {
      siguienteComponente: 'datosPago',
      codigoPlan: dataLocal.plan.codPlan
    }
    if (this.tieneBeneficiario && this.formulario.invalid) {
      this.formulario.markAllAsTouched()
      return
    } else if (this.tomador.invalid) {
      this.tomador.markAllAsTouched()
      return
    } 

    if (this.tieneBeneficiario) {
      data = {...data, tomador: this.tomador.getRawValue(), asegurado: this.asegurado.getRawValue()}
      
    } else {
      data = {...data, tomador: this.tomador.getRawValue()}
    }
    this.accidentesService.enviarCotizacion(data).subscribe(resp => {
      this.accidentesService.codigoToken = resp.data.codigoToken;
      this.eventoGuardar.emit({...data, tuCotizacionId: resp.data.tuCotizacionId, tarifa: resp.data.tarifa})
      //TODO: valida este metodo para que se utiliza
      // this.terminosPoliticasService.setTerminosCondiciones({
      //   tuCotizacionId: resp.data.tuCotizacionId,
      //   terminosCondiciones: true
      // }).subscribe( resp => {});
    })

    
  }

  get tomador() {
    return this.formulario.get('tomador') as UntypedFormGroup
  }

  get asegurado() {
    return this.formulario.get('asegurado') as UntypedFormGroup
  }

  volver(data: any): void {
    this.eventoVolver.emit({ componente: 'datosPlan'});
  }


}
