import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { GeneralCoreService } from 'src/app/core/services/general-core/general-core.service';
import { AuthService } from 'src/app/sesion/services/auth/auth.service';
import { ProductosService } from 'src/app/shared/services/productos/productos.service';
import { ItemProductComponent } from '../../../../shared/components/item-product/item-product.component';
import { NgIf, NgFor } from '@angular/common';
import { FeatherModule } from 'angular-feather';

@Component({
    selector: 'app-nav-desktop',
    templateUrl: './nav-desktop.component.html',
    styleUrls: ['./nav-desktop.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        RouterLinkActive,
        FeatherModule,
        NgIf,
        NgFor,
        ItemProductComponent,
    ],
})
export class NavDesktopComponent implements OnInit, OnDestroy {
  @Input() isStaticColor = false;
  @Input() isLinkBlue = false;
  @ViewChild('navbar') nav: ElementRef;

  public titleNavbar = '';
  public isNavbarProduct = true;
  public openMenuSubcribe = false;
  public indexCategoria = 0;
  public menuProfile =
  {
    products: [
      {
        name: "Perfil",
        url: "/perfil",
        icon: "person_outline"
      },
      {
        name: "Mis Pólizas",
        url: "/mis-polizas",
        icon: "description"
      },
      {
        name: "Configuración",
        url: "/configuracion",
        icon: "settings"
      }
    ]
  }

  private destroy$: Subject<boolean> = new Subject<boolean>();

  estadoSession = false;
  public isVisibleMenu = false;
  public categories = [];
  public currentCategory = '';
  public tipo = '';

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private generalCoreService: GeneralCoreService,
    private router: Router,
    private productosService: ProductosService
  ) {
    this.closeExtendedNavbar();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isStaticColor?.currentValue) {
      this.nav?.nativeElement.classList.add('nav-sticky');
    }
  }

  ngOnInit(): void {
    const dataSession = this.authService.getSession();
    if (
      dataSession &&
      dataSession?.token &&
      dataSession.roles[0] !== 'Onbording' &&
      dataSession.roles[0] !== 'OnbordingCliente'
    ) {
      this.estadoSession = true;
    } else {
      this.authService.closeSession();
    }

    this.authService.estadoSession$
      .pipe(takeUntil(this.destroy$))
      .subscribe((resp) => {
        this.estadoSession = resp;
      });

    this.generalCoreService.navbarMenu$
      .pipe(takeUntil(this.destroy$))
      .subscribe((resp) => {
        this.openMenuSubcribe = true;
        this.toggleExtendedNavbar();
      });

    if (this.isStaticColor) {
      const navbar = document.getElementById('navbar');
      navbar.classList.add('nav-sticky');
    }
    this.obtenerProducto();
  }

  obtenerProducto() {
    this.productosService.obtenerProductos().subscribe((resp) => {
      this.categories = resp;
      this.currentCategory = this.categories[0].name;
    });
  }

  windowScroll(): void {
    if (!this.isStaticColor) {
      const navbar = document.getElementById('navbar');
      if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop > 50
      ) {
        navbar.classList.add('nav-sticky');
      } else {
        navbar.classList.remove('nav-sticky');
      }
    }
  }

  toggleExtendedNavbar(objeto: any = null, isNavbarProduct = true): void {
    this.isNavbarProduct = isNavbarProduct;

    if (objeto) {
      this.router.navigateByUrl(objeto.url);
    }
    const navbar = document.getElementById('navbar');
    navbar.classList.toggle('show-extended-navbar');
    this.isVisibleMenu = !this.isVisibleMenu;
  }

  changeActived(category): void {
    this.currentCategory = category.name;
  }

  getRandomArbitrary(min, max): number {
    return Math.random() * (max - min) + min;
  }

  hideExtendedNavbar(): void {
    const navbar = document.getElementById('navbar');
    navbar.classList.remove('show-extended-navbar');
    this.isVisibleMenu = false;
  }

  closeExtendedNavbar(): void {
    this.generalCoreService.openMenu(false);
  }

  cerrarSession(): void {
    this.authService.closeSession();
    this.router.navigate(['home']);
    localStorage.removeItem('urlDetalle');
  }

  redirect(ruta: string) {
    this.router.navigate([ruta]);
  }

}
