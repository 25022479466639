<div class="mt-4 mb-3 text-center d-flex justify-content-center">
  <h4 class="text-primary w-75 fw-light title__onboarding" [innerHTML]="getPasoVisible().titulo"></h4>
</div>

<div *ngFor="let item of pasosNew">
  <app-coberturas-onboarding
    (eventoGuardar)="guardar($event)" 
    *ngIf="item.visible && item.paso == 'datosPlan'">
  </app-coberturas-onboarding>
  <app-informacion-plan
    (eventoVolver)="volver($event)"
    (eventoGuardar)="guardar($event)" 
    *ngIf="item.visible && item.paso == 'datosTomador'"
  ></app-informacion-plan>
  <app-informacion-pago-accidentes
    (eventoVolver)="volver($event)"
    (eventoGuardar)="guardar($event)" 
    *ngIf="item.visible && item.paso == 'datosPago'"
  ></app-informacion-pago-accidentes>
</div>