<h5 class="card-title mb-3">
  <i-feather class="text-primary me-2" name="info"></i-feather>
  Datos de la moto
</h5>
<h6 class="card-subtitle mb-2 text-muted"></h6>
<div class="row">
  <div class="col-12 col-md-6 mb-3 ps-5">
    <small class="text-grey-dark fw-bold">Placa</small>
    <p class="card-text">{{informacionVehiculo?.placa}}</p>
  </div>
  <div class="col-12 col-md-6 mb-3 ps-5">
    <small class="text-grey-dark fw-bold">Modelo de la moto</small>
    <p class="card-text">{{informacionVehiculo?.modelo}}</p>
  </div>
  <div class="col-12 col-md-6 mb-3 ps-5">
    <small class="text-grey-dark fw-bold">Clase de la moto</small>
    <p class="card-text">{{informacionVehiculo?.dsClase}}</p>
  </div>
  <div class="col-12 col-md-6 mb-3 ps-5">
    <small class="text-grey-dark fw-bold">Línea</small>
    <p class="card-text">{{informacionVehiculo?.dsLinea}}</p>
  </div>
</div>