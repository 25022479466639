import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/sesion/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private authService: AuthService, private router: Router){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let validaSession = false;

      const dataSession = this.authService.getSession();

      if (dataSession && (dataSession?.roles[0] === 'Onbording' ||  dataSession?.roles[0] === 'OnbordingCliente')){
        if(this.authService.authUrl.current === '/onboarding/autos?paso=datosPlanes'){
          this.authService.closeSession();
          return true
        }
        
        this.authService.closeSession();
        return false;
      }

      if (dataSession && dataSession.token){
        if (state.url.includes('login') || state.url.includes('registro')){
          this.router.navigate(['perfil']);
          validaSession = false;
        }else {
          if (this.authService.authUrl.next !== ''){
            const urlTemp  = this.authService.authUrl.next;
            this.authService.authUrl.next = '';
            this.router.navigateByUrl(urlTemp);
          }
          validaSession = true;
        }
      }else {
        if (state.url.includes('login') || state.url.includes('registro')){
          validaSession = true;
        }else{
          this.authService.authUrl.next = state.url;
          this.router.navigate(['login']);
        }
      }
      return validaSession;
  }
}
