import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FeatherModule } from 'angular-feather';

@Component({
    selector: 'app-checkbox-card-onboarding',
    templateUrl: './checkbox-card-onboarding.component.html',
    styleUrls: ['./checkbox-card-onboarding.component.scss'],
    standalone: true,
    imports: [FeatherModule]
})
export class CheckboxCardOnboardingComponent implements OnInit {
  @Output() activeBottomSheet: EventEmitter<any> = new EventEmitter();
  @Output() selectCover: EventEmitter<any> = new EventEmitter();
  @Input() title: string;
  @Input() urlImage: string;
  @Input() cover: any;
  @Input() active: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  public verDetalle() {
    this.activeBottomSheet.emit(this.cover)
  }

  public selectCard() {
    this.selectCover.emit(this.cover)
  }

}
