<div class="cover pt-1">
  <div class="container px-2">
    <div class="row cards-wrapper">
      <div class="card p-0 mt-4">
        <div class="card-body row pb-2 pt-4">
          <div class="col-12 text-start pb-3">
            <h5 class="text-primary mb-0">Cobertura principal</h5>
            <span class="opacity-50">Es la cobertura básica del seguro y no se pude modificar</span>
          </div>
          <div class="col-12 mb-4">
            <app-checkbox-card-onboarding (activeBottomSheet)="activeBottomSheet($event)" [active]="coberturaPrincipal.active" [urlImage]="coberturaPrincipal.url_img" [cover]="coberturaPrincipal" [title]="coberturaPrincipal.titulo"></app-checkbox-card-onboarding>
          </div>
          <div class="col-12 text-start pb-3">
            <h5 class="text-primary mb-0">Coberturas adicionales</h5>
            <span class="opacity-50">Recuerda que solo puedes escoger 2 coberturas</span>
          </div>
          <div class="col-12 col-md-6 mb-4" *ngFor="let cover of contenidos">
            <app-checkbox-card-onboarding (activeBottomSheet)="activeBottomSheet($event)" (selectCover)="selectCover($event)" [active]="cover.active" [urlImage]="cover.url_img" [cover]="cover" [title]="cover.titulo"></app-checkbox-card-onboarding>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-bottom-sheet [id]="'cover'" [isActive]="isActiveModal" (closeBottomSheet)="closeBottomSheet()">
  <div class="mx-3" *ngIf="selectedCover">
    <img *ngIf="selectedCover.url_img" [src]="selectedCover.url_img" alt="Cobertura de seguros">
    <p *ngIf="selectedCover.titulo" class="fw-normal fs-4 text-primary mt-4" [innerHTML]="selectedCover.titulo"></p>
    <p *ngIf="selectedCover.descripcion" class="fw-light mt-2 fs-5" [innerHTML]="selectedCover.descripcion"></p>
  </div>
</app-bottom-sheet>

<app-botones-onboarding *ngIf="planSeleccionado"
[activarBotonVolver]="false"
(eventoSiguiente)="submit()"
></app-botones-onboarding>