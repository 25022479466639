<div class="container d-flex flex-column">
  <div class="align-items-center justify-content-center min-vh-100 row">
    <div class="col-12 col-md-8 py-8 py-md-11 text-center">
      <div class="icon-box d-flex justify-content-center align-items-center mx-auto my-5">
        <i-feather class="text-primary icon-box__check" name="clock"></i-feather>
      </div>
      <h1 class="text-primary fw-bold">La transacción esta en proceso</h1>
      <p class="fs-5 fw-light text-primary">Te hemos enviado al correo electrónico el detalle de tu póliza</p>
      <div class="my-5">
        <button class="btn btn-success" [routerLink]="['/home']">Volver al inicio</button>
      </div>
    </div>
  </div>
</div>