<div class="card mb-3">
  <div class="card-body">
    <h5 class="text-primary mb-3">Pasajero {{index + 1}}</h5>
    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <small class="text-grey-dark fw-bold">Nombre</small>
        <p class="card-text">{{pasajero?.persona?.primerNombre}} {{pasajero?.persona?.primerApellido}}</p>
      </div>
      <div class="col-12 col-md-6 mb-3">
        <small class="text-grey-dark fw-bold">Correo</small>
        <p class="card-text">{{pasajero?.persona?.correoElectronico}}</p>
      </div>
      <div class="col-12 col-md-6">
        <small class="text-grey-dark fw-bold">Fecha de nacimiento</small>
        <p class="card-text">{{pasajero?.persona?.fechaNacimiento | date: 'dd/MM/yyyy'}}</p>
      </div>
      <div class="col-12 col-md-6">
        <small class="text-grey-dark fw-bold">Celular</small>
        <p class="card-text">{{pasajero?.persona?.numeroTelefono}}</p>
      </div>
    </div>
  </div>
</div>