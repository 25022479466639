import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GeneralCoreService } from '../../services/general-core/general-core.service';
import { SpinnerLoadingComponent } from './spinner-loading/spinner-loading.component';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    standalone: true,
})
export class SpinnerComponent implements OnInit {
  dialogRef: any;

  constructor(
    public dialog: MatDialog,
    private generalCoreService: GeneralCoreService
  ) {}

  ngOnInit(): void {
    this.generalCoreService.spinner$.subscribe((resp) => {
      if (resp) {
        this.dialogRef = this.dialog.open(SpinnerLoadingComponent, {
          panelClass: 'modal-spinner',
          backdropClass: 'overlay-spinner',
          disableClose: true,
        });

        this.dialogRef.afterClosed().subscribe((result) => {});
      }
    });
  }
}
