import { Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth/auth.guard';
import { CambioContrasenaComponent } from './components/cambio-contrasena/cambio-contrasena.component';
import { LoginComponent } from './components/login/login.component';
import { RecuperarContrasenaComponent } from './components/recuperar-contrasena/recuperar-contrasena.component';
import { RegisterComponent } from './components/register/register.component';
import { VerificarCuentaComponent } from './components/verificar-cuenta/verificar-cuenta.component';

const sesionRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'registro',
    component: RegisterComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'verificar-cuenta',
    component: VerificarCuentaComponent,
  },
  {
    path: 'recuperar-clave',
    component: RecuperarContrasenaComponent,
  },
  {
    path: 'cambio-clave',
    component: CambioContrasenaComponent,
  },
];

export default sesionRoutes;
