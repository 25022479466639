
<form [formGroup]="formulario" #form="ngForm" *ngIf="coberturas.length > 0">
  <div class="card card-tarifa" *ngIf="dataLocalFormulario">
    <div class="card-body d-flex justify-content-between align-items-center py-3">
      <h5 class="text-success fw-light mb-0">Este es el valor de tu seguro</h5>
      <h1 class="card-text text-success mb-0">{{dataLocalFormulario.valorCotizacion.primaTotal | currency:'$':'code':'1.0':'en'}}</h1>
    </div>
  </div>

<div class="card mt-3" *ngFor="let item of coberturas">
    <div class="card-header bg-white text-primary d-flex align-items-center">
      <img src="/assets/images/cover/danos.webp" alt="" height="50" class="me-3">
      <h5 class="mb-0">{{item.dsGrupo}}</h5>
    </div >
    <div class="card-body" *ngFor="let itemCobertura of item.coberturas">
      <div class="row">
        <div class="col-md-4">
          <p class="">{{itemCobertura.dsCobertura}}</p>
        </div>
      
        <div *ngFor="let gopciones of itemCobertura.gruposOpciones" class="col-4">
            <label for="exampleInputEmail1" class="form-label fw-light small">{{gopciones.titulo}}</label>
            <select 
              [formControlName]="gopciones?.publicId" 
              (change)="onChangeSelect($event.target.value)"
              class="form-select form-select-lg mb-3" 
              aria-label=".form-select-lg example">
                <option value="">Seleccionar</option>
                <option *ngFor="let itemOpciones of gopciones.opciones" [value]="itemOpciones.cdOpcion">{{itemOpciones.dsOpcion | coberturas}}</option>
              </select>
              <app-msg-error 
                [messages]="errors"
                [control]="formulario.controls[gopciones.publicId]"
              ></app-msg-error>
        </div>
      </div>
       
    </div>
  </div>
</form>
<app-botones-onboarding
  (eventoVolver)="volver($event)"
  (eventoSiguiente)="submit()"
  ></app-botones-onboarding>
