import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { BlogService } from '../../services/blog/blog.service';
import { NoticiasService } from '../../services/noticias/noticias.service';
import { FeatherModule } from 'angular-feather';
import { NgFor, NgIf, NgClass, TitleCasePipe } from '@angular/common';

@Component({
    selector: 'app-blog-detalle',
    templateUrl: './blog-detalle.component.html',
    styleUrls: ['./blog-detalle.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, NgClass, RouterLink, FeatherModule, TitleCasePipe]
})
export class BlogDetalleComponent implements OnInit {

  public dataBlog = []
  public slug: string;
  public categoria: string;
  public tipo: string;

  constructor(private activatedRoute: ActivatedRoute,
              private blogService: BlogService,
              private noticiasService: NoticiasService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.slug = params.get('slug');
    })
    
    this.tipo = this.activatedRoute.snapshot.queryParams.tipo
    if (this.tipo === "1") {
      this.blogService.blogItemsDetalle(this.slug).subscribe(resp => {
        this.dataBlog = resp.data
      })
    } else {
      this.noticiasService.noticiasItemsDetalle(this.slug).subscribe(resp => {
        this.dataBlog = resp.data
      })
    }

    this.categoria = this.activatedRoute.snapshot.queryParams.categoria
  }

  getColorTipo(tipo) {
    return {
      'badge-warning': tipo === 'bicicleta', 'badge-primary': tipo === 'auto', 'badge-secondary': tipo === 'moto', 'badge-success': tipo === 'seguros'
    }
  }

}
