import { Component, Input, OnInit } from '@angular/core';
import { FeatherModule } from 'angular-feather';

@Component({
    selector: 'app-datos-vehiculo',
    templateUrl: './datos-vehiculo.component.html',
    styleUrls: ['./datos-vehiculo.component.scss'],
    standalone: true,
    imports: [FeatherModule]
})
export class DatosVehiculoComponent implements OnInit {
  @Input() informacionVehiculo: any;

  constructor() { }

  ngOnInit(): void {
  }

}
