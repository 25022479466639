<div
  class="alert-modal d-flex align-items-center flex-column position-relative p-4"
>
  <div
    class="alert-modal__icon-box d-flex justify-content-center align-items-center"
    [style]="'background:' + configModal.background"
  >
    <i-feather
      class="alert-modal__icon-box__check {{ configModal.color }}"
      [name]="configModal.icon"
    ></i-feather>
  </div>

  <h2 mat-dialog-title class="pt-5 text-grey-dark">{{ data?.titulo }}</h2>
  <div mat-dialog-content>
    <p class="text-body text-center" [innerHTML]="data.mensaje"></p>
    <form [formGroup]="form">
      <input
        type="text"
        class="form-control"
        placeholder="000 000"
        formControlName="otp"
        mask="000 000"
      />
      <app-msg-error [messages]="errorsOtp" [control]="form.controls.otp">
      </app-msg-error>
    </form>
  </div>
  <div mat-dialog-actions class="w-100">
    <ng-container>
      <div class="mt-4 d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-outline-dark w-100 mx-2 px-4 px-sm-5"
          *ngIf="data.hasCancelButton"
          (click)="eventoBoton('cancelar')"
        >
          {{ data.cancelButtonText }}
        </button>
        <button
          type="button"
          class="btn {{ configModal.btn }} w-100 mx-2 px-4 px-sm-5"
          *ngIf="data.hasConfirmButton"
          (click)="eventoBoton('confirmar')"
        >
          {{ data.confirmButtonText }}
        </button>
      </div>
    </ng-container>
  </div>
</div>
