<div class="header-mobile bg-white px-4 py-3 d-flex justify-content-between align-items-center position-sticky top-0">
  <div class="logo">
    <img src="assets/images/logo.webp" alt="logo TuPrimero" [routerLink]="'/'" height="35">
  </div>
  <i-feather class="icon text-primary" [name]="isDrawerActive ? 'x' : 'menu'" (click)="toggleMenu()"></i-feather>
</div>

<div class="drawer" id="drawer">
  <ul class="nav flex-column">
    <li class="py-3 px-4" (click)="toggleMenu()">
      <a class="text-white h1 fw-light text-decoration-none" [routerLink]="'nosotros'">Nosotros</a>
    </li>
    <li class="py-3 px-4 d-flex justify-content-between align-items-center" data-bs-toggle="collapse" href="#collapseProduct" role="button" aria-expanded="false" aria-controls="collapseProduct">
      <div class="text-white h1 fw-light text-decoration-none">Tu Seguros</div>
      <i-feather class="icon text-white h1" name="chevron-down"></i-feather>
    </li>
    <div class="collapse" id="collapseProduct">
      <div class="drawer__content text-white fs-5 ps-4 pe-2 py-4" >
        <ul class="list-unstyled">
          <li *ngFor="let subItem of navList">
            <span class="drawer__subitem d-block mb-3">{{subItem.name}}</span>
            <app-item-product class="item" *ngFor="let product of subItem.products" [label]="product?.name"
              [urlIcon]="product?.icon" (click)="redirect(product)"></app-item-product>
          </li>
        </ul>
      </div>
    </div> 
    <li class="py-3 px-4" (click)="toggleMenu()">
      <a class="text-white h1 fw-light text-decoration-none" [routerLink]="'blog'">Noticias</a>
    </li>
    <li class="py-3 px-4" (click)="toggleMenu()">
      <a class="text-white h1 fw-light text-decoration-none" [routerLink]="'contacto'">Contacto</a>
    </li>
    <li class="py-3 px-4 d-flex justify-content-between align-items-center" (click)="toggleMenu()" *ngIf="estadoSession">
      <a class="text-white h1 fw-light text-decoration-none" [routerLink]="'perfil'">Mi perfil</a>
      <img src="assets/images/user.webp" width="40" height="40" class="rounded-circle cursor-pointer">
    </li>

    <button *ngIf="estadoSession" class="btn btn-outline-success m-4" [routerLink]="'auth'" (click)="cerrarSession()">Cerrar sesión</button>
    <button class="btn btn-success m-4" routerLink="/login" (click)="toggleMenu()" *ngIf="!estadoSession">Mi cuenta</button>
  </ul>
</div>
