import { Routes } from '@angular/router';
import blogRoutes from './blog/blog.routes';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { AuthLayoutComponent } from './core/layouts/auth-layout/auth-layout.component';
import { DashboardLayoutComponent } from './core/layouts/dashboard-layout/dashboard-layout.component';
import { OnboardingLayoutComponent } from './core/layouts/onboarding-layout/onboarding-layout.component';
import { ProfileLayoutComponent } from './core/layouts/profile-layout/profile-layout.component';
import dashboardRoutes from './dashboard/dashboard.routes';
import onBoardingRoutes from './onboarding/onboarding.routes';
import sesionRoutes from './sesion/sesion.routes';

const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    children: [
      {
        path: '',
        children: dashboardRoutes,
      },
      {
        path: 'blog',
        children: blogRoutes,
      },
    ],
  },
  {
    path: '',
    component: ProfileLayoutComponent,
    children: [
      {
        canActivate: [AuthGuard],
        path: 'perfil',
        loadComponent: () =>
          import('./sesion/pages/profile/profile.component').then(
            (m) => m.ProfileComponent
          ),
      },
      {
        canActivate: [AuthGuard],
        path: 'perfil-detalle',
        loadComponent: () =>
          import('./sesion/pages/perfil-detalle/perfil-detalle.component').then(
            (m) => m.PerfilDetalleComponent
          ),
      },
      {
        canActivate: [AuthGuard],
        path: 'mis-polizas',
        loadComponent: () =>
          import('./sesion/pages/mis-polizas/mis-polizas.component').then(
            (m) => m.MisPolizasComponent
          ),
      },
      {
        canActivate: [AuthGuard],
        path: 'cargue-masivo',
        loadComponent: () =>
          import('./sesion/pages/carga-masiva/carga-masiva.component').then(
            (m) => m.CargaMasivaComponent
          ),
      },
      {
        canActivate: [AuthGuard],
        path: 'polizas-masivas',
        loadComponent: () =>
          import(
            './sesion/pages/polizas-masivas/polizas-masivas.component'
          ).then((m) => m.PolizasMasivasComponent),
      },
      {
        canActivate: [AuthGuard],
        path: 'polizas-masivas/detalle/:id',
        loadComponent: () =>
          import(
            './sesion/pages/polizas-masivas-detalle/polizas-masivas-detalle.component'
          ).then((m) => m.PolizasMasivasDetalleComponent),
      },
      {
        canActivate: [AuthGuard],
        path: 'mis-polizas/detalle/:id',
        loadComponent: () =>
          import(
            './sesion/pages/mis-polizas-detalle/mis-polizas-detalle.component'
          ).then((m) => m.MisPolizasDetalleComponent),
      },
      {
        canActivate: [AuthGuard],
        path: 'asegurados',
        loadComponent: () =>
          import(
            './sesion/pages/asegurados/asegurados.component'
          ).then((m) => m.AseguradosComponent),
      },
      {
        canActivate: [AuthGuard],
        path: 'asegurados-por-modificar',
        loadComponent: () =>
          import(
            './sesion/pages/asegurados-por-modificar/asegurados-por-modificar.component'
          ).then((m) => m.AseguradosPorModificarComponent),
      },
      {
        canActivate: [AuthGuard],
        path: 'configuracion',
        loadComponent: () =>
          import(
            './sesion/pages/tab-configuracion/tab-configuracion.component'
          ).then((m) => m.TabConfiguracionComponent),
      },
      {
        path: 'politicas',
        loadComponent: () =>
          import('./dashboard/components/politicas/politicas.component').then(
            (m) => m.PoliticasComponent
          ),
      },
      {
        path: 'terminos',
        loadComponent: () =>
          import('./dashboard/components/terminos/terminos.component').then(
            (m) => m.TerminosComponent
          ),
      },
      {
        path: 'glosario',
        loadComponent: () =>
          import('./dashboard/components/glosario/glosario.component').then(
            (m) => m.GlosarioComponent
          ),
      },
    ],
  },
  // Auth Routes
  {
    path: '',
    component: AuthLayoutComponent,
    children: sesionRoutes,
  },

  // Onboarding Routes
  {
    path: 'onboarding',
    component: OnboardingLayoutComponent,
    children: onBoardingRoutes,
  },
  {
    path: '**',
    component: DashboardLayoutComponent,
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./shared/components/not-found/not-found.component').then(
            (m) => m.NotFoundComponent
          ),
      },
    ],
  },
];

export default routes;
