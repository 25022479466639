import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { OnBoardingAutos } from 'src/app/onboarding/models/onboarding.model';
import { OnboardingService } from 'src/app/onboarding/services/onboarding/onboarding.service';
import { CoberturasPipe } from '../../../pipes/coberturas/coberturas.pipe';
import { BotonesOnboardingComponent } from '../../botones-onboarding/botones-onboarding.component';
import { MsgErrorComponent } from '../../../../shared/components/msg-error/msg-error.component';
import { NgIf, NgFor, CurrencyPipe } from '@angular/common';

@Component({
    selector: 'app-informacion-coberturas',
    templateUrl: './informacion-coberturas.component.html',
    styleUrls: ['./informacion-coberturas.component.scss'],
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, NgFor, MsgErrorComponent, BotonesOnboardingComponent, CurrencyPipe, CoberturasPipe]
})
export class InformacionCoberturasComponent implements OnInit {

  @Output() eventoVolver: EventEmitter<any> = new EventEmitter();
  @Output() eventoGuardar: EventEmitter<any> = new EventEmitter();

  coberturas: any[] = [];
  formulario: UntypedFormGroup;
  dataLocalFormulario: OnBoardingAutos;
  dataValorCotizacion: any;

  errors: any[] =  [
    { error: 'required', message: 'Campo requerido'}
  ];

  constructor() {}

  ngOnInit(): void {
    this.formulario = new UntypedFormGroup({});
    this.cargarDatosFormulario();
  }

  private cargarDatosFormulario(): void {
    this.dataLocalFormulario = new OnBoardingAutos(JSON.parse(localStorage.getItem('onboarding')));

    // this.dataValorCotizacion = this.dataLocalFormulario.valorCotizacion;

    // if (this.dataLocalFormulario && this.dataLocalFormulario.datosCobertura) {
    //     this.coberturas = this.dataLocalFormulario.datosCobertura;
    //     this.crearFormulario(this.coberturas);
    //   }else{
    //     this.obtenerCoberturas();
    //   }
  }

  // obtenerCoberturas(): void{
  //   this.onboardingService.obtenerCoberturas(this.dataLocalFormulario.datosVehiculo.idCotizacion, this.dataLocalFormulario.datosVehiculo.ciudadCirculacion.id, this.dataLocalFormulario.datosVehiculo.ciudadCirculacion.codigo_zona).subscribe( (resp:any) => {
  //     this.coberturas = resp;
  //     this.crearFormulario(this.coberturas);
  //   });
  // }

  private crearFormulario(data: any[]): void {
    data.forEach(grupo => {
      grupo.coberturas.forEach(cobertua => {
        cobertua.gruposOpciones.forEach(opcionCobertura => {

          const valorCampo = opcionCobertura.cdOpcionSeleccionada;
          if (opcionCobertura.opciones.length === 1) {
            // valorCampo = opcionCobertura.opciones[0].id;
          }
          if (opcionCobertura.requerido) {
            this.formulario.addControl(opcionCobertura.publicId, new UntypedFormControl(valorCampo, [Validators.required]));
          } else {
            this.formulario.addControl(opcionCobertura.publicId, new UntypedFormControl(valorCampo, []));
          }
        });
      });
    });
  }

  submit(): void{
    if (this.formulario.invalid){
      this.formulario.markAllAsTouched();
    }else{
      this.guardarSeleccion(this.formulario.getRawValue());
      const data: any = {
        siguienteComponente: 'datosPago',
        datosCobertura: this.coberturas,
        datosPago: true
      };
      this.eventoGuardar.emit(data);
    }
  }

  guardarSeleccion(dataForm: any): void {
    this.coberturas.forEach( grupo => {
      grupo.coberturas.forEach(cobertua => {
        cobertua.gruposOpciones.forEach( opcionCobertura => {
          for (const key in dataForm) {
            if (key === opcionCobertura.publicId){
              opcionCobertura.cdOpcionSeleccionada = dataForm[key];
            }
          }
        });
      });
    });
  }

  onCategoriaPressed(item: any, checked: boolean): void{
    this.coberturas.forEach( grupo => {
      grupo.coberturas.forEach(cobertua => {
        if (cobertua.publicId === item.publicId){
          cobertua.gruposOpciones.forEach( opcionCobertura => {
            if (checked){
              this.formulario.controls[opcionCobertura.publicId].setValidators([Validators.required]);
              this.formulario.controls[opcionCobertura.publicId].updateValueAndValidity();
            }else{
              this.formulario.controls[opcionCobertura.publicId].setValidators(null);
              this.formulario.controls[opcionCobertura.publicId].updateValueAndValidity();
            }
          });
        }
      });
    });
  }

  volver(data: any): void {
    this.eventoVolver.emit({ componente: 'datosTomador'});
  }

  onChangeSelect(data: any): void{
    // this.onboardingService.cotizacionCoberturas({
    //   tuCotizacionId: this.dataLocalFormulario.idCotizacion,
    //   grupoCoberturas: this.coberturas
    // }).subscribe( (resp:any) => {
    //   this.dataValorCotizacion  = resp.data;
    //   const data: any = {
    //     siguienteComponente:'datosCobertura',
    //     valorCotizacion: resp.data
    //   }
    //   this.eventoGuardar.emit(data);
    // });
  }

  getRandomArbitrary(min, max): number {
    return Math.random() * (max - min) + min;
  }

}
