import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralCoreService } from 'src/app/core/services/general-core/general-core.service';
import { OnboardingAccidentes } from 'src/app/onboarding/models/onboardingAccidentes.model';
import { InformacionPagoAccidentesComponent } from '../informacion-pago-accidentes/informacion-pago-accidentes.component';
import { InformacionPlanComponent } from '../informacion-plan/informacion-plan.component';
import { CoberturasOnboardingComponent } from '../coberturas-onboarding/coberturas-onboarding.component';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-onboarding-accidentes',
    templateUrl: './onboarding-accidentes.component.html',
    styleUrls: ['./onboarding-accidentes.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, CoberturasOnboardingComponent, InformacionPlanComponent, InformacionPagoAccidentesComponent]
})
export class OnboardingAccidentesComponent implements OnInit {
  public onboardingData: OnboardingAccidentes;
  public pasoActual = '';

  pasos: any = {
    datosPlan: true,
    datosTomador: false,
    datosFinalizar: false,
  };

  pasosNew: any[] = [
    {
      paso: 'datosPlan',
      estado: false,
      visible: true,
      titulo: 'Conoce y selecciona <b class="fw-bold">tus coberturas</b>'
    },
    {
      paso: 'datosTomador',
      estado: false,
      visible: false,
      titulo: 'Vamos a necesitar <b class="fw-bold">algunos datos</b>'
    },
    {
      paso: 'datosPago',
      estado: false,
      visible: false,
      titulo: 'Resumen de tu seguro de<b class="fw-bold"> accidentes personales</b>'
    },
  ];

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private generalCoreService: GeneralCoreService) 
  { 
    this.validaParametros()
  }

  ngOnInit(): void {
    if (localStorage.getItem('onboardingAccidentes')) {
      this.onboardingData = new OnboardingAccidentes(JSON.parse(localStorage.getItem('onboardingAccidentes')));
    }
    // window.onbeforeunload = function() {
    //   localStorage.removeItem('onboardingAccidentes')
    // }

  }

  private validarPasoActual(paso: string, siguiente: boolean = false): void {

    if (siguiente){
      this.router.navigate(['/onboarding/accidentes'], { queryParams: { paso } });
    }else{
      if (!this.onboardingData[paso]){
        this.router.navigate(['/onboarding/accidentes'], { queryParams: { paso: 'datosPlan' } });
      }
    }
  }

  private validaParametros() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.paso) {
        let tempo = 0;
        this.pasosNew.forEach(e => {
          if (e.paso === params.paso) {
            tempo++;
          }
        });

        if (tempo === 1) {
          if (this.onboardingData) {
            this.pasosNew.forEach(element => {
              element.visible = element.paso === params.paso ? true : false;
            });
          } else {
            this.router.navigate(['/onboarding/accidentes'], { queryParams: { paso: 'datosPlan' } });
          }

          this.pasosNew.forEach(element => {
            element.visible = element.paso === params.paso ? true : false;
          });
        } else {
          this.router.navigate(['/onboarding/accidentes'], { queryParams: { paso: 'datosPlan' } });
        }
      } else {
        if (this.onboardingData) {
          this.router.navigate(['/onboarding/accidentes'], { queryParams: { paso: this.onboardingData.siguienteComponente } });
        } else {
          this.router.navigate(['/onboarding/accidentes'], { queryParams: { paso: 'datosPlan' } });
        }
      }
    });
  }

  getPasoVisible() {
    return this.pasosNew.find(paso => paso.visible )
  }

  guardar(data) {
    if (!this.onboardingData) {
      this.onboardingData = new OnboardingAccidentes({});
    } else {
      const dataOnb = JSON.parse(localStorage.getItem('onboardingAccidentes'));
      this.onboardingData = new OnboardingAccidentes(dataOnb);
    }
    this.onboardingData = { ... this.onboardingData, ...data };
    localStorage.setItem('onboardingAccidentes', JSON.stringify(this.onboardingData));
    if (this.onboardingData.tuCotizacionId) {
      localStorage.removeItem('partner')
    }

    this.validarPasoActual(data.siguienteComponente, true);
  }

  volver(data: any): void {
    this.router.navigate(['/onboarding/accidentes'], { queryParams: { paso: data.componente } });
  }

}
