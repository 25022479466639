import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { FeatherModule } from 'angular-feather';
import { patronValidacion } from 'src/app/onboarding/enums/validaciones';
import { MsgErrorComponent } from '../../../shared/components/msg-error/msg-error.component';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-recuperar-contrasena',
  templateUrl: './recuperar-contrasena.component.html',
  styleUrls: ['./recuperar-contrasena.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    FeatherModule,
    NgIf,
    ReactiveFormsModule,
    MsgErrorComponent,
  ],
})
export class RecuperarContrasenaComponent implements OnInit {
  formulario: UntypedFormGroup;
  formularioVerificarCodigo: UntypedFormGroup;
  validarCodigo = false;
  mensajes = {};
  estadoSolicituContrasena = false;
  msgError = '';
  titulo = 'Recuperar contraseña';

  constructor(private authService: AuthService, private route: Router) {}

  ngOnInit(): void {
    this.crearFormulario();
    this.crearMensajes();
  }

  crearFormulario(): void {
    this.formulario = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(patronValidacion.email),
      ]),
    });
  }

  crearMensajes(): void {
    this.mensajes = {
      email: [
        { error: 'required', message: 'Campo requerido' },
        { error: 'pattern', message: 'Formato incorrecto' },
      ],
    };
  }

  submit(): void {
    if (this.formulario.invalid) {
      this.formulario.markAllAsTouched();
    } else {
      this.authService
        .solicitarNuevaContrasena(this.formulario.getRawValue())
        .subscribe(
          (resp) => {
            this.estadoSolicituContrasena = true;
            this.authService.authEmail = this.formulario.controls.email.value;
            this.formulario.reset();
            this.route.navigate(['cambio-clave']);
          },
          (err) => {
            this.msgError = err.error.message;
          }
        );
    }
  }
}
