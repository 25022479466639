import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';

import { GeneralCoreService } from 'src/app/core/services/general-core/general-core.service';
import { SoatService } from 'src/app/onboarding/services/soat/soat.service';
import { AuthService } from 'src/app/sesion/services/auth/auth.service';
import { IpService } from 'src/app/shared/services/ip/ip.service';
import { PasarelaService } from 'src/app/onboarding/services/pasarela/pasarela.service';
import { AccidentesService } from 'src/app/onboarding/services/accidentes/accidentes.service';

import { InformacionSoatForm } from './informacion-soat.form';
import { InformacionSoat } from 'src/app/onboarding/models/informacion-soat.interfaz';
import { InformacionTomadorSoatForm } from '../informacion-tomador-soat/informacion-tomador-soat.form';
import { OnBoardingSoat } from 'src/app/onboarding/models/onboardingSoat.model';
import { DatosErradosRunt, DatosErradosRuntElement } from 'src/app/onboarding/models/datos-errados-runt.interfaz';
import { TerminosPoliticasService } from 'src/app/shared/services/terminos-politicas/terminos-politicas.service';
import { InformacionTomadorSoatComponent } from '../informacion-tomador-soat/informacion-tomador-soat.component';
import { BotonesOnboardingComponent } from '../../botones-onboarding/botones-onboarding.component';
import { FormularioDatosErradosComponent } from '../formulario-datos-errados/formulario-datos-errados.component';
import { FeatherModule } from 'angular-feather';
import { TerminosPoliticasComponent } from '../../../../shared/components/terminos-politicas/terminos-politicas.component';
import { MsgErrorComponent } from '../../../../shared/components/msg-error/msg-error.component';
import { NgIf, NgFor, CurrencyPipe } from '@angular/common';


@Component({
    selector: 'app-informacion-soat',
    templateUrl: './informacion-soat.component.html',
    styleUrls: ['./informacion-soat.component.scss',
    ],
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, MsgErrorComponent, TerminosPoliticasComponent, FeatherModule, FormularioDatosErradosComponent, BotonesOnboardingComponent, InformacionTomadorSoatComponent, NgFor, CurrencyPipe]
})
export class InformacionSoatComponent implements OnInit {
  @Output() eventoVolver: EventEmitter<any> = new EventEmitter();
  @Output() eventoGuardar: EventEmitter<any> = new EventEmitter();

  public formulario: UntypedFormGroup;
  public formularioTomador: UntypedFormGroup;
  public formularioRunt = new UntypedFormGroup({});
  public dataFormulario: any;
  public dataFormularioRunt: any;
  public estadoConsultaPlaca = false;
  public informacionVehiculo: InformacionSoat;
  public idCotizacion: number;
  public listDatosErradosRunt = [];
  public listaFormulario: any[] = [];
  public dataFormularioRuntAct = [];
  public valorTotal: number;
  public notFoundText = 'No hay datos';
  public descuentos: any[] = [];
  public onboardingSoat: OnBoardingSoat;
  public pasoActual: number = 0;
  public tituloPaso: string;
  public registrarTerminos = false;
  public session: boolean;
  public dataSession: any;

  constructor(private soatService: SoatService,
              private generalCoreService: GeneralCoreService,
              private terminosPoliticasService: TerminosPoliticasService,
              private pasarelaService: PasarelaService,
              private ipService: IpService,
              private authService: AuthService,
              private accidentesService: AccidentesService) {
    this.listaFormulario = InformacionTomadorSoatForm.getForm();
  }

  ngOnInit(): void {
    localStorage.removeItem('onboarding');
    this.dataSession = this.authService.getSession();
    this.session = this.dataSession?.roles[0] === 'Cliente' ? true : false;
    
    this.dataFormularioRunt = InformacionSoatForm.getFormRunt();
    this.cargarDatosFormulario();
    this.dataFormulario = InformacionSoatForm.getForm();
    this.formularioTomador = this.crearFormularioSoat();
    this.titulosPasos()
  }

  private cargarDatosFormulario(): void {
    const dataLocalFormulario = JSON.parse(localStorage.getItem('onboarding'));
    if (dataLocalFormulario && dataLocalFormulario.datosErradosRunt) {
      this.listDatosErradosRunt = [...dataLocalFormulario.datosErradosRunt, ...dataLocalFormulario.datosErradosRuntNoNecesariosCotizacion];
      this.formularioRunt = this.crearFormularioRunt();
    }
    this.onboardingSoat = dataLocalFormulario

    if (dataLocalFormulario && dataLocalFormulario.vehiculo) {
      this.informacionVehiculo = new InformacionSoat(dataLocalFormulario);
      this.idCotizacion = dataLocalFormulario.tuCotizacionId;

      this.formulario = new UntypedFormGroup({
        placa: new UntypedFormControl(dataLocalFormulario.vehiculo.placa, [Validators.required])

      });
    } else {
      this.formulario = new UntypedFormGroup({
        placa: new UntypedFormControl('', [Validators.required])
      });
    }
    this.pasoActual = localStorage.getItem('pasoActual') ? Number(localStorage.getItem('pasoActual')) : 0;
    localStorage.setItem('pasoActual', this.pasoActual.toString())
    this.valorTotal = this.pasoActual === 3 ? this.onboardingSoat?.totalAPagar : this.onboardingSoat?.tarifa?.costoTotal ?? 0;

  }

  public consultarPlaca(): void {
    if (!this.formulario.invalid) {
      this.estadoConsultaPlaca = true;
      this.soatService.consultarPlacaSoat({ placa: this.formulario.controls.placa.value }).subscribe((resp: any) => {
        if (resp.data.urlPago && !resp.data.urlPago?.urlPago ) {
          this.generalCoreService.openDialog({
            titulo: 'Pago pendiente',
            mensaje: 'La placa suministrada tiene un proceso de pago pendiente. Puedes volver a intentarlo en 30 minutos',
            tipo: 'warning',
            hasCancelButton: false,
            callbackConfirm: () => {
              
            },
            callbackCancel: () => {
              
            }
          });
          this.estadoConsultaPlaca = false;
          return
        }
        if (resp.data.urlPago?.urlPago){
          this.generalCoreService.openDialog({
            titulo: 'Pago pendiente',
            mensaje: 'La placa suministrada tiene un proceso de pago pendiente. ¿Quieres continuar con el proceso de pago?',
            tipo: 'warning',
            confirmButtonText: 'Si, pagar',
            cancelButtonText: 'No, cancelar',
            hasCancelButton: true,
            callbackConfirm: () => {
              window.open(resp.data.urlPago?.urlPago, '_self');
            },
            callbackCancel: () => {
              
            }
          });
          this.estadoConsultaPlaca = false;
          return
        }

        if (!this.session) {
          this.terminosPoliticasService.setTerminosCondiciones({
            "tuCotizacionId": resp.data.tuCotizacionId,
            "terminosCondiciones": true
          }).subscribe( resp => {});
        }
        
        this.registrarTerminos = true;
        const { descuentos, ...data } = resp.data
        this.estadoConsultaPlaca = false;
        this.informacionVehiculo = new InformacionSoat(resp.data);

        this.listDatosErradosRunt = [...resp.data.datosErradosRuntNoNecesariosCotizacion, ...resp.data.datosErradosRunt];
        this.formularioRunt = this.crearFormularioRunt();
        this.idCotizacion = resp.data.tuCotizacionId;
        this.onboardingSoat = new OnBoardingSoat({ ...this.onboardingSoat, ...data });
        localStorage.setItem('onboarding', JSON.stringify(this.onboardingSoat));
        this.valorTotal = this.onboardingSoat.tarifa?.costoTotal ?? 0;
        this.pasoActual = this.dataFormularioRuntAct.length ? 1 : 2;
        localStorage.setItem('pasoActual', this.pasoActual.toString())
        this.titulosPasos()
      }, err => {
        const configDialog = {
          titulo: 'Atención',
          tipo: 'error',
        }
        if (err.error.message && err.error.message.includes('vigente')) {
          configDialog.tipo = 'warning'
          configDialog.titulo = 'Lo sentimos'
        }
        this.generalCoreService.openDialog({
          titulo: configDialog.titulo,
          mensaje: err.error.message,
          tipo: configDialog.tipo,
          hasCancelButton: false,
        });
        this.estadoConsultaPlaca = false;
      });
    } else {
      this.formulario.markAllAsTouched();
    }
  }

  public titulosPasos(){
    switch (this.pasoActual) {
      case 0:
        this.tituloPaso = 'Para empezar vamos a necesitar la <b class="fw-bold">placa de tu vehículo</b>'
        break;
      case 1:
        this.tituloPaso = 'Para cotizar tu SOAT ingresa los <b class="fw-bold">datos faltantes de tu vehículo</b>'
        break;
      case 2:
        this.tituloPaso = 'Ingresa tus datos y válida tu <b class="fw-bold">descuento</b>'
        break;
      case 3:
        this.tituloPaso = 'Estas a un paso de adquirir <b class="fw-bold">tu SOAT</b>'
        break;
    
      default:
        break;
    }
  }

  

  public onKeydown(event): void {
    if (event.key === 'Enter') {
      this.consultarPlaca();
    }
  }

  public crearFormularioRunt() {
    const formularioRunt = new UntypedFormGroup({});
    this.listDatosErradosRunt.forEach(element => {
      if (this.dataFormularioRunt[element]) {
        this.dataFormularioRuntAct.push(this.dataFormularioRunt[element])
        formularioRunt.addControl(element, new UntypedFormControl(
        {
          value: '',
          disabled: this.dataFormularioRunt[element].disabled
        },
        this.dataFormularioRunt[element].validations));
      } else {
        this.dataFormularioRunt[element] = {
          label: element,
          value: element,
          col: 'col-12',
          disabled: false,
          readonly: false,
          visible: false,
          formControlName: element,
          placeholder: '',
          type: 'text',
          validations: [],
        } 
        this.dataFormularioRuntAct.push(this.dataFormularioRunt[element])
        formularioRunt.addControl(element, new UntypedFormControl(
          {
            value: '',
            disabled: this.dataFormularioRunt[element].disabled
          },
          this.dataFormularioRunt[element].validations));
      }
    });
    this.obtenerDatosRunt();
    return formularioRunt;
  }

  public obtenerDatosRunt() {
    this.dataFormularioRuntAct.forEach(item => {
      if (item.servicio === 'lineas' && this.informacionVehiculo?.idMarca) {
        this.soatService.obtenerDatosRunt(item.servicio, this.informacionVehiculo?.idMarca).subscribe(resp => {
          item.opciones = resp.data;
        })
      } else if (item.servicio && item.servicio != 'lineas') {
        this.soatService.obtenerDatosRunt(item.servicio).subscribe(resp => {
          item.opciones = resp.data;
        })
      }
    })
  }

  public changeSelectDatosErrados(value) {
    if (value.item.formControlName === 'idMarca') {
      this.soatService.obtenerDatosRunt('lineas', value.event.idMarcaVehiculo).subscribe(resp => {
        const selectLineas = this.dataFormularioRuntAct.find(item => item.formControlName === 'idLinea')
        selectLineas.opciones = resp.data
      })
    }
  }

  public obtenerDatosErradosRunt(): DatosErradosRuntElement[] {
    const dataRunt = [];
    this.dataFormularioRuntAct.forEach(item => {
      if (this.formularioRunt.get(item.formControlName).value || item.value) {
        if (!this.formularioRunt.get(item.formControlName).value) {
          dataRunt.push({ dato: item.formControlName, valor: item.value})
        } else {
          dataRunt.push({ dato: item.formControlName, valor: this.formularioRunt.get(item.formControlName).value })
        }
      }
    })
    return dataRunt
  }

  private crearFormularioSoat(): UntypedFormGroup {
    const formulario = new UntypedFormGroup({});
    this.listaFormulario.forEach(element => {
      formulario.addControl(element.formControlName, new UntypedFormControl(
        {
          value: element.value,
          disabled: element.disabled
        },
        element.validations));
    });
    return formulario;
  }

  public enviarDatosErradosRunt() {
    if (this.formularioRunt.invalid) {
      this.formularioRunt.markAllAsTouched();
    } else {
      const datosErradosRunt: DatosErradosRunt = {
        tuCotizacionId: this.idCotizacion,
        placa: this.informacionVehiculo.placa,
        datosErradosRunt: this.obtenerDatosErradosRunt()
      }
      this.soatService.consultarPlacaSoat(datosErradosRunt).subscribe(resp => {
        const { descuentos, ...data } = resp.data
        this.informacionVehiculo = new InformacionSoat(resp.data);
        this.valorTotal = this.informacionVehiculo.tarifa?.costoTotal;
        this.onboardingSoat = { ...this.onboardingSoat, ...data };
        localStorage.setItem('onboarding', JSON.stringify(this.onboardingSoat));
        this.pasoActual = 2;
        localStorage.setItem('pasoActual', this.pasoActual.toString())
        this.titulosPasos()
      })
    }
  }

  public submit(): void {
    if (this.formularioTomador.invalid) {
      this.formularioTomador.markAllAsTouched();
    } else {
      const data: any = {
        tuCotizacionId: this.idCotizacion,
        datosErradosRunt: this.obtenerDatosErradosRunt(),
        ...this.formularioTomador.getRawValue()
      };
      this.soatService.enviarDatosTomadorSoat(data).subscribe(resp => {
        this.descuentos = resp.data.descuentos;
        this.valorTotal = resp.data.totalAPagar;
        this.onboardingSoat = { ...this.onboardingSoat, ...resp.data, ...{ datosTomador: this.formularioTomador.getRawValue() } };
        localStorage.setItem('onboarding', JSON.stringify(this.onboardingSoat));
        this.pasoActual = 3;
        localStorage.setItem('pasoActual', this.pasoActual.toString())
        this.titulosPasos()
      })
    }
  }

  public existeUsuario(){
    this.accidentesService.existeUsuario(this.onboardingSoat.tuCotizacionId).subscribe(resp => {
      if (resp.data) {
        this.generalCoreService.openDialog({
          titulo: 'Usuario registrado',
          mensaje: resp.message,
          tipo: 'warning',
          confirmButtonText: 'Si, vincular',
          cancelButtonText: 'No, cancelar',
          hasCancelButton: true,
          callbackConfirm: () => {
            this.enviarCodigoEmail()
          },
          callbackCancel: () => {
            this.cambiarTokenCliente()
          }
        });
      } else {
        this.cambiarTokenCliente()
      }
    })
  }

  public enviarCodigoEmail() {
    this.accidentesService.enviarCodigoEmail(this.onboardingSoat.tuCotizacionId).subscribe( resp => {
      this.generalCoreService.openDialog({
        componente: 'otp',
        titulo: 'Código de verificación',
        mensaje: `Se ha enviado un código al correo ${this.onboardingSoat.datosTomador.email}`,
        tipo: 'warning',
        confirmButtonText: 'Verificar código',
        cancelButtonText: 'Cancelar',
        hasCancelButton: true,
        callbackConfirm: (value) => {
          this.cambiarTokenCliente(value.otp)
        }
      });
    })
  }

  public irAPagar() {
    const session = JSON.parse(localStorage.getItem('session'));
    
    if (session.roles[0] == 'Onbording') {
      this.existeUsuario()
    } else {
      this.enviarPasarelaPago()
    }
  }

  public cambiarTokenCliente(codigo = null) {
    const dataSession = this.authService.getSession();
    if (dataSession.roles[0] === 'Onbording') {
      this.authService.getTokenCliente({
        tuCotizacionId: this.onboardingSoat.tuCotizacionId,
        codigo: codigo
      }).subscribe(resp => {
        this.authService.setSession(resp.data)
        this.enviarPasarelaPago()
      })
    } else {
      this.enviarPasarelaPago()
    }
  }

  public enviarPasarelaPago() {
    this.pasarelaService.realizarPagoCoink({
      tuCotizacionId: this.onboardingSoat.tuCotizacionId,
      ipAddr: this.ipService.getIpCliente()
    }).subscribe(resp => {
      this.generalCoreService.openSpinner(true);
      this.dataSession = JSON.parse(localStorage.getItem('session'));
      if (this.dataSession.roles[0] === 'OnbordingCliente') {
        localStorage.removeItem('session');
      }
      localStorage.removeItem('onboarding');
      window.open(resp.data.urlPago, '_self');
    });
  }

  public nuevaCotizacion(): void {
    this.generalCoreService.openDialog({
      titulo: 'Nueva cotización',
      mensaje: `¿Deseas realizar una nueva cotización?`,
      tipo: 'warning',
      confirmButtonText: 'Si, cotizar',
      cancelButtonText: 'No, volver',
      hasCancelButton: true,
      callbackConfirm: () => {
        localStorage.removeItem('onboarding');
        localStorage.removeItem('pasoActual');
        location.reload();
      },
      callbackCancel: () => {
      }
    });
  }

}
