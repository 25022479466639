import { InformacionSoat } from './informacion-soat.interfaz';

export class OnBoardingSoat {
    public tuCotizacionId: number;
    public codigoEstado: number;
    public vehiculo: InformacionSoat;
    public datosErradosRunt: any[];
    public datosErradosRuntNoNecesariosCotizacion: any[];
    public datosTomador: any;
    public siguienteComponente: string;
    public tarifa: any
    public descuentos: any[]
    public fechaInicioVigencia: string;
    public totalAPagar: number;

    constructor(data: any){
        this.tuCotizacionId = data?.tuCotizacionId ?? null;
        this.codigoEstado = data?.codigoEstado ?? null;
        this.vehiculo = data?.vehiculo ?? null;
        this.datosErradosRunt = data?.datosErradosRunt ?? [];
        this.datosErradosRuntNoNecesariosCotizacion = data?.datosErradosRuntNoNecesariosCotizacion ?? [];
        this.datosTomador = data?.datosTomador ?? null;
        this.siguienteComponente = data?.siguienteComponente ?? '';
        this.tarifa = data?.tarifa ?? {};
        this.descuentos = data?.descuentos ?? [];
        this.fechaInicioVigencia = data?.fechaInicioVigencia ?? '';
        this.totalAPagar = data?.totalAPagar ?? 0;
    }
}

