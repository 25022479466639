<div class="d-flex justify-content-center my-5 text-primary pb-2 text-center">
  <h4 class="w-75 fw-light" [innerHTML]="tituloPaso"></h4>
</div>

<form [formGroup]="formulario" *ngIf="!informacionVehiculo">
  <div class="row">
    <div class="mb-5 col-md-12">
      <label for="input-placa" class="form-label">Placa </label>
      <input type="text" class="form-control text-uppercase" id="input-placa" (keyup)="onKeydown($event)"
        formControlName="placa" aria-describedby="emailHelp" maxlength="6">

      <app-msg-error [messages]="dataFormulario.placa.errors" [control]="formulario.controls['placa']"></app-msg-error>
    </div>
  </div>
  <app-terminos-politicas *ngIf="!session" [form]="formulario"></app-terminos-politicas>
</form>

<div class="row" *ngIf="!informacionVehiculo">
  <div class="mb-3 d-flex justify-content-center col-md-12">
    <button type="button" class="btn btn-primary" (click)="consultarPlaca()">
      <span *ngIf="estadoConsultaPlaca" class="spinner-border spinner-border-sm" role="status"
        aria-hidden="true"></span>
      <span *ngIf="estadoConsultaPlaca"> Consultando...</span>
      <span *ngIf="!estadoConsultaPlaca">Consultar</span>
    </button>
  </div>
</div>

<div *ngIf="informacionVehiculo && pasoActual === 1">
  <div class="card">
    <div class="card-header bg-primary">
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="text-white mb-0 fs-3">Placa</h5>
        <h2 class="text-white fw-bold mb-0">{{informacionVehiculo.placa}}</h2>
      </div>
    </div>
    <div class="card-body row">
      <h5 class="card-title">
        <i-feather class="text-primary me-2 mb-2" name="info"></i-feather>
        Información del vehículo
      </h5>
      <h6 class="card-subtitle mb-2 text-muted"></h6>
      <div class="col-12 col-sm-6 mb-3">
        <small class="text-grey-dark fw-bold">Clase de vehículo</small>
        <p class="card-text">{{informacionVehiculo.claseVehiculo}}</p>
      </div>
      <div class="col-12 col-sm-6 mb-3">
        <small class="text-grey-dark fw-bold">Modelo</small>
        <p class="card-text">{{informacionVehiculo.modelo}}</p>
      </div>
      <div class="col-12 col-sm-6 mb-3">
        <small class="text-grey-dark fw-bold">Marca</small>
        <p class="card-text">{{informacionVehiculo.marca}}</p>
      </div>
      <div class="col-12 col-sm-6 mb-3">
        <small class="text-grey-dark fw-bold">Línea</small>
        <p class="card-text">{{informacionVehiculo.linea}}</p>
      </div>
    </div>
  </div>
  <div class="mt-4" *ngIf="dataFormularioRuntAct.length">
    <div class="card">
      <div class="card-body">
        <app-formulario-datos-errados [formularioRunt]="formularioRunt" [dataFormularioRuntAct]="dataFormularioRuntAct" (changeSelectEmit)="changeSelectDatosErrados($event)">
        </app-formulario-datos-errados>
      </div>
    </div>
  </div>
  <div
    class="d-flex justify-content-md-between justify-content-center align-items-center flex-column-reverse flex-md-row pb-5">
    <button class="btn btn-link ps-md-0 h-100" (click)="nuevaCotizacion()">Nueva cotizacion</button>
    <app-botones-onboarding class="d-flex justify-content-center justify-content-md-end" activarBotonVolver="false"
      (eventoSiguiente)="enviarDatosErradosRunt()">
    </app-botones-onboarding>
  </div>
</div>


<div *ngIf="informacionVehiculo && (pasoActual === 2 || pasoActual === 3)">
  <div class="row">
    <div class="col-12 col-md-7 order-2 order-md-1">
      <div class="card">
        <div class="card-body row ">
          <div *ngIf="!onboardingSoat?.mediosPagoHabilitados">
            <!-- <h5 class="card-title mt-2 mb-4 text-primary fw-bold">
              Ingresa tus datos y válida tu descuento
            </h5> -->
            <app-informacion-tomador-soat [formulario]="formularioTomador"></app-informacion-tomador-soat>
          </div>
          <div *ngIf="onboardingSoat?.mediosPagoHabilitados">
            <h5 class="card-title mt-2 mb-4 text-primary fw-bold">
              <i-feather class="text-primary me-2" name="calendar"></i-feather>
              Vigencia de la póliza
            </h5>
            <div class="col-12 mb-2 d-flex justify-content-between ps-4 ps-4">
              <small class="text-grey-dark fw-bold">Fecha inicio vigencia</small>
              <p class="card-text">{{informacionVehiculo.fechaInicioVigencia}}</p>
            </div>
            <h5 class="card-title my-4 text-primary fw-bold">
              <i-feather class="text-primary me-2" name="info"></i-feather>
              Información del vehículo
            </h5>
            <div class="col-12 mb-2 d-flex justify-content-between ps-4">
              <small class="text-grey-dark fw-bold">Placa</small>
              <p class="card-text">{{informacionVehiculo.placa}}</p>
            </div>
            <div class="col-12 mb-2 d-flex justify-content-between ps-4">
              <small class="text-grey-dark fw-bold">Clase de vehículo</small>
              <p class="card-text">{{informacionVehiculo.claseVehiculo}}</p>
            </div>
            <div class="col-12 mb-2 d-flex justify-content-between ps-4">
              <small class="text-grey-dark fw-bold">Modelo</small>
              <p class="card-text">{{informacionVehiculo.modelo}}</p>
            </div>
            <div class="col-12 mb-2 d-flex justify-content-between ps-4">
              <small class="text-grey-dark fw-bold">Marca</small>
              <p class="card-text">{{informacionVehiculo.marca}}</p>
            </div>
            <div class="col-12 mb-2 d-flex justify-content-between ps-4">
              <small class="text-grey-dark fw-bold">Línea</small>
              <p class="card-text">{{informacionVehiculo.linea}}</p>
            </div>
            <h5 class="card-title my-4 text-primary fw-bold">
              <i-feather class="text-primary me-2" name="user"></i-feather>
              Datos personales
            </h5>
            <div class="col-12 mb-2 d-flex justify-content-between ps-4">
              <small class="text-grey-dark fw-bold">Documento de identidad</small>
              <p class="card-text">{{onboardingSoat.datosTomador.numeroDocumento}}</p>
            </div>
            <div class="col-12 mb-2 d-flex justify-content-between ps-4">
              <small class="text-grey-dark fw-bold">Email</small>
              <p class="card-text">{{onboardingSoat.datosTomador.email}}</p>
            </div>
            <div class="col-12 mb-2 d-flex justify-content-between ps-4">
              <small class="text-grey-dark fw-bold">Número de celular</small>
              <p class="card-text">{{onboardingSoat.datosTomador.numeroCelular}}</p>
            </div>
            <div class="col-12 mb-2 d-flex justify-content-between ps-4">
              <small class="text-grey-dark fw-bold">Dirección</small>
              <p class="card-text">{{onboardingSoat.datosTomador.direccion}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-5 order-1 order-md-2">
      <div class="card mb-4">
        <div class="card-body card-valor p-4 pb-3" *ngIf="onboardingSoat.descuentos.length">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <p class="mb-0">Valor neto:</p>
            <p class="text-grey-dark mb-0">{{informacionVehiculo.tarifa.costoTotal | currency:'$':'code':'1.0':'en'}}</p>
          </div>
          <div class="d-flex justify-content-between align-items-center mb-2" *ngFor="let descuento of onboardingSoat.descuentos">
            <p class="mb-0 me-5">{{descuento.descripcion}}:</p>
            <p class="text-primary mb-0">-{{descuento.valor | currency:'$':'code':'1.0':'en'}}</p>
          </div>
        </div>
        <div class="card-footer bg-primary text-white d-flex justify-content-between align-items-center px-4">
          <p class="mb-0">Valor de tu SOAT </p>
          <h3 class="mb-0">{{valorTotal | currency:'$':'code':'1.0':'en'}}</h3>

        </div>
      </div>
      <div class="card mb-4" *ngIf="!onboardingSoat?.mediosPagoHabilitados">
        <!-- <div class="card-header d-flex justify-content-between align-items-center text-grey-dark">
          <h4 class="mb-0">Placa</h4>
          <h4 class="fw-bold mb-0">{{informacionVehiculo.placa}}</h4>
        </div> -->
        <div class="card-body p-4 text-grey-dark">
          <!-- <div class="d-flex justify-content-between align-items-center mb-3">
            <h4 class="mb-0">Placa</h4>
            <h4 class="fw-bold mb-0">{{informacionVehiculo.placa}}</h4>
          </div> -->
          <h5 class="card-title mt-1 mb-3 text-primary fw-bold">
           Vigencia de la póliza
          </h5>
          <div class="col-12 mb-2 d-flex justify-content-between">
            <small class="text-grey-dark fw-bold">Fecha inicio vigencia</small>
            <p class="card-text">{{informacionVehiculo.fechaInicioVigencia}}</p>
          </div>
          <h5 class="card-title mt-4 mb-3 text-primary fw-bold">
            Información del vehículo
          </h5>
          <div class="col-12 mb-2 d-flex justify-content-between">
            <small class="fw-bold">Placa</small>
            <p class="card-text">{{informacionVehiculo.placa}}</p>
          </div>
          <div class="col-12 mb-2 d-flex justify-content-between">
            <small class="fw-bold">Clase de vehículo</small>
            <p class="card-text">{{informacionVehiculo.claseVehiculo}}</p>
          </div>
          <div class="col-12 mb-2 d-flex justify-content-between">
            <small class="fw-bold">Modelo</small>
            <p class="card-text">{{informacionVehiculo.modelo}}</p>
          </div>
          <div class="col-12 mb-2 d-flex justify-content-between">
            <small class="fw-bold">Marca</small>
            <p class="card-text">{{informacionVehiculo.marca}}</p>
          </div>
          <div class="col-12 mb-2 d-flex justify-content-between">
            <small class="fw-bold">Línea</small>
            <p class="card-text">{{informacionVehiculo.linea}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-botones-onboarding *ngIf="!onboardingSoat?.mediosPagoHabilitados" [activarBotonVolver]="false"
    (eventoSiguiente)="submit()" labelBtnContinuar="Obtener descuento">
  </app-botones-onboarding>

  <div class="d-flex justify-content-center py-5" *ngIf="onboardingSoat?.mediosPagoHabilitados">
    <button *ngIf="!onboardingService?.riesgoEstandar" type="button" class="btn btn-success" (click)="irAPagar()">Ir a
      pagar
      <i-feather class="text-white" name="arrow-right"></i-feather>
    </button>
  </div>
</div>
