import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { InformacionVehiculo } from 'src/app/onboarding/models/informacion-vehiculo.interfaz';
import { OnboardingService } from 'src/app/onboarding/services/onboarding/onboarding.service';
import { ParametricosService } from 'src/app/onboarding/services/parametricos/parametricos.service';
import { InformacionBiciForm } from './informacion-bici.form';

import moment from 'moment';
import 'moment/locale/es';
import { patronValidacion } from 'src/app/onboarding/enums/validaciones';
import { CiudadesCirculacion } from 'src/app/onboarding/models/ciudades-circulacion.interfaz';
import { Validation } from 'src/app/onboarding/utils/validation';
import { BotonesOnboardingComponent } from '../../botones-onboarding/botones-onboarding.component';
import { MsgErrorComponent } from '../../../../shared/components/msg-error/msg-error.component';
import { CalendarModule } from 'primeng/calendar';
import { NgxMaskDirective } from 'ngx-mask';
import { NgClass, NgFor, NgIf, TitleCasePipe } from '@angular/common';

@Component({
    selector: 'app-informacion-bici',
    templateUrl: './informacion-bici.component.html',
    styleUrls: ['./informacion-bici.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        ReactiveFormsModule,
        NgFor,
        NgIf,
        NgxMaskDirective,
        CalendarModule,
        MsgErrorComponent,
        BotonesOnboardingComponent,
        TitleCasePipe,
    ],
})
export class InformacionBiciComponent implements OnInit {
  @Output() eventoVolver: EventEmitter<any> = new EventEmitter();
  @Output() eventoGuardar: EventEmitter<any> = new EventEmitter();

  public formulario: UntypedFormGroup;
  public listaFormulario: any[] = [];
  public listaCiudades: CiudadesCirculacion[] = [];
  public informacionVehiculo: InformacionVehiculo;
  public listaMarcas: any[] = [];
  public listaMeses: any[] = [];
  public listaTipos: any[] = [];
  public marcaSeleccionada: any;
  public tipoMarcaSeleccionada: any;
  public idCotizacion: number;
  public idProducto = 1;
  public oldYears: number;
  public maxDate: Date;
  public yearRange: string;
  public valorMax: string;

  constructor(
    private parametricosService: ParametricosService,
    private onboardingService: OnboardingService
  ) {
    this.listaFormulario = InformacionBiciForm.getForm();
    // this.es = localDate;
    this.maxDate = new Date();
  }

  ngOnInit(): void {
    this.formulario = this.crearFormulario();
    this.obtenerAnios();
    this.obtenerMeses();
    this.cargarDatosFormulario();
    this.obtenerCiudadesCirculacion();
    this.calendarYears();
    this.valorMaximoProducto();
  }

  private crearFormulario(): UntypedFormGroup {
    const formulario = new UntypedFormGroup({});
    this.listaFormulario.forEach((element) => {
      if (element.formControlName === 'valor') {
        element.validations = this.obtenerValidacionesPrecioProducto();
      }
      formulario.addControl(
        element.formControlName,
        new UntypedFormControl(
          {
            value: element.value,
            disabled: element.disabled,
          },
          element.validations
        )
      );
    });
    return formulario;
  }

  valorMaximoProducto() {
    this.valorMax = this.idProducto === 1 ? '45.000.000' : '10.000.000';
  }

  calendarYears() {
    const minYear =
      this.idProducto === 1
        ? new Date().getFullYear() - 5
        : new Date().getFullYear() - 3;
    const maxYear = new Date().getFullYear();
    this.yearRange = minYear + ':' + maxYear;
  }

  obtenerAnios() {
    this.listaFormulario.forEach((element) => {
      if (element.formControlName === 'anio') {
        element.opciones = this.years();
      }
    });
  }

  obtenerMeses() {
    this.listaFormulario.forEach((element) => {
      if (element.formControlName === 'mes') {
        this.listaMeses = moment
          .months()
          .map((mes, index) => ({
            id: String(++index).padStart(2, '0'),
            descripcion: mes,
          }));
        element.opciones = this.listaMeses;
      }
    });
  }

  years() {
    const years = [];
    const dateStart = moment().add(1, 'year');
    this.oldYears = this.idProducto === 1 ? 6 : 4;
    for (let i of Array(this.oldYears)) {
      const year = dateStart.add(-1, 'year').year();
      years.push({ id: year, descripcion: year });
    }
    return years;
  }

  obtenerCiudadesCirculacion(): void {
    this.parametricosService
      .obtenerParametricas('CiudadesCirculacion')
      .subscribe((resp: any) => {
        this.listaFormulario.forEach((element) => {
          if (element.formControlName === 'ciudadCirculacion') {
            element.opciones = resp;
            this.listaCiudades = resp;
          }
        });
      });
  }

  obtenerMarcas(marca?: string): void {
    let form = this.formulario.value;
    this.onboardingService.obtenerMarcas(form.anio).subscribe((resp: any) => {
      this.listaFormulario.forEach((element) => {
        if (element.formControlName === 'marca') {
          this.listaMarcas = resp;
          element.opciones = resp;
        }
      });
      if (marca) {
        this.changeMarca(marca);
      } else {
        this.formulario.get('tipoBici').setValue('');
      }
    });
  }

  changeMarca(value) {
    this.marcaSeleccionada = this.listaMarcas.find(
      (marca) => marca.marca == value
    );
    this.listaFormulario.forEach((element) => {
      if (element.formControlName === 'tipoBici' && this.marcaSeleccionada) {
        this.listaTipos = this.marcaSeleccionada.tiposbicicletas.map(
          (tipobicicleta) => ({
            id: tipobicicleta.fasecolda,
            descripcion: tipobicicleta.tipo,
          })
        );
        element.opciones = this.listaTipos;
      }
    });
  }

  get marca() {
    return this.formulario.get('marca');
  }

  changeTipoMarca(value, control) {
    if (control === 'tipoBici') {
      this.tipoMarcaSeleccionada = this.marcaSeleccionada.tiposbicicletas.find(
        (fasecolda) => fasecolda.fasecolda == value
      );
    }
    if (control === 'anio') {
      this.obtenerMarcas();
    }
  }

  crearCotizacion(): void {
    let form = this.formulario.value;
    if (!this.tipoMarcaSeleccionada && this.idProducto === 1) {
      this.tipoMarcaSeleccionada = this.marcaSeleccionada.tiposbicicletas.find(
        (fasecolda) => fasecolda.fasecolda == this.formulario.value.tipoBici
      );
    }
    this.onboardingService
      .crearCotizacionBici({
        fechaCompra: form.fechaCompra,
        valorCompra: form.valor,
        bicicleta: {
          marca:
            this.idProducto === 1
              ? this.marcaSeleccionada.marca
              : 'OTRAS MARCAS',
          modelo: form.anio,
          tipoBicicleta: {
            tipo:
              this.idProducto === 1
                ? this.tipoMarcaSeleccionada?.tipo
                : 'GENERICO PATINETA - 150W-22V',
            fasecolda:
              this.idProducto === 1
                ? this.tipoMarcaSeleccionada?.fasecolda
                : '99927390',
          },
        },
      })
      .subscribe((resp: any) => {
        const ciudad = this.listaCiudades.find(
          (m) => m.id === this.formulario.controls.ciudadCirculacion.value
        );

        const data: any = {
          siguienteComponente: 'datosTomador',
          idCotizacion: resp.data.tuCotizacionId,
          datosPlanes: resp.data.planes,
          datosVehiculo: {
            informacionVehiculo: resp.data,
            ciudadCirculacion: ciudad,
            idCotizacion: resp.data.tuCotizacionId,
          },
          fecha: moment(this.formulario.value.fechaCompra).valueOf(),
          idProducto: this.idProducto,
        };
        this.eventoGuardar.emit(data);
      });
  }

  private cargarDatosFormulario(): void {
    const dataLocalFormulario = JSON.parse(localStorage.getItem('onboarding'));

    if (dataLocalFormulario && dataLocalFormulario.datosVehiculo) {
      this.informacionVehiculo = new InformacionVehiculo(
        dataLocalFormulario.datosVehiculo.informacionVehiculo
      );
      this.idCotizacion = dataLocalFormulario.datosVehiculo.idCotizacion;
      if (dataLocalFormulario.idProducto === 2) {
        this.changeProducto(dataLocalFormulario.idProducto);
      }
      this.formulario = new UntypedFormGroup({
        fechaCompra: new UntypedFormControl(
          new Date(dataLocalFormulario.fecha),
          [Validators.required]
        ),
        anio: new UntypedFormControl(
          dataLocalFormulario.datosVehiculo.informacionVehiculo.modelo,
          [Validators.required]
        ),
        valor: new UntypedFormControl(
          dataLocalFormulario.datosVehiculo.informacionVehiculo.valorAsegurado,
          this.obtenerValidacionesPrecioProducto()
        ),
        marca: new UntypedFormControl(
          dataLocalFormulario.datosVehiculo.informacionVehiculo.dsMarca,
          [Validators.required]
        ),
        tipoBici: new UntypedFormControl(
          dataLocalFormulario.datosVehiculo.informacionVehiculo.cdLinea,
          [Validators.required]
        ),
        ciudadCirculacion: new UntypedFormControl(
          dataLocalFormulario.datosVehiculo.ciudadCirculacion.id,
          [Validators.required]
        ),
      });
    }
    if (dataLocalFormulario && dataLocalFormulario.datosVehiculo) {
      this.obtenerMarcas(
        dataLocalFormulario.datosVehiculo.informacionVehiculo.dsMarca
      );
    }
  }

  public obtenerValidacionesPrecioProducto() {
    return this.idProducto === 1
      ? [
          Validators.required,
          Validators.pattern(patronValidacion.validacionPunto),
          Validation.rangePricingBici,
        ]
      : [
          Validators.required,
          Validators.pattern(patronValidacion.validacionPunto),
          Validation.rangePricingPatineta,
        ];
  }

  public changeProducto(producto): void {
    this.idProducto = producto;
    this.obtenerAnios();
    this.calendarYears();
    this.valorMaximoProducto();
    if (producto === 2) {
      this.listaFormulario = this.listaFormulario.slice(0, 5);
      this.formulario.get('tipoBici').setValidators([]);
      this.formulario.get('tipoBici').updateValueAndValidity();
      this.formulario.get('marca').setValidators([]);
      this.formulario.get('marca').updateValueAndValidity();
    } else {
      this.listaFormulario.push(...InformacionBiciForm.getForm().slice(-2));
      this.formulario.get('tipoBici').setValidators([Validators.required]);
      this.formulario.get('tipoBici').updateValueAndValidity();
      this.formulario.get('marca').setValidators([Validators.required]);
      this.formulario.get('marca').updateValueAndValidity();
      this.formulario.get('marca').setValue('');
    }
    this.formulario
      .get('valor')
      .setValidators(this.obtenerValidacionesPrecioProducto());
    this.formulario.get('valor').updateValueAndValidity();

    if (this.formulario.value.anio) {
      this.obtenerMarcas();
    }
    this.formulario.get('anio').setValue('');
  }

  submit(): void {
    if (this.formulario.invalid) {
      this.formulario.markAllAsTouched();
    } else {
      this.crearCotizacion();
    }
  }
}
