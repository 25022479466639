<div class="d-flex justify-content-center mt-3 pb-4 pb-md-5">
    <div class="btn-volver position-fixed start-0 my-2 ps-4 ps-md-5 d-flex align-items-center" (click)="volver()" *ngIf="activarBotonVolver">
        <i-feather class="text-light cursor-pointer btn-volver__icon" name="arrow-left"></i-feather>
    </div>
    <button [disabled]="desactivarBoton"
        *ngIf="activarBotonContinuar"
        type="button" 
        class="btn btn-danger"  
        (click)="siguiente()">{{labelBtnContinuar}}
        <i-feather class="text-white" name="arrow-right"></i-feather>
    </button>
</div>
