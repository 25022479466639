import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { GeneralModalComponent } from './core/components/general-modal/general-modal.component';
import { SpinnerComponent } from './core/components/spinner/spinner.component';
import { OnBoardingAutos } from './onboarding/models/onboarding.model';
import { AuthService } from './sesion/services/auth/auth.service';
import { IpService } from './shared/services/ip/ip.service';

const VERSION_APP = environment.version;

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterModule, SpinnerComponent, GeneralModalComponent],
})
export class AppComponent {
  title = 'market-front';

  constructor(
    private authService: AuthService,
    public primeNGConfig: PrimeNGConfig,
    private ipService: IpService,
    private router: Router
  ) {
    this.validarOnBoarding();
    this.validarSesion();
    console.log(VERSION_APP);
    this.setLocale(this.primeNGConfig);
    this.ipService.consultarIpCliente();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(function () {
          if (window.scrollY !== 0) {
            window.scrollTo(0, 0);
          }
        }, 400);
      }
    });
  }

  validarOnBoarding(): void {
    const dataOnb = new OnBoardingAutos(
      JSON.parse(localStorage.getItem('onboarding'))
    );

    const fechaRegistro = new Date(dataOnb.fecha).getTime();
    const fechaActual = new Date().getTime();

    const diff = (fechaActual - fechaRegistro) / 1000 / 60;

    if (diff > 30) {
      localStorage.clear();
    }
  }

  validarSesion(): void {
    const dataSession = JSON.parse(localStorage.getItem('session'));

    if (dataSession) {
      const fechaRegistro = new Date(dataSession.date).getTime();
      const fechaActual = new Date().getTime();
      const diff = (fechaActual - fechaRegistro) / 1000 / 60;

      if (diff > 60) {
        this.authService.setSession(null);
        localStorage.removeItem('session');
      }
    }
  }

  setLocale(config: PrimeNGConfig): void {
    config.setTranslation({
      dayNames: [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
      ],
      dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
      dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
      monthNamesShort: [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
      ],
      today: 'Hoy',
      clear: 'Borrar',
      weekHeader: 'Sm',
    });
  }
}
