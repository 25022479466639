<div class="row">
  <app-card-planes class="col mb-4" *ngFor="let plan of planes" [plan]="plan" (eventoPlanPeronalizado)="getPlan($event)" (eventoPagar)="redirect($event)"></app-card-planes>
</div>
<div class="mt-4 d-flex justify-content-center align-items-end">
  <!-- <p class="mb-1 me-2">Apoyado por</p> -->
  <img src="assets/images/logos/sura.webp" alt="logo de sura" height="40">
</div>

<app-botones-onboarding
 [activarBotonContinuar]="false"
  (eventoVolver)="volver($event)"
  [activarBotonVolver]="false"
  ></app-botones-onboarding>

