import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { GeneralCoreService } from 'src/app/core/services/general-core/general-core.service';
import { InformacionVehiculo } from 'src/app/onboarding/models/informacion-vehiculo.interfaz';
import { OnBoardingAutos } from 'src/app/onboarding/models/onboarding.model';
import { OnboardingService } from 'src/app/onboarding/services/onboarding/onboarding.service';
import { PasarelaService } from 'src/app/onboarding/services/pasarela/pasarela.service';
import { ActivatedRoute, ParamMap, Router, RouterLink } from '@angular/router';
import { IpService } from 'src/app/shared/services/ip/ip.service';
import { AccidentesService } from 'src/app/onboarding/services/accidentes/accidentes.service';
import { AuthService } from 'src/app/sesion/services/auth/auth.service';
import { CoberturasPipe } from '../../pipes/coberturas/coberturas.pipe';
import { BotonesOnboardingComponent } from '../botones-onboarding/botones-onboarding.component';
import { MsgErrorComponent } from '../../../shared/components/msg-error/msg-error.component';
import { FeatherModule } from 'angular-feather';
import { DatosMotoComponent } from '../motos/datos-moto/datos-moto.component';
import { DatosBiciComponent } from '../bici/datos-bici/datos-bici.component';
import { DatosVehiculoComponent } from '../autos/datos-vehiculo/datos-vehiculo.component';
import { NgIf, NgFor, NgClass, CurrencyPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'app-informacion-pago',
    templateUrl: './informacion-pago.component.html',
    styleUrls: ['./informacion-pago.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgIf,
        DatosVehiculoComponent,
        DatosBiciComponent,
        DatosMotoComponent,
        FeatherModule,
        NgFor,
        NgClass,
        MsgErrorComponent,
        RouterLink,
        BotonesOnboardingComponent,
        CurrencyPipe,
        DatePipe,
        CoberturasPipe,
    ],
})
export class InformacionPagoComponent implements OnInit {
  @Output() eventoVolver: EventEmitter<any> = new EventEmitter();
  @Output() eventoGuardar: EventEmitter<any> = new EventEmitter();

  public coberturas: any[] = [];
  public formulario: UntypedFormGroup;
  public dataLocalFormulario: OnBoardingAutos;
  public dataValorCotizacion: any;
  public informacionVehiculo: InformacionVehiculo;
  public planSeleccionado;
  public idMetodo = 0;
  public currentPage: string;
  public otrosMediosDePago = false;
  public datosTomador: any;
  public codigoPlan;
  public volverHome: any;
  public coberturasHabilitadas = [];

  errors: any[] = [{ error: 'required', message: 'Campo requerido' }];

  constructor(
    private pasarelaService: PasarelaService,
    public onboardingService: OnboardingService,
    private activatedRoute: ActivatedRoute,
    private generalCoreService: GeneralCoreService,
    private router: Router,
    private ipService: IpService,
    private accidentesService: AccidentesService,
    private authService: AuthService
  ) {
    this.activatedRoute.paramMap.subscribe((paramMap: ParamMap) => {
      this.currentPage = paramMap.get('pagina');
    });
  }

  ngOnInit(): void {
    this.formulario = new UntypedFormGroup({});
    this.dataLocalFormulario = new OnBoardingAutos(
      JSON.parse(localStorage.getItem('onboarding'))
    );
    this.formulario.addControl(
      'metodoPago',
      new UntypedFormControl(this.otrosMediosDePago ? '' : 2, [Validators.required])
    );
    this.cargarDatosFormulario();
    // this.validacionRiesgoEstandar();
    this.codigoPlan = this.onboardingService.getCodigoPlanSeleccionado();
  }

  // validacionRiesgoEstandar(){
  //   this.codigoPlan = this.onboardingService.getCodigoPlanSeleccionado()
  //   if(this.codigoPlan !== null){
  //     this.generalCoreService.openSpinner(true);
  //     this.onboardingService.guardarTarifaPorPlan({
  //       tuCotizacionId: this.dataLocalFormulario.idCotizacion,
  //       codigoPlan: this.codigoPlan,
  //       GrupoCoberturas: this.dataLocalFormulario.datosCobertura[this.codigoPlan].data
  //     }).subscribe( resp => {
  //       this.generalCoreService.openSpinner(false);
  //       this.dataLocalFormulario.riesgoEstandar = resp?.data?.esRiesgoEstandar;
  //       localStorage.setItem('onboarding', JSON.stringify({ ...this.dataLocalFormulario}));
  //       this.onboardingService.riesgoEstandar = resp?.data?.esRiesgoEstandar;
  //     });
  //   }
  // }

  private cargarDatosFormulario(): void {
    if (this.dataLocalFormulario.sarlaft !== 'Vigente') {
      this.onboardingService.riesgoEstandar = false;
    }
    this.codigoPlan = this.onboardingService.getCodigoPlanSeleccionado();

    if (typeof this.codigoPlan !== undefined && this.codigoPlan !== null) {
      this.dataValorCotizacion =
        this.dataLocalFormulario.datosTarifa[this.codigoPlan];
      this.planSeleccionado = this.onboardingService.planSeleccionado;
      this.cargarCoberturas(this.codigoPlan);
      this.informacionVehiculo = new InformacionVehiculo(
        this.dataLocalFormulario.datosVehiculo.informacionVehiculo
      );
    }
  }

  cargarCoberturas(codigoPlan: any): void {
    this.coberturas = this.dataLocalFormulario.datosCobertura[codigoPlan]?.data || []
    this.datosTomador = this.dataLocalFormulario.datosTomador;

    let coberturasHabilitadas = [] 
    if (this.coberturas.length) {
      this.coberturas.forEach((grupo) => {
        grupo.coberturas.forEach((cobertura) => {
          cobertura.gruposOpciones.forEach((opcionCobertura) => {
            if (opcionCobertura.cdOpcionSeleccionada ) {
              coberturasHabilitadas.push(grupo)
            }
          });
        });
      })
    }

    this.coberturasHabilitadas = Array.from(new Set(coberturasHabilitadas))
    
  }

  submit(): void {
    if (this.formulario.invalid) {
      this.formulario.markAllAsTouched();
    } else {
      this.guardarSeleccion(this.formulario.getRawValue());
      const data: any = {
        siguienteComponente: 'datosPago',
        datosCobertura: this.coberturas,
      };

      this.pasarelaService
        .realizarPagoCoink({
          tuCotizacionId: this.dataLocalFormulario.idCotizacion,
          proveedorId: this.formulario.controls.metodoPago.value,
          ipAddr: this.ipService.getIpCliente(),
        })
        .subscribe((resp) => {
          localStorage.removeItem('onboarding');
          window.open(resp.data.urlPago, '_self');
        });
      this.eventoGuardar.emit(data);
    }
  }

  guardarSeleccion(dataForm: any): void {
    this.coberturas.forEach((grupo) => {
      grupo.coberturas.forEach((cobertua) => {
        cobertua.gruposOpciones.forEach((opcionCobertura) => {
          for (const key in dataForm) {
            if (key === opcionCobertura.publicId) {
              opcionCobertura.cdOpcionSeleccionada = dataForm[key];
            }
          }
        });
      });
    });
  }

  volver(data: any): void {
    this.eventoVolver.emit({ componente: 'datosPlanes' });
  }

  getRandomArbitrary(min, max): number {
    return Math.random() * (max - min) + min;
  }

  public changeMetodo(pago): void {
    this.idMetodo = pago;
    this.formulario.controls.metodoPago.setValue(pago);
    this.formulario.markAllAsTouched();
  }

  public existeUsuario() {
    this.accidentesService
      .existeUsuario(this.dataLocalFormulario.idCotizacion)
      .subscribe((resp) => {
        if (resp.data) {
          this.generalCoreService.openDialog({
            titulo: 'Usuario registrado',
            mensaje: resp.message,
            tipo: 'warning',
            confirmButtonText: 'Si, vincular',
            cancelButtonText: 'No, cancelar',
            hasCancelButton: true,
            callbackConfirm: () => {
              this.enviarCodigoEmail();
            },
            callbackCancel: () => {
              this.cambiarTokenCliente();
            },
          });
        } else {
          this.cambiarTokenCliente();
        }
      });
  }

  public enviarCodigoEmail() {
    this.accidentesService
      .enviarCodigoEmail(this.dataLocalFormulario.idCotizacion)
      .subscribe((resp) => {
        this.generalCoreService.openDialog({
          componente: 'otp',
          titulo: 'Código de verificación',
          mensaje: `Se ha enviado un código al correo ${this.dataLocalFormulario?.datosTomador?.email}`,
          tipo: 'warning',
          confirmButtonText: 'Verificar código',
          cancelButtonText: 'Cancelar',
          hasCancelButton: true,
          callbackConfirm: (value) => {
            this.cambiarTokenCliente(value.otp);
          },
        });
      });
  }

  public cambiarTokenCliente(codigo = null) {
    const dataSession = this.authService.getSession();
    if (dataSession.roles[0] === 'Onbording') {
      this.authService
        .getTokenCliente({
          tuCotizacionId: this.dataLocalFormulario.idCotizacion,
          codigo: codigo,
        })
        .subscribe((resp) => {
          this.authService.setSession(resp.data);
          this.cotizacion();
        });
    } else {
      this.cotizacion();
    }
  }

  public finalizar(): void {
    const dataSession = this.authService.getSession();
    if (dataSession.roles[0] == 'Onbording' && this.currentPage === 'accidentes') {
      this.existeUsuario();
    } else {
      this.cambiarTokenCliente();
    }
  }

  public cotizacion() {
    this.codigoPlan = this.onboardingService.getCodigoPlanSeleccionado();

    if (this.codigoPlan !== null) {
      this.generalCoreService.openSpinner(true);

      this.onboardingService
        .guardarTarifaPorPlan({
          tuCotizacionId: this.dataLocalFormulario.idCotizacion,
          codigoPlan: this.codigoPlan,
        })
        .subscribe((resp) => {
          this.volverHome = resp.error === false ? true : false;
          this.generalCoreService.openDialog({
            titulo: 'Cotización enviada',
            mensaje: `Hemos enviado el detalle del plan seleccionado al correo <b>${this.dataLocalFormulario?.datosTomador?.email}</b>, en unos minutos uno de nuestros asesores se comunicará contigo para continuar con el proceso.`,
            tipo: 'success',
            hasCancelButton: false,
            callbackConfirm: () => {
              localStorage.removeItem('onboarding');
              // this.router.navigate(['onboarding/autos']);
              // window.location.reload()
            }
          });

          this.generalCoreService.openSpinner(false);
        });
    }
  }

  print() {
    window.print();
  }

  redirectWhatsapp(): void {
    const urlWhatsapp = 'https://api.whatsapp.com/send?phone=573204843918&text=Hola!%20%F0%9F%91%8B%F0%9F%8F%BB%20Seguros%20T%C3%BAPrimero.%20Realice%20una%20cotizaci%C3%B3n%20de%20movilidad%20y%20quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20seguro'
    window.open(urlWhatsapp, '_blank');
  }
}
