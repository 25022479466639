import { Routes } from '@angular/router';
import { BlogDetalleComponent } from './pages/blog-detalle/blog-detalle.component';
import { BlogComponent } from './pages/blog/blog.component';

const blogRoutes: Routes = [
  {
    path: '',
    component: BlogComponent,
  },
  {
    path: ':slug',
    component: BlogDetalleComponent,
  },
];

export default blogRoutes;
