<div class="container">
  <div class="row">
    <div class="col-12 col-md-6 pt-md-5 mt-md-5">
      <h1 class="pt-5 mt-md-5 text-primary fw-bold">{{titulo}}</h1>
      <form action="" [formGroup]="formulario" *ngIf="!validarCodigo">
        <div class="pe-md-5">
          <div class="form-floating col-12 mt-5">
            <input type="email" class="form-control" id="inputEmail4" formControlName="costumerName" placeholder="nombre">
            <label for="inputEmail4" class="form-label">Nombre de usuario *</label>
          </div>
          <app-msg-error 
          [messages]="mensajes['costumerName']"
          [control]="formulario.controls['costumerName']"
          ></app-msg-error>
          <div class="form-floating col-12 mt-4">
            <input type="email" class="form-control" id="inputEmail4" formControlName="email" placeholder="correo">
            <label for="inputEmail4" class="form-label">Correo electrónico *</label>
          </div>
          <app-msg-error 
          [messages]="mensajes['email']"
          [control]="formulario.controls['email']"
          ></app-msg-error>
          <div class="form-floating col-12 mt-4">
            <input type="password" class="form-control" id="inputPassword4" formControlName="contrasena" placeholder="contraseña">
            <label for="inputPassword4" class="form-label">Contraseña *</label>
           
            <div  *ngIf="!formulario.controls['contrasena'].hasError('required')">
              <label class="col" [ngClass]="formulario.controls['contrasena'].hasError('required') || formulario.controls['contrasena'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                <i class="material-icons">{{ formulario.controls['contrasena'].hasError('required') ||
                  formulario.controls['contrasena'].hasError('minlength') ? 'cancel' :
                  'check_circle' }}</i>
                  ¡Debe tener al menos 6 caracteres!
              </label>
              <label class="col" [ngClass]="formulario.controls['contrasena'].hasError('required') || formulario.controls['contrasena'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                <i class="material-icons">{{ formulario.controls['contrasena'].hasError('required') ||
                  formulario.controls['contrasena'].hasError('hasNumber') ? 'cancel' :
                  'check_circle' }}</i>
                  Debe contener al menos 1 número!
              </label>
              <label class="col" [ngClass]="formulario.controls['contrasena'].hasError('required') || formulario.controls['contrasena'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                <i class="material-icons">{{ formulario.controls['contrasena'].hasError('required') ||
                  formulario.controls['contrasena'].hasError('hasCapitalCase') ? 'cancel' :
                  'check_circle' }}</i>
                  ¡Debe contener al menos una Mayúsculas!
              </label>
              <label class="col" [ngClass]="formulario.controls['contrasena'].hasError('required') || formulario.controls['contrasena'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                <i class="material-icons">{{ formulario.controls['contrasena'].hasError('required') ||
                  formulario.controls['contrasena'].hasError('hasSmallCase') ? 'cancel' :
                  'check_circle' }}</i>
                  ¡Debe contener al menos 1 letra en minúsculas!
              </label>
              <label class="col" [ngClass]="formulario.controls['contrasena'].hasError('required') || formulario.controls['contrasena'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                <i class="material-icons">{{ formulario.controls['contrasena'].hasError('required') ||
                  formulario.controls['contrasena'].hasError('hasSpecialCharacters') ? 'cancel' :
                  'check_circle' }}</i>
                  ¡Debe contener al menos 1 carácter especial!
              </label>
            </div>
          </div>
          <app-msg-error 
          [messages]="mensajes['contrasena']"
          [control]="formulario.controls['contrasena']"
          ></app-msg-error>
          <div class="form-floating col-12 mt-4">
            <input type="password" class="form-control" id="inputPassword4" formControlName="confirmarContrasena" placeholder="repetir contrasena">
            <label for="inputPassword4" class="form-label">Repetir contraseña *</label>
          </div>
          <app-msg-error 
          [messages]="mensajes['confirmarContrasena']"
          [control]="formulario.controls['confirmarContrasena']"
          ></app-msg-error>
          <div class="mt-4">
            <div class="custom-control custom-checkbox custom-checkbox-green">
              <input class="form-check-input custom-control-input custom-control-input-green" formControlName="check"
               type="checkbox" id="input-check">
              <label class="custom-control-label" for="input-check"></label>
              <span class="ps-2 t-center"> He leído y acepto la <u class="cursor-pointer" (click)="openModal()">política de tratamiento de datos</u> , así como los términos y condiciones.</span> 
            </div>
          </div>
          <app-msg-error 
          [messages]="mensajes['check']"
          [control]="formulario.controls['check']"
          ></app-msg-error>
          <div class="d-flex align-items-center flex-column my-5">
            <button class="btn btn-primary mb-4 w-100" (click)="submit()">Crear cuenta</button>
            <p class="mb-0">¿Ya tienes una cuenta? </p>
            <a routerLink="/login" class="text-decoration-none">Iniciar sesión</a>
          </div>
        </div>
        
      </form>

      <form action="" [formGroup]="formularioVerificarCodigo" *ngIf="validarCodigo">
        <div class="row " >
          <div class="alert alert-success" role="alert" >
            Hemos enviado el código de verificación al correo electrónico: <b>{{formulario.controls['email'].value}}</b>
          </div>
        </div>
          <div class="row pe-md-5">
            <div class="col-12">
              <label class="form-label">Código verificación</label>
              <input type="number" class="form-control" formControlName="codigo">
            </div>
            <app-msg-error 
            [messages]="mensajes['codigo']"
            [control]="formularioVerificarCodigo.controls['codigo']"
            ></app-msg-error>
            
            <div class="d-flex align-items-center flex-column my-5">
              <button class="btn btn-primary mb-4 w-100" (click)="verificarCodigo()">Verificar</button>
            </div>
          </div>
        </form>
    </div>
    <div class="col-6 d-none d-md-inline-block position-fixed end-0">
      <img src="/assets/images/img-sesion.webp" alt="Seguros 100% digital y simple" class="h-100">
    </div>
  </div>


</div>