import { Routes } from '@angular/router';
import { AuthOnboardingGuard } from '../core/guards/auth-onboarding/auth-onboarding.guard';
import { OnboardingGuard } from '../core/guards/onboarding/onboarding.guard';
import { ResultadoPagoComponent } from './components/resultado-pago/resultado-pago.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';

const onBoardingRoutes: Routes = [
  {
    path: 'finalizado/:idcotizacion',
    component: ResultadoPagoComponent,
  },
  {
    path: 'finalizado',
    component: ResultadoPagoComponent,
  },
  {
    path: ':pagina',
    canActivate: [AuthOnboardingGuard],
    canDeactivate: [OnboardingGuard],
    component: OnboardingComponent,
  },
];

export default onBoardingRoutes;
