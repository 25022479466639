<div class="card h-100">
  <div class="card-header text-center">
    <h4 class="fw-light text-danger mb-0">Plan de viajes</h4>
  </div>
  <div class="card-body text-center">
    <h1 class="text-primary fw-bold mb-0">{{coberturasViajes?.primaTotalImpuestos | currency:'$':'symbol':'1.0-0':'en'}}</h1>
    <span class="text-primary">anual</span>
    <div class=" my-4">
      <button type="button" class="btn btn-danger" (click)="onClickInfoPasajeros()">Seleccionar plan
        <i-feather class="text-white ms-1" name="arrow-right"></i-feather>
      </button>
      <div class="mt-4 mb-5">
        <!-- <i-feather class="text-primary me-2" name="user"></i-feather> -->
        <a class="card-link text-decoration-none cursor-pointer" (click)="onClickPlanViajes()">Modificar el plan</a>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 text-start" *ngFor="let itemCobertura of coberturasViajes?.grupoCoberturas">
        <div class="d-flex">
          <i-feather class="text-success me-2 d-flex" name="check"></i-feather>
          <p class="text-grey-dark fw-bold">{{itemCobertura.dsGrupo}}</p>
        </div>
        <ul>
          <div *ngFor="let itemC of itemCobertura.coberturas">
            <small>{{itemC.dsCobertura}}</small> 
            <ul>
              <li *ngFor="let itemOpciones of itemC.terminos"><small>{{itemOpciones.titulo}} </small> 
                <ng-container *ngFor="let opp of itemOpciones.opciones">
                  <small *ngIf="opp.cdOpcion === itemOpciones.cdOpcionSeleccionada">  ( {{opp.dsOpcion | coberturas}} )</small>
                </ng-container>
              </li>
            </ul>
          </div>
        </ul>
      </div>
    </div>
  </div>
</div>
