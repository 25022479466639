<div [formGroup]="form" class="mb-4">
  <div class="row" >
    <div class="col-md-12">
          <div class="custom-control custom-checkbox custom-checkbox-green">
            <input class="form-check-input custom-control-input custom-control-input-green" formControlName="politicas"
             type="checkbox" id="input-check"
             >
            <label class="custom-control-label" for="input-check"></label>
            <span class="ps-2 t-center"> He leído y acepto la <u class="cursor-pointer" (click)="openModal()">política de tratamiento de datos</u> , así como los términos y condiciones.</span> 
          </div>
    </div>
    <app-msg-error 
        [messages]="errors"
        [control]="form.controls['politicas']"
        ></app-msg-error>
  </div>
</div>
