<div class="card card-blog mb-4 cursor-pointer">
  <div class="position-relative">
    <svg class="card-blog__wave position-absolute" viewBox="0 0 374 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 34H374.001V18.6058C258.447 0.0854151 152.703 -5.48285 32.5315 5.87785C22.0142 6.87213 11.0867 8.32409 0 10.1441V34Z" fill="white"/>
    </svg>    
    <img [src]="detalleCard?.url_img" [alt]="detalleCard?.url_alt" class="card-img-top img-fluid">
  </div>
  <div class="card-body pt-2">
    <span class="badge badge-warning mb-4" [ngClass]="getColorTipo(detalleCard?.blog_tipo)">{{detalleCard?.blog_tipo | titlecase}}</span>
    <h5 class="card-title text-black">{{detalleCard?.titulo}}</h5>
    <p class="fw-light card-blog__descripcion">{{detalleCard?.descripcion}}</p>
    <div>
      <i-feather name="calendar" class="icono me-2 text-light"></i-feather>
      <small class="mb-0 fw-light text-light">{{detalleCard?.fecha}}</small>
    </div>
  </div>
</div>
