import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { GeneralCoreService } from 'src/app/core/services/general-core/general-core.service';
import { RespuestaCobertura } from 'src/app/onboarding/models/cobertura.interface';
import { OnBoardingAutos } from 'src/app/onboarding/models/onboarding.model';
import { OnboardingService } from 'src/app/onboarding/services/onboarding/onboarding.service';
import { AuthService } from 'src/app/sesion/services/auth/auth.service';
import { InformacionPagoComponent } from '../../informacion-pago/informacion-pago.component';
import { InformacionCoberturasComponent } from '../informacion-coberturas/informacion-coberturas.component';
import { InformacionPlanesComponent } from '../../informacion-planes/informacion-planes.component';
import { InformacionTomadorComponent } from '../../informacion-tomador/informacion-tomador.component';
import { InformacionVehiculoComponent } from '../informacion-vehiculo/informacion-vehiculo.component';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-onboarding-autos',
    templateUrl: './onboarding-autos.component.html',
    styleUrls: ['./onboarding-autos.component.scss'],
    standalone: true,
    imports: [
        NgFor,
        NgIf,
        InformacionVehiculoComponent,
        InformacionTomadorComponent,
        InformacionPlanesComponent,
        InformacionCoberturasComponent,
        InformacionPagoComponent,
    ],
})
export class OnboardingAutosComponent implements OnInit {
  onboardingData: OnBoardingAutos;
  pasoActual = '';

  pasos: any = {
    datosVehiculo: true,
    datosTomador: false,
    datosCobertura: false,
    datosPago: false,
    datosFinalizar: false,
  };

  pasosNew: any[] = [
    {
      paso: 'datosVehiculo',
      estado: false,
      visible: true,
      titulo:
        'Para empezar vamos a necesitar la <b class="fw-bold">placa de tu vehículo</b>',
    },
    {
      paso: 'datosTomador',
      estado: false,
      visible: false,
      titulo: 'Ingresa los <b class="fw-bold"> datos del propietario</b>',
      subtitulo:
        'Ten en cuenta que es la persona registrada en la <b class="fw-bold"> tarjeta de propiedad</b>',
    },
    {
      paso: 'datosPlanes',
      estado: false,
      visible: false,
      titulo:
        '<b class="fw-bold">Elige el plan </b> que más se ajuste a tus necesidades',
    },
    {
      paso: 'datosCobertura',
      estado: false,
      visible: false,
      titulo:
        'A medida que seleccioes las coberturas, se irá calculando el valor',
    },
    {
      paso: 'datosPago',
      estado: false,
      visible: false,
      titulo: 'Resumen de tu<b class="fw-bold"> cotización </b>',
    },
    {
      paso: 'datosFinalizar',
      estado: false,
      visible: false,
    },
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private generalCoreService: GeneralCoreService,
    private onboardingService: OnboardingService,
    private authService: AuthService
  ) {
    this.onboardingData = JSON.parse(localStorage.getItem('onboarding'));

    this.validaSiContinuaLaCotizacion(this.onboardingData);
    this.validaParametros();
  }

  private validaSiContinuaLaCotizacion(data) {
    if (this.authService.authUrl.next === '/login') {
      this.authService.authUrl.current = '';
      this.authService.authUrl.next = '';
      const data: any = {
        siguienteComponente: this.authService.esUsuarioOnboarding()
          ? 'datosPlanes'
          : 'datosPago',
      };

      this.guardar(data);
      // /onboarding/autos?paso=datosPago
    } else {
      if (data) {
        this.generalCoreService.openDialog({
          titulo: 'Cotización pendiente',
          mensaje: `Tiene una cotización pendiente, ¿Deseas continuar con la cotización?`,
          tipo: 'warning',
          confirmButtonText: 'Si, continuar',
          cancelButtonText: 'No, volver',
          hasCancelButton: true,
          callbackConfirm: () => {},
          callbackCancel: () => {
            localStorage.removeItem('onboarding');
            location.reload();
          },
        });
      }
    }
  }

  private validaParametros() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.paso) {
        let tempo = 0;
        this.pasosNew.forEach((e) => {
          if (e.paso === params.paso) {
            tempo++;
          }
        });

        if (params.paso === 'datosPago') {
          if (!this.onboardingService.planSeleccionado) {
            this.router.navigate(['/onboarding/autos'], {
              queryParams: { paso: 'datosPlanes' },
            });
          }
        }

        if (tempo === 1) {
          if (this.onboardingData) {
            this.pasosNew.forEach((element) => {
              element.visible = element.paso === params.paso ? true : false;
            });
          } else {
            this.router.navigate(['/onboarding/autos'], {
              queryParams: { paso: 'datosVehiculo' },
            });
          }

          this.pasosNew.forEach((element) => {
            element.visible = element.paso === params.paso ? true : false;
          });
        } else {
          this.router.navigate(['/onboarding/autos'], {
            queryParams: { paso: 'datosVehiculo' },
          });
        }
      } else {
        if (this.onboardingData) {
          this.router.navigate(['/onboarding/autos'], {
            queryParams: { paso: this.onboardingData.siguienteComponente },
          });
        } else {
          this.router.navigate(['/onboarding/autos'], {
            queryParams: { paso: 'datosVehiculo' },
          });
        }
      }
    });
  }

  ngOnInit(): void {}

  private validarPasoActual(paso: string, siguiente: boolean = false): void {
    if (siguiente) {
      this.router.navigate(['/onboarding/autos'], { queryParams: { paso } });
    } else {
      if (!this.onboardingData[paso]) {
        this.router.navigate(['/onboarding/autos'], {
          queryParams: { paso: 'datosVehiculo' },
        });
      }
    }
  }

  guardar(data: any): void {
    this.consultarCoberturasPorPlanes(data);

    if (!this.onboardingData) {
      this.onboardingData = new OnBoardingAutos({});
    } else {
      const dataOnb = JSON.parse(localStorage.getItem('onboarding'));
      this.onboardingData = new OnBoardingAutos(dataOnb);
    }

    this.onboardingData = { ...this.onboardingData, ...data };
    this.onboardingData.idCotizacion = this.onboardingData.idCotizacion;

    localStorage.setItem('onboarding', JSON.stringify(this.onboardingData));
    this.validarPasoActual(data.siguienteComponente, true);
  }

  private consultarCoberturasPorPlanes(data: any): void {
    if (data.datosVehiculo) {
      const requestFork: { [key: string]: Observable<RespuestaCobertura> } = {};
      const responseFork: any = {};
      const dataOnboardingTemp = new OnBoardingAutos(
        JSON.parse(localStorage.getItem('onboarding'))
      );

      if (dataOnboardingTemp.datosCobertura) {
        dataOnboardingTemp.datosCobertura = null;
        dataOnboardingTemp.datosTarifa = null;
        localStorage.setItem(
          'onboarding',
          JSON.stringify({ ...dataOnboardingTemp })
        );
      }
      this.generalCoreService.changeEstadoCoberturas(false);
      data.datosPlanes.forEach((element) => {
        // tslint:disable-next-line: max-line-length
        requestFork[element.codigoPlan] =
          this.onboardingService.obtenerCoberturasPorPlan(
            data.idCotizacion,
            data.datosVehiculo.ciudadCirculacion.id,
            element.codigoPlan
          );
        responseFork[element.codigoPlan] = {};
      });

      forkJoin(requestFork).subscribe((respFork: RespuestaCobertura) => {
        const dataOnboarding = JSON.parse(localStorage.getItem('onboarding'));
        localStorage.setItem(
          'onboarding',
          JSON.stringify({ ...dataOnboarding, ...{ datosCobertura: respFork } })
        );
        this.generalCoreService.changeEstadoCoberturas(true);
      });
    }
  }

  volver(data: any): void {
    this.router.navigate(['/onboarding/autos'], {
      queryParams: { paso: data.componente },
    });
    // this.validarPasoActual(data.componente);
  }

  getPasoVisible() {
    return this.pasosNew.find((paso) => paso.visible);
  }

  validarOnBoarding(): void {
    const dataOnb = new OnBoardingAutos(
      JSON.parse(localStorage.getItem('onboarding'))
    );

    const fechaRegistro = new Date(dataOnb.fecha).getTime();
    const fechaActual = new Date().getTime();

    const diff = (fechaActual - fechaRegistro) / 1000 / 60;

    if (diff > 30) {
      localStorage.clear();
    }
  }
}
