import { CiudadesCirculacion } from './ciudades-circulacion.interfaz';

export class InformacionVehiculo {


    placa: string;
    modelo: string;
    cdClase: string;
    cdMarca: string;
    cdLinea: string;
    cdTipoServicio: string;
    snCeroKm: string;
    valorAsegurado: string;
    valorSugerido: string;
    usoVehiculo: string;
    dsClase: string;
    dsMarca: string;
    dsLinea: string;
    valorAccesorios: string;
    blindado: string;
    tieneGas: string;
    valorAccesoriosEspeciales: string;
    inspeccionValida: string;
    esLineaPermitida: string;
    planes: any[];

    constructor(data: any){
        this.placa = data.placa ?? '';
        this.modelo = data.modelo ?? '';
        this.cdClase = data.cdClase ?? '';
        this.cdMarca = data.cdMarca ?? '';
        this.cdLinea = data.cdLinea ?? '';
        this.cdTipoServicio = data.cdTipoServicio ?? '';
        this.snCeroKm = data.snCeroKm ?? '';
        this.valorAsegurado = data.valorAsegurado ?? '';
        this.valorSugerido = data.valorSugerido ?? '';
        this.usoVehiculo = data.usoVehiculo ?? '';
        this.dsClase = data.dsClase ?? '';
        this.dsMarca = data.dsMarca ?? '';
        this.dsLinea = data.dsLinea ?? '';
        this.valorAccesorios = data.valorAccesorios ?? '';
        this.blindado = data.blindado ?? '';
        this.tieneGas = data.tieneGas ?? '';
        this.valorAccesoriosEspeciales = data.valorAccesoriosEspeciales ?? '';
        this.inspeccionValida = data.inspeccionValida ?? '';
        this.esLineaPermitida = data.esLineaPermitida ?? '';
        this.planes = data.planes ?? [];
    }
}

export class DatosVehiculo {
    public informacionVehiculo: InformacionVehiculo;
    public ciudadCirculacion: CiudadesCirculacion;
}
