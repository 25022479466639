import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Dialog } from '../../models/dialog.model';
import { GeneralCoreService } from '../../services/general-core/general-core.service';
import { AlertModalOtpComponent } from '../alert-modal-otp/alert-modal-otp.component';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';

@Component({
  selector: 'app-general-modal',
  templateUrl: './general-modal.component.html',
  styleUrls: ['./general-modal.component.scss'],
  standalone: true,
})
export class GeneralModalComponent implements OnInit {
  datosDialog: Dialog;

  constructor(
    public dialog: MatDialog,
    private generalCoreService: GeneralCoreService
  ) {}

  ngOnInit(): void {
    this.generalCoreService.dialog$.subscribe((resp) => {
      this.datosDialog = new Dialog(resp);
      this.datosDialog.botones = [
        {
          texto: 'Aceptar',
          clase: 'btn-success',
        },
        {
          texto: 'Cancelar',
          clase: 'btn-danger',
        },
      ];
      this.openDialog(this.datosDialog);
    });
  }

  openDialog(datosDialog: Dialog): void {
    let dialogRef: any;
    if (datosDialog.componente === 'otp') {
      dialogRef = this.dialog.open(AlertModalOtpComponent, {
        disableClose: datosDialog.disableClose,
        data: datosDialog,
        width: '500px',
      });
    } else {
      dialogRef = this.dialog.open(AlertModalComponent, {
        disableClose: datosDialog.disableClose,
        data: datosDialog,
        width: '500px',
      });
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'cancelar' || result.evento === 'cancelar') {
        if (this.datosDialog.callbackCancel) {
          this.datosDialog.callbackCancel();
        }
      } else {
        if (this.datosDialog.callbackConfirm) {
          this.datosDialog.callbackConfirm(result);
        }
      }
    });
  }
}
