<h2 mat-dialog-title class="text-primary">POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES TÚPRIMERO</h2>
<mat-dialog-content>
  <p>
    El presente Aviso de Privacidad (el “Aviso”) prevé los términos en virtud de los cuales SEGUROS TUPRIMERO LTDA., sociedad identificada con el NIT 901.182.242-4, con domicilio en la Calle 117 No. 6 A 60 Oficina 411 de la ciudad de Bogotá (en adelante la “Compañía” o “TÚPRIMERO”), tratará sus datos personales.
  </p>
  <ol>
    <li>
      <p>
        <b>MEDIOS Y FINALIDADES DEL TRATAMIENTO:</b> La Compañía recolectará, almacenará, usará, transmitirá, transferirá y eliminará datos personales de acuerdo con sus gestiones comerciales, contables, financieras, laborales, operativas y en general para el ejercicio de su modelo de negocio, con las siguientes finalidades:
      </p>
      <ol type="i">
        <li>
          <p>
            Negociar y celebrar contratos con la compañía, incluyendo la determinación y análisis de primas y riesgos, y ejecutar los mismos.
          </p>
      </ol>
      <ol type="i" start="2">
        <li>
          <p>
            Determinar y liquidar pagos de siniestros.
          </p>
      </ol>
      <ol type="i" start="3">
        <li>
          <p>
            Tramitar las solicitudes del cliente como consumidor financiero.
          </p>
      </ol>
      <ol type="i" start="4">
        <li>
          <p>
            Ejecutar y cumplir los contratos que celebre la Compañía con entidades en Colombia o en el extranjero para cumplir su actividad aseguradora y los servicios contratados, incluyendo actividades de coaseguro y reaseguro.
          </p>
        <li>
          <p>
            Realizar campañas comerciales y actividades de mercadeo.
          </p>
      </ol>
      <ol type="i" start="6">
        <li>
          <p>
            Compartir y recibir la información estrictamente necesaria con centrales de información, centrales de riesgo y aseguradoras para evitar y mitigar fraudes y realizar análisis de riesgos.
          </p>
      </ol>
      <ol type="i" start="7">
        <li>
          <p>
            Contrastar la información recibida con la que se encuentra en reportes de centrales de riesgo.
          </p>
      </ol>
      <ol type="i" start="8">
        <li>
          <p>
            Para el caso de las pólizas de salud y de seguros de vida, obtener acceso a las historias clínicas de los clientes, con la finalidad exclusiva de celebrar contratos de seguro, suscribir pólizas, definir el alcance del riesgo asegurado y definir concretamente los riesgos que pueden afectar la salud. Estos datos personales serán tratados como datos sensibles, conforme lo señala la legislación vigente. Requeriremos su autorización expresa para darle tratamiento.
          </p>
      </ol>
      <ol type="i" start="9">
        <li>
          <p>
            Tramitar la atención de quejas y reclamos sobre las pólizas contratadas, así como para el cobro de las primas o tarifas acordadas.
          </p>
      </ol>
  </ol>

  <ol start="2">
    <li>
      <p>
        <b>DERECHOS DEL TITULAR DE LA INFORMACIÓN: </b>
        El titular de los datos tiene derecho a acceder gratuitamente a sus datos personales, solicitar que los mismos sean rectificados y/o actualizados de la base de datos de la Compañía; solicitar la prueba de la autorización que otorgó para el manejo de su información; revocar dicha autorización o solicitar la eliminación de los datos personales siempre que la relación que dio origen al tratamiento haya terminado, y mientras no haya una obligación legal o contractual de mantener los datos personales en la base de datos correspondiente; conocer, previa solicitud, el uso que le han dado a sus datos personales los responsables y encargados; reclamar ante la Compañía cuando considere que sus derechos han sido violados, y presentar ante la Superintendencia de Industria y Comercio de Colombia todas las quejas que considere pertinentes, una vez se haya agotado el trámite de reclamo ante la Compañía.
      </p>
  </ol>
  <ol start="3">
    <li>
      <p>
        <b>MECANISMOS PARA CONOCER LA POLÍTICA DE TRATAMIENTO DE LA INFORMACIÓN: </b>
        Puede acceder a nuestra Política de Tratamiento de Datos Personales por medio del siguiente <a href="/politicas">enlace</a>  o comunicándose al correo electrónico <a href="mailto:info@tuprimero.com.co">info&#64;tuprimero.com.co </a>.
      </p>
  </ol>
</mat-dialog-content>
<mat-dialog-actions align="center" class="py-4">
  <button class="btn btn-dark" mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>
