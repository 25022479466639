import { Component, OnInit, Input } from '@angular/core';
import { FeatherModule } from 'angular-feather';

@Component({
    selector: 'app-datos-bici',
    templateUrl: './datos-bici.component.html',
    styleUrls: ['./datos-bici.component.scss'],
    standalone: true,
    imports: [FeatherModule]
})
export class DatosBiciComponent implements OnInit {
  @Input() informacionVehiculo: any;

  constructor() { }

  ngOnInit(): void {
  }

}
