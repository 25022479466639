import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneralHttpService } from 'src/app/shared/services/general-http/general-http.service';


@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private http: GeneralHttpService) { }

  blogItems(): Observable<any>{
    return this.http.get(`api/v1/web/pagina/blog/componente/blog-items/contenido`);
  }

  blogItemsDetalle(slug: string): Observable<any>{
    return this.http.get(`api/v1/web/blog/${slug}/componente/blog-items-detalle/contenido`);
  }
}
