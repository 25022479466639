<div class="mt-4 mb-5 text-center">
  <h3 class="text-primary w-75 fw-light mx-auto title__onboarding mb-0" [innerHTML]="pasoActual.titulo"></h3>
</div>

<div class="row">
  <div class="col-12 col-lg-7">
    <app-plan-viajes (eventoPlanViajes)="eventoPlanViajes()" (eventoInfoPasajeros)="eventoInfoPasajeros()" [coberturasViajes]="coberturasViajes" [dataViaje]="dataViaje"  *ngIf="pasoActual.paso == 'planViajes' && coberturasViajes"></app-plan-viajes>
    <div [formGroup]="pasajerosForm" *ngIf="pasoActual.paso == 'informacionPasajeros' && coberturasViajes">
      <div formArrayName="pasajeros">
        <ng-container *ngFor="let pasajero of pasajeros.controls; let i=index" [formGroupName]="i">
          <app-informacion-pasajeros [listaFormulario]="listaFormulario" [formulario]="pasajero" [index]="i"></app-informacion-pasajeros>
        </ng-container>
      </div>
      <app-botones-onboarding (eventoVolver)="cambiarPasoActual()" [activarBotonContinuar]="false"></app-botones-onboarding>
    </div>
    <app-informacion-plan-personalizado [coberturasViajes]="coberturasViajes.grupoCoberturas" *ngIf="pasoActual.paso == 'modificarPlan' && coberturasViajes" (eventoVolver)="cambiarPasoActual()" (eventoCambiarValor)="eventoCambiarValor($event)"></app-informacion-plan-personalizado>
    <ng-container *ngIf="pasoActual.paso == 'resumenPlan' && coberturasViajes">
      <app-detalle-plan-viaje *ngFor="let item of resumenPasajeros; let i=index" [pasajero]="item" [index]="i"></app-detalle-plan-viaje>
      <div class="my-4 ms-2">
        <h5 class="mb-4 text-dark">Selecciona el medio de pago</h5>
        <div class="form-group">
          <div class="text-center mb-4">
            <ul class="nav nav-pills radio-tabs mb-4">
              <li class="nav-item me-md-4 mb-3 mb-md-0">
                <button type="button" class="nav-link py-4" [ngClass]="{active: idMetodo === 1 }" (click)="changeMetodo(1)">
                  <img src="/assets/images/logos/sura.webp" alt="Logo de Sura" height="40">
                </button>
              </li>
            </ul>
            <!-- <app-msg-error 
                    [messages]="errors"
                    [control]="formulario.controls.metodoPago"
                  ></app-msg-error> -->
          </div>
        </div>
        <app-botones-onboarding class="botones-onboarding" (eventoVolver)="eventoInfoPasajeros()" (eventoSiguiente)="finalizar()" labelBtnContinuar="Ir a pagar"></app-botones-onboarding>
      </div>
    </ng-container>
  </div>
  <div class="col-12 col-lg-5">
    <app-card-resumen-compra [mostrarDetallePlan]="mostrarDetallePlan" [mostrarTotalPlan]="mostrarTotalPlan" [mostrarBotonSiguiente]="pasoActual === this.pasosNew[1] || pasoActual === this.pasosNew[2]" (eventoSiguiente)="eventoSiguiente()" [valorTarifa]="valorTarifa"></app-card-resumen-compra>
  </div>
</div>

