<div class="container content px-4">
    <!-- <app-stepper [step]="onboardingService.onbording.pasoActual"></app-stepper> -->
    <app-onboarding-autos *ngIf="currentPage === 'autos'"></app-onboarding-autos>
    <app-onboarding-bici *ngIf="currentPage === 'bicicleta'"></app-onboarding-bici>
    <app-onboarding-moto *ngIf="currentPage === 'motos'"></app-onboarding-moto>
    <app-onboarding-soat *ngIf="currentPage === 'soat'"></app-onboarding-soat>
    <app-onboarding-accidentes *ngIf="currentPage === 'accidentes'"></app-onboarding-accidentes>
    <app-onboarding-viajes *ngIf="currentPage === 'viajes'"></app-onboarding-viajes>
</div>

<!-- Botón de WhatsApp -->
<a href="https://api.whatsapp.com/send?phone=573204843918&text=Hola!%20%F0%9F%91%8B%F0%9F%8F%BB%20Seguros%20T%C3%BAPrimero.%20Estuve%20navegando%20por%20su%20p%C3%A1gina%20web%20y%20estoy%20interesado%20en%20uno%20de%20sus%20productos,%20quisiera%20tener%20m%C3%A1s%20informaci%C3%B3n"
  target="_blank" class="whatsapp-onboarding" title="WhatsApp" rel="noopener">
  <i class="fa fa-whatsapp"></i>
</a>
