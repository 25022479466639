import { NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FeatherModule } from 'angular-feather';
import { NgxMaskDirective } from 'ngx-mask';
import { MsgErrorComponent } from '../../../shared/components/msg-error/msg-error.component';
import { Dialog } from '../../models/dialog.model';

@Component({
  selector: 'app-alert-modal-otp',
  templateUrl: './alert-modal-otp.component.html',
  styleUrls: ['./alert-modal-otp.component.scss'],
  standalone: true,
  imports: [
    FeatherModule,
    ReactiveFormsModule,
    MsgErrorComponent,
    NgIf,
    NgxMaskDirective,
  ],
})
export class AlertModalOtpComponent implements OnInit {
  // public otp = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)])
  public form = new UntypedFormGroup({
    otp: new UntypedFormControl('', [
      Validators.required,
      Validators.min(100000),
      Validators.max(999999),
    ]),
  });
  public errorsOtp = [
    { error: 'required', message: 'Campo requerido' },
    { error: 'min', message: 'Debe tener al menos 6 caracteres' },
    { error: 'max', message: 'Debe tener  máximo 6 caracteres' },
  ];

  botones: any[] = [];
  configModal: any = {
    background: '#ffebb0',
    color: 'text-warning',
    icon: 'alert-triangle',
    btn: 'btn-warning',
  };

  constructor(
    public dialogRef: MatDialogRef<AlertModalOtpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Dialog
  ) {}

  ngOnInit(): void {
    this.botones = this.data.botones;

    if (this.botones?.length === 0) {
      this.botones.push({
        texto: 'Aceptar',
        clase: 'btn-danger',
      });
    }
  }

  eventoBoton(evento: any): void {
    this.dialogRef.close({ evento, otp: this.form.controls.otp.value });
  }
}
