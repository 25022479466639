<div class="card" [class.active]="active">
  <div class="card-body d-flex justify-content-between align-items-center py-3 px-4" (click)="selectCard()">
    <div class="d-flex align-items-center">
      <img class="me-3 card__image" [src]="urlImage" alt="Cobertura de seguros" height="60">
      <h3 class="fw-normal fs-5 mb-0 text-grey-dark" [innerHTML]="title"></h3>
    </div>
    <div (click)="verDetalle()">
      <i-feather class="card__icon" name="info" ></i-feather>
    </div>
  </div>
</div>