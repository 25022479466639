import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FeatherModule } from 'angular-feather';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-botones-onboarding',
    templateUrl: './botones-onboarding.component.html',
    styleUrls: ['./botones-onboarding.component.scss'],
    standalone: true,
    imports: [NgIf, FeatherModule]
})
export class BotonesOnboardingComponent implements OnInit {

  @Input() labelBtnAtras = 'Volver';
  @Input() labelBtnContinuar = 'Continuar';
  @Input() activarBotonVolver = true;
  @Input() activarBotonContinuar = true;
  @Input() desactivarBoton = false;
  @Output() eventoVolver: EventEmitter<boolean> = new EventEmitter();
  @Output() eventoSiguiente: EventEmitter<boolean> = new EventEmitter();


  constructor() { }

  ngOnInit(): void {
  }

  volver(): void{
    this.eventoVolver.emit(true);
  }
  siguiente(): void{
    this.eventoSiguiente.emit(true);
  }

}
