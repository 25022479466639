<div class="card card-blog-main mb-4 cursor-pointer">
  <div class="row g-0">
    <div class="position-relative col-md-7">
      <svg class="card-blog-main__wave-mobile position-absolute d-md-none" viewBox="0 0 374 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 34H374.001V18.6058C258.447 0.0854151 152.703 -5.48285 32.5315 5.87785C22.0142 6.87213 11.0867 8.32409 0 10.1441V34Z" fill="white"/>
      </svg>   
      <svg class="card-blog-main__wave d-none d-md-inline-block position-absolute h-100" viewBox="0 0 34 374" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M34 374.001L34 0.000120584L18.6058 0.000121257C0.0854035 115.555 -5.48286 221.298 5.87785 341.47C6.87213 351.987 8.32409 362.915 10.1441 374.001L34 374.001Z" fill="white"/>
      </svg>
      <img [src]="detalleCard?.url_img" [alt]="detalleCard?.url_alt" class="card-img-top card-img-bottom img-fluid">
    </div>
    <div class="col-md-5">
      <div class="card-body pt-0 pt-md-4 mt-md-3 pe-5">
        <span class="badge badge-warning mb-4" [ngClass]="getColorTipo(detalleCard?.blog_tipo)">{{detalleCard?.blog_tipo | titlecase}}</span>
        <h3 class="card-title text-black">{{detalleCard?.titulo}}</h3>
        <p class="card-blog-main__descripcion fw-light">{{detalleCard?.descripcion}}</p>
        <div>
          <i-feather name="calendar" class="icono me-2 text-light"></i-feather>
          <small class="mb-0 fw-light text-light">{{detalleCard?.fecha}}</small>
        </div>
      </div>
    </div>
  </div>
</div>

