import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AuthService } from 'src/app/sesion/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { GeneralCoreService } from '../../services/general-core/general-core.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const generalCoreService = inject(GeneralCoreService);
  const router = inject(Router);

  const token: string = authService.getToken();

  if (token) {
    req = req.clone({
      setHeaders: {
        authorization: `Bearer ${token}`,
      },
    });
  }

  return next(req).pipe(
    tap(
      () => {},
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          const urlExistInWhitlist = existInWhitelist(req.url);
          if (
            err.status >= 400 &&
            err.status < 600 &&
            err.status !== 401 &&
            !urlExistInWhitlist
          ) {
            let dataMsg = '';

            if (err.error.data && err.error.data.length > 0) {
              err.error.data.forEach((element) => {
                dataMsg += '<br>' + element;
              });
            }

            generalCoreService.openDialog({
              mensaje: `${err.error.message} ${dataMsg}`,
            });
          }

          if (err.status !== 401) {
            return;
          } else {
            authService.setSession(null);
            localStorage.removeItem('session');
            router.navigate(['login']);
          }
        }
      }
    )
  );
};

const existInWhitelist = (url: string): boolean => {
  const errorWhiteList = environment.errorWhiteList;
  return errorWhiteList.some((item) => url.includes(item));
};
