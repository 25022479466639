export class InformacionSoat {

    tuCotizacionId: number;
    placa: string;
    modelo: number;
    idClaseVehiculo: number;
    claseVehiculo: string;
    marca: string;
    linea: string;
    idMarca: number;
    idLinea: number;
    tipoServicio: string;
    idTipoServicio: number;
    cilindrajeOPotencia: number;
    numeroMotor: string;
    tipoCarroceria: string;
    tipoCombustible: string;
    fechaInicioVigencia: string;
    tarifa: any = {};
    descuentos: any[];
    datosErradosRunt: any[];
    datosErradosRuntNoNecesariosCotizacion: any[];

    constructor(data: any){
        this.tuCotizacionId = data.tuCotizacionId ?? '';
        this.placa = data.vehiculo?.placa ?? '';
        this.modelo = data.vehiculo?.modelo ?? '';
        this.claseVehiculo = data.vehiculo?.claseVehiculo ?? '';
        this.marca = data.vehiculo?.marca ?? '';
        this.linea = data.vehiculo?.linea ?? '';
        this.idMarca = data.vehiculo?.idMarca ?? '';
        this.idLinea = data.vehiculo?.idLinea ?? '';
        this.tipoServicio = data.vehiculo?.tipoServicio ?? '';
        this.idTipoServicio = data.vehiculo?.idTipoServicio ?? '';
        this.cilindrajeOPotencia = data.vehiculo?.cilindrajeOPotencia ?? '';
        this.numeroMotor = data.vehiculo?.numeroMotor ?? '';
        this.tipoCarroceria = data.vehiculo?.tipoCarroceria ?? '';
        this.tipoCombustible = data.vehiculo?.tipoCombustible ?? '';
        this.fechaInicioVigencia = data.fechaInicioVigencia ?? '';
        this.tarifa = data.vehiculo?.tarifa ? data.vehiculo?.tarifa : data.tarifa;
        this.descuentos = data.vehiculo?.descuentos ? data.vehiculo?.descuentos : data.descuentos;
        this.datosErradosRunt = data.datosErradosRunt ?? [];
        this.datosErradosRuntNoNecesariosCotizacion = data.datosErradosRuntNoNecesariosCotizacion ? data.datosErradosRuntNoNecesariosCotizacion : [];
    }
}
