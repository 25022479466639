import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';

import { AccidentesService } from 'src/app/onboarding/services/accidentes/accidentes.service';

import { InformacionTomadorAccidentesForm } from './informacion-tomador-accidentes.form';
import { MsgErrorComponent } from '../../../../shared/components/msg-error/msg-error.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CalendarModule } from 'primeng/calendar';
import { FeatherModule } from 'angular-feather';
import { NgIf, NgFor, LowerCasePipe, TitleCasePipe } from '@angular/common';
@Component({
    selector: 'app-informacion-tomador-accidentes',
    templateUrl: './informacion-tomador-accidentes.component.html',
    styleUrls: ['./informacion-tomador-accidentes.component.scss'],
    standalone: true,
    imports: [NgIf, FeatherModule, ReactiveFormsModule, NgFor, CalendarModule, NgSelectModule, MsgErrorComponent, LowerCasePipe, TitleCasePipe]
})
export class InformacionTomadorAccidentesComponent implements OnInit {
  @Output() cerrarFormulario: EventEmitter<any> = new EventEmitter();
  @Input() formulario: UntypedFormGroup;
  @Input() listaFormulario: any[] = [];
  @Input() titulo: string = '';
  @Input() tieneBotonCerrar: boolean = false;

  maxDate: Date;
  yearRange: string;

  constructor(private accidentesService: AccidentesService) { 
    this.maxDate = new Date();
    // this.listaFormulario = InformacionTomadorAccidentesForm.getForm();
  }

  ngOnInit(): void {
    this.obtenerTipoDocumento();
    this.obtenerDepartamentos();
    const minYear = new Date().getFullYear() - 70;
    const maxYear = new Date().getFullYear();
    this.yearRange = minYear + ':' + maxYear;
  }

  public obtenerTipoDocumento() {
    this.accidentesService.obtenerTipoDocumento().subscribe( (resp: any) => {
      this.listaFormulario.forEach( el => {
        if (el.formControlName === 'tipoDocumento') {
            el.opciones = resp.data;
        }
      });
    });
  }

  public obtenerDepartamentos() {
    this.accidentesService.obtenerDepartamentos().subscribe( (resp: any) => {
      this.listaFormulario.forEach( el => {
        if (el.formControlName === 'codDepartamento') {
            el.opciones = resp.data;
        }
      });
    });
  }

  public changeSelect(value) {
    if (value.codDaneDepartamento) {
      this.obtenerCiudades(value.codDaneDepartamento)
      this.formulario.controls.codMunicipio.enable()
    }
  }

  public obtenerCiudades(id): void{
    this.accidentesService.obtenerMunicipios(id).subscribe( (resp: any) => {
      this.listaFormulario.forEach( el => {
        if (el.formControlName === 'codMunicipio') {
          el.opciones = resp.data;
        }
      });
    });
  }

  public enviarDatos() {

  }

  public eventoCerrar() {
    this.cerrarFormulario.emit()
  }

}
