import {Component, Input, OnInit} from '@angular/core';
import { NavMobileComponent } from './nav-mobile/nav-mobile.component';
import { NavDesktopComponent } from './nav-desktop/nav-desktop.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [NavDesktopComponent, NavMobileComponent]
})
export class HeaderComponent implements OnInit {
  @Input() isStaticColor = false;
  @Input() isLinkBlue = false;

  constructor() { }

  ngOnInit(): void {
  }
}
