import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from 'rxjs/operators';
import { GeneralHttpService } from 'src/app/shared/services/general-http/general-http.service';
import { environment } from 'src/environments/environment';
import { RespuestaCobertura } from '../../models/cobertura.interface';

const DATA_TEST = environment.informacionPrueba;

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  planSeleccionado = null;
  riesgoEstandar =  false;

  constructor(private http: GeneralHttpService){}

  consultarPlacaVehiculo(placa: string): Observable<any>{
    const codigoPartner = localStorage.getItem('partner');

    if (DATA_TEST){
      return this.http.json(`assets/json/consultarPlacaVehiculo.json`);
    }else{
      if (codigoPartner){
        return this.http.get(`api/v1/Sura/Movilidad/Cotizacion/Autos/${placa}?codigoPartner=${codigoPartner}`);
      }else {
        return this.http.get(`api/v1/Sura/Movilidad/Cotizacion/Autos/${placa}`);
      }

    }
  }

  consultarPlacaMotos(placa: string): Observable<any>{
    const codigoPartner = localStorage.getItem('partner');

    if (DATA_TEST){
      return this.http.json(`assets/json/consultarPlacaVehiculo.json`);
    }else{
      if (codigoPartner){
        return this.http.get(`api/v1/Sura/Movilidad/Cotizacion/Motos/${placa}?codigoPartner=${codigoPartner}`);
      }else {
        return this.http.get(`api/v1/Sura/Movilidad/Cotizacion/Motos/${placa}`);
      }

    }
  }


  obtenerCoberturas = (idCotizacion: number, codCiudadCirculacion: string, codigoZona: string) =>
    this.http.get(`api/v1/Sura/Movilidad/Cotizacion/${idCotizacion}/${codCiudadCirculacion}/${codigoZona}`)
    .pipe( map( (ev: any) => ev.data ))

  cotizacionTomador(data: any): Observable<any>{
    if (DATA_TEST){
      return this.http.json(`assets/json/tomador.json`);
    }else{
      return this.http.post(`api/v1/Sura/Movilidad/Cotizacion/tomador`, data);
    }
  }

  obtenerCoberturasPorPlan(idCotizacion: string, idCiudadCirculacion: string, idPlan: string): Observable<RespuestaCobertura>{
    if (DATA_TEST){
      return this.http.json(`assets/json/coberturas-${idPlan}.json`);
    }else{
      return this.http.get(`api/v1/Sura/Movilidad/Cotizacion/Coberturas/${idCotizacion}/${idCiudadCirculacion}/${idPlan}`)
      .pipe(
        map((res) => res as RespuestaCobertura), 
        catchError(e => of(null))
      );
    }
  }

  cotizacionTarifa(data: any): Observable<any>{
    if (DATA_TEST){
      return this.http.json(`assets/json/tarifa-${data.codigoPlan}.json`).pipe(map( m => m.data));
    }else{
      return this.http.post<any>(`api/v1/Sura/Movilidad/Cotizacion/Tarifa`, data).pipe(map( m => m?.data))
                  .pipe(
                    map((res) => res), 
                    catchError(e => of(null))
                  );
    }
  }

  validarEstadoPago(data: any): Observable<any>{
    return this.http.post(`api/v1/PasarelasPagos/Status`, data);
  }

  // autoGuardado(data): Observable<any> {
  //   const informacion = {
  //     datos: JSON.stringify(data)
  //   };
  //   return this.http.post(`api/v1/AutoGuardado`, informacion);
  // }
 
  guardarTarifaPorPlan(data: any): Observable<any>{
    return this.http.post(`api/v1/Sura/Movilidad/Cotizacion/Coberturas`, data);
  }

  crearCotizacionBici(data: any): Observable<any>{
    return this.http.post(`api/v1/Sura/Movilidad/Cotizacion/Bici`, data);
  }
  
  obtenerMarcas(date: string = '01-01-2021'): Observable<any>{
    return this.http.get<any>(`api/v1/Sura/Movilidad/Bicicletas/Marcas/${date}`).pipe(map( (m: any) => m.data))
  }

  getCodigoPlanSeleccionado(){
    return typeof this.planSeleccionado?.codigo !== undefined ? this.planSeleccionado?.codigo : null;
  };
}


