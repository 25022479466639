import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneralHttpService } from 'src/app/shared/services/general-http/general-http.service';

@Injectable({
  providedIn: 'root',
})
export class PasarelaService {
  constructor(private http: GeneralHttpService) {}

  realizarPagoCoink(data: any): Observable<any> {
    return this.http.post(`api/v1/pasarelaspagos/sura`, data);
  }

  realizarPagoSura(data: any): Observable<any> {
    return this.http.post(`api/v1/pasarelaspagos/sura`, data);
    // return this.http.json(`assets/json/pago-viaje.json`);
  }
}
