<div class="blog-detalle" *ngFor="let seccion of dataBlog ; let i = index">
  <div class="position-relative" *ngIf="i === 0">
    <svg class="blog-detalle__wave position-absolute" viewBox="0 0 1440 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1441 40.4948C1054.66 0.185882 701.114 -11.9332 299.335 12.793C204.062 18.6563 98.7231 31.8321 0 48.4401V62.1525H1441V40.4948Z" fill="#F8FAFC"/>
    </svg>
    <img [src]="seccion.url_img" [alt]="seccion.alt_img" class="img-fluid blog-detalle__banner">
  </div>
  <div class="container col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
    <div class="text-center mt-4" *ngIf="i === 0">
      <span class="badge badge-warning" [ngClass]="getColorTipo(categoria)">{{categoria | titlecase}}</span>
      <h1 class="text-grey-dark mt-4 mb-5 px-4">{{seccion.titulo}}</h1>
    </div>
    <h2 class="mt-5 mb-4 text-grey-dark" *ngIf="i != 0">{{seccion.titulo}}</h2>
    <img [src]="seccion.url_img" [alt]="seccion.alt_img" class="img-fluid rounded-3 mb-4" *ngIf="i != 0">
    <p [innerHTML]="seccion.descripcion"></p>
  </div>
</div>
<div class="text-center my-5">
  <button class="btn btn-primary" [routerLink]="['/blog']"  >
    <i-feather name="arrow-left" class="me-2 fw-light"></i-feather>
     Volver
  </button>
</div>