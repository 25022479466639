import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'coberturas',
    standalone: true
})
export class CoberturasPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {

    if (this.contieneSoloLetras(value)) { return value; }

    if (this.contieneNumerosYPuntos(value)) { return `$ ${value}`; }

    return  this.contieneSignos(value);
  }

  private contieneSignos(valor: string): string {
    if (valor.includes('/')){
      const temp =  valor.split('/');

      if (this.contieneNumerosYPuntos(temp[0])){ return `$ ${temp[0]} / ${temp[1]}`; }
    }
    return valor;
  }

  private contieneNumerosYPuntos(valor): boolean{
    const regex = /^\d+(\.\d+)*$/;
    const validcion = regex.test(valor);
    return validcion;
  }

  private contieneSoloLetras(valor): boolean{
    const regex = /^[a-zA-Z\s]*$/;
    const validcion = regex.test(valor);
    return validcion;
  }



}
