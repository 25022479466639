import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OnBoardingAutos } from 'src/app/onboarding/models/onboarding.model';
import { OnboardingService } from 'src/app/onboarding/services/onboarding/onboarding.service';
import { GeneralCoreService } from 'src/app/core/services/general-core/general-core.service';
import { AuthService } from 'src/app/sesion/services/auth/auth.service';
import { Router } from '@angular/router';
import { FeatherModule } from 'angular-feather';
import { NgIf, NgClass, NgFor, CurrencyPipe } from '@angular/common';

@Component({
    selector: 'app-card-planes',
    templateUrl: './card-planes.component.html',
    styleUrls: ['./card-planes.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        FeatherModule,
        NgFor,
        CurrencyPipe,
    ],
})
export class CardPlanesComponent implements OnInit {
  @Input() plan: any;
  @Output() eventoPlanPeronalizado = new EventEmitter<any>();
  @Output() eventoPagar: EventEmitter<any> = new EventEmitter();
  public dataLocalFormulario: OnBoardingAutos;

  constructor(
    private onboardingService: OnboardingService,
    private authService: AuthService,
    private router: Router,
    private generalCoreService: GeneralCoreService
  ) {}

  ngOnInit(): void {
    this.dataLocalFormulario = new OnBoardingAutos(
      JSON.parse(localStorage.getItem('onboarding'))
    );
  }

  getColors(id, type): any {
    switch (id) {
      case '1':
        return { [`${type}-danger`]: true };
      case '2':
        return { [`${type}-success`]: true };
      case '3':
        return { [`${type}-primary`]: true };
      case '54':
        return { [`${type}-danger`]: true };
      case '52':
        return { [`${type}-success`]: true };
      case '8':
        return { [`${type}-danger`]: true };
      default:
        break;
    }
  }

  sendPlan(plan): void {
    this.eventoPlanPeronalizado.emit(plan);
  }

  redirect(plan): void {
    this.eventoPagar.emit(plan);
    // }
  }
}
