import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlogService } from '../../services/blog/blog.service';
import { NoticiasService } from '../../services/noticias/noticias.service';
import { CardBlogComponent } from '../../components/card-blog/card-blog.component';
import { CardBlogMainComponent } from '../../components/card-blog-main/card-blog-main.component';
import { NgClass, NgFor, SlicePipe } from '@angular/common';
@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss'],
    standalone: true,
    imports: [NgClass, CardBlogMainComponent, NgFor, CardBlogComponent, SlicePipe]
})
export class BlogComponent implements OnInit {
  
  public listBlog = [];
  public idTipoContenido = 1;

  constructor(private router: Router,
    private blogService: BlogService,
    private noticiasService: NoticiasService) { }

  ngOnInit(): void {
    // this.blogService.blogItems().subscribe(resp => {
    //   this.listBlog = resp.data.reverse()
    // })
    this.changeTipoContenido(1)
  }

  redirectDetalleBlog(blog) {
    this.router.navigate(['blog/'+blog.slug], { queryParams: {categoria: blog.blog_tipo, tipo: this.idTipoContenido}})
  }

  changeTipoContenido(id){
    this.idTipoContenido = id;
    if (this.idTipoContenido === 1) {
      this.blogService.blogItems().subscribe(resp => {
        this.listBlog = resp.data.sort((a, b) => Number(b.id) -  Number(a.id))
      })
    } else {
      this.noticiasService.noticiasItems().subscribe(resp => {
        this.listBlog = resp.data.sort((a, b) => Number(b.id) -  Number(a.id))
      })
    }
  }

}
