import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { NgIf, NgFor, CurrencyPipe, DatePipe } from '@angular/common';
import { FeatherModule } from 'angular-feather';

@Component({
    selector: 'app-card-resumen-compra',
    templateUrl: './card-resumen-compra.component.html',
    styleUrls: ['./card-resumen-compra.component.scss'],
    standalone: true,
    imports: [
        FeatherModule,
        NgIf,
        MatDialogModule,
        NgFor,
        CurrencyPipe,
        DatePipe,
    ],
})
export class CardResumenCompraComponent implements OnInit {
  @Input() mostrarDetallePlan = false;
  @Input() mostrarTotalPlan = false;
  @Input() mostrarBotonSiguiente = false;
  @Input() valorTarifa = '';
  @Output() eventoSiguiente = new EventEmitter();
  @ViewChild('coberturas', { static: true }) coberturas: TemplateRef<any>;
  public dataViaje: any;
  public coberturasViajes: any;

  constructor(public dialog: MatDialog) {
    this.dataViaje = localStorage.getItem('onboardingViajes')
      ? JSON.parse(localStorage.getItem('onboardingViajes'))?.dataViaje
      : {};
    this.coberturasViajes = localStorage.getItem('onboardingViajes')
      ? JSON.parse(localStorage.getItem('onboardingViajes')).coberturas
      : {};
  }

  ngOnInit(): void {}

  public siguiente() {
    this.eventoSiguiente.emit();
  }

  public mostrarCoberturas() {
    const dialogRef = this.dialog.open(this.coberturas, {
      width: '100%',
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
}
