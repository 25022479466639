import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { CustomValidators } from '../register/custom-validators';
import { GeneralCoreService } from 'src/app/core/services/general-core/general-core.service';
import { patronValidacion } from 'src/app/onboarding/enums/validaciones';
import { MsgErrorComponent } from '../../../shared/components/msg-error/msg-error.component';
import { NgIf, NgClass } from '@angular/common';


@Component({
    selector: 'app-cambio-contrasena',
    templateUrl: './cambio-contrasena.component.html',
    styleUrls: ['./cambio-contrasena.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, NgIf, MsgErrorComponent, NgClass, RouterLink]
})
export class CambioContrasenaComponent implements OnInit {

  formulario: UntypedFormGroup;
  mensajes = {};
  token: string;
  estadoSolicituContrasena = false;
  authEmail = '';
  msgError = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private generalCoreService: GeneralCoreService
  ) {
  }

  ngOnInit(): void {
    this.inicializarFormulario();
    this.authEmail = this.authService.authEmail;

    this.formulario.controls.password.valueChanges.subscribe( () => {
      this.validaValoresContrasena();
    });
    this.formulario.controls.confirmPassword.valueChanges.subscribe( () => {
      this.validaValoresContrasena();
    });
  }

  private validaValoresContrasena(): void {
    const password = this.formulario.controls.password.value;
    const confirmPassword = this.formulario.controls.confirmPassword.value;

    if (password !== '' && confirmPassword !== '') {
      if (password !== confirmPassword) {
        this.formulario.controls.confirmPassword.setErrors({ NoPassswordMatch: true });
      }
    }
  }

  private inicializarFormulario(): void {
    this.formulario = new UntypedFormGroup({
      email: new UntypedFormControl(this.authService.authEmail),
      password: new UntypedFormControl('', 
        [
          Validators.required,
          CustomValidators.patternValidator(/\d/, { hasNumber: true }),
          // check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true}),
          // check whether the entered password has a lower case letter
          CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
          // check whether the entered password has a special character
          CustomValidators.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true
            }
          ),
          Validators.minLength(6)
        ]
      ),
      confirmPassword: new UntypedFormControl('', [ Validators.required,]),
      codigo: new UntypedFormControl('', 
        [
          Validators.required, 
          Validators.pattern(patronValidacion.soloNumeros)
        ])
    });

    this.mensajes = {
      password: [
        { error: 'required', message: 'Campo requerido' }
      ],
      confirmPassword: [
        { error: 'required', message: 'Campo requerido' },
        { error: 'NoPassswordMatch', message: 'Contraseña no coincide' }
      ],
      codigo: [
        { error: 'required', message: 'Campo requerido' },
        { error: 'pattern', message: 'Formato incorrecto' },
        { error: 'max', message: 'El número máximo de caracteres es 6' }
      ]
    };
  }

  submit(): void{
    
   if (this.formulario.invalid){
      this.formulario.markAllAsTouched();
    }else{
     this.authService.restaurarContrasena(this.formulario.getRawValue()).subscribe( resp => {
      this.generalCoreService.openDialog({
        titulo: 'Contraseña modificada',
        mensaje: 'La contraseña se modificó correctamente',
        tipo: 'success',
        callbackConfirm: () => {
          this.router.navigate(['login']);
        },
        hasCancelButton: false
      });
      this.formulario.reset();
     }, err => {
      this.msgError = 'Se produjo un error al modificar la contraseña.';
     });
    }
  }

}
