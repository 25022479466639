<div class="header__onboarding bg-white d-flex justify-content-center align-items-center w-100 position-fixed">
  <div class="px-4">
    <i-feather class="header__onboarding__icon text-light mb-2 end-0 cursor-pointer position-absolute" name="x" [routerLink]="'/'"></i-feather>
    <div class="header__onboarding__logo" [routerLink]="'/'">
      <div class="logo">
        <img src="assets/images/logo.webp" alt="logo TuPrimero" height="45" width="50">
      </div>
    </div>
    <div class="ms-3 header__onboarding__user position-absolute" id="avatar" *ngIf="session" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Ir a perfil">
      <img src="assets/images/user.webp" [routerLink]="['/perfil']" width="40" height="40" class="rounded-circle cursor-pointer">
    </div>
  </div>
</div>
<router-outlet></router-outlet>