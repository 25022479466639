import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OnboardingService } from '../../services/onboarding/onboarding.service';
import { GeneralCoreService } from 'src/app/core/services/general-core/general-core.service';
import { ResultadoGenericoComponent } from './resultado-generico/resultado-generico.component';

@Component({
    selector: 'app-resultado-pago',
    templateUrl: './resultado-pago.component.html',
    styleUrls: ['./resultado-pago.component.scss'],
    standalone: true,
    imports: [ResultadoGenericoComponent]
})
export class ResultadoPagoComponent implements OnInit {

  requestId: string;
  idCotizacion: string;
  exitoso = false;
  pendiente = false;
  fallido = false;
  listExitoso: string[] = ['PagoAprobado'];
  listPendiente: string[] = ['PagoPendiente', 'Pagada'];
  listFallido: string[] = ['PagoExpirado', 'PagoCancelado'];
  dataSession = JSON.parse(localStorage.getItem('session'));

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private onboardingService: OnboardingService,
  ) {
    let tempCotizacion = this.router.url.split('/onboarding/finalizado/')
    this.idCotizacion = tempCotizacion[1] ? tempCotizacion[1] : null;

    this.activatedRoute.queryParams.subscribe(resp => {
      if (resp.request_id) {
        this.requestId = resp.request_id;
      }
    });
  }

  ngOnInit(): void {
    if (this.idCotizacion) {
      if (this.dataSession) {
        this.onboardingService.validarEstadoPago({
          tuCotizacionId: this.idCotizacion.split('?')[0]
        }).subscribe(resp => {
          if (this.listExitoso.includes(resp.data.estado)) {
            this.exitoso = true
          }
          if (this.listPendiente.includes(resp.data.estado)) {
            this.pendiente = true
          }
          if (this.listFallido.includes(resp.data.estado)) {
            this.fallido = true
          }
        });
      }
    }
  }

}
