import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
    selector: 'app-modal-terminos',
    templateUrl: './modal-terminos.component.html',
    styleUrls: ['./modal-terminos.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatButtonModule]
})
export class ModalTerminosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
