<div *ngIf="dataFormularioRuntAct.length">
  <h5 class="card-title">
    <i-feather class="text-primary me-2 mb-3" name="file-text"></i-feather>
    Datos de runt
  </h5>
  <form [formGroup]="formularioRunt" class="row">
    <div [class]="item.col" *ngFor="let item of dataFormularioRuntAct" class="mb-3 pe-4">
      <div *ngIf="item.type === 'text' && item.visible">
        <label for="input-{{item.formControlName}}" class="form-label">{{item.label}}</label>
        <input id="input-{{item.formControlName}}" [formControlName]="item.formControlName" [type]="item.type"
          class="form-control">
      </div>
      <div *ngIf="item.type == 'select' && item.visible">
        <label for="input-{{item.formControlName}}" id="{{item.formControlName}}"
          class="form-label">{{item.label}}</label>
        <ng-select ngDefaultControl [formControlName]="item.formControlName" id="item.formControlName"
          [items]="item.opciones" [bindLabel]="item.bindLabel ? item.bindLabel : 'descripcion'"
          [bindValue]="item.bindValue ? item.bindValue : 'id'" placeholder="Seleccionar" [searchable]="true"
          notFoundText="No hay datos" aria-labelledby="item.formControlName" (change)="changeSelect($event, {formControlName: item.formControlName})">
        </ng-select>
      </div>
      <app-msg-error [messages]="item?.errors" [control]="formularioRunt.controls[item.formControlName]"></app-msg-error>
    </div>
  </form>
</div>