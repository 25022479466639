import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeneralHttpService } from 'src/app/shared/services/general-http/general-http.service';

@Injectable({
  providedIn: 'root'
})
export class ParametricosService {

  private listaParametricos: any = {};

  constructor(private http: GeneralHttpService) { }

  obtenerParametricas(parametrico: string, url: string = 'Movilidad'): Observable<any>{
    return new Observable( (observer) => {
      if(parametrico === '') {
        observer.error("paramétrico requerido");
      }
      if (this.listaParametricos[parametrico]){
        observer.next(this.listaParametricos[parametrico]);
      }else{
        this.consultarParametricas(parametrico, `api/v1/Sura/${url}/`).subscribe( resp => {
          observer.next(resp);
        });
      }
    });
  }


  consultarParametricas = (parametrico: string, url:string = `api/v1/Sura/Movilidad/`) => this.http.get(`${url}${parametrico}`)
                        .pipe( map( (ev: any) => {
                          this.listaParametricos[parametrico] = ev.data;
                          return ev.data;
                        }))
}