import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { MsgErrorComponent } from '../../../../shared/components/msg-error/msg-error.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FeatherModule } from 'angular-feather';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-formulario-datos-errados',
    templateUrl: './formulario-datos-errados.component.html',
    styleUrls: ['./formulario-datos-errados.component.scss'],
    standalone: true,
    imports: [NgIf, FeatherModule, ReactiveFormsModule, NgFor, NgSelectModule, MsgErrorComponent]
})
export class FormularioDatosErradosComponent implements OnInit {

  @Input() formularioRunt: UntypedFormGroup
  @Input() dataFormularioRuntAct = []
  @Output() changeSelectEmit: EventEmitter<any> = new EventEmitter()

  constructor() {
    
  }

  ngOnInit(): void {
  }

  changeSelect(event, item) {
    if (item.formControlName === 'idMarca') {
      this.formularioRunt.controls.idLinea.setValue('')
    }
    this.changeSelectEmit.emit({event, item})
  }

}
