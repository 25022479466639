<div class="container">
    <div class="row">
      <div class="col-12 col-md-6 pt-md-5 mt-md-5 pe-md-5">
        <h1 class="pt-5 mt-md-5 text-primary fw-bold">Cambiar contraseña</h1>
        <form action="" [formGroup]="formulario">
          
          <div class="row pe-md-5" *ngIf="authEmail !== ''">
            <div class="alert alert-success" role="alert" >
              Hemos enviado el código de verificación al correo electrónico: <b>{{authEmail}}</b>
            </div>
          </div>
          <div class="row pe-md-5">
            <div class="col-12 mt-5">
              <label class="form-label">Código verificación</label>
              <input type="number" class="form-control" formControlName="codigo">
            </div>
            <app-msg-error 
            [messages]="mensajes['codigo']"
            [control]="formulario.controls['codigo']"
            ></app-msg-error>
            
          </div>
          <div class="row pe-md-5">
            
            <div class="col-12 mt-5">
              <label for="inputPassword4" class="form-label">Nueva contraseña *</label>
              <input type="password" class="form-control" id="inputPassword4" formControlName="password">
             
             
              <div  *ngIf="!formulario.controls['password'].hasError('required')">
                
                <label class="col" [ngClass]="formulario.controls['password'].hasError('required') || formulario.controls['password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                  <i class="material-icons">{{ formulario.controls['password'].hasError('required') ||
                    formulario.controls['password'].hasError('minlength') ? 'cancel' :
                    'check_circle' }}</i>
                    ¡Debe tener al menos 6 caracteres!
                </label>
                <label class="col" [ngClass]="formulario.controls['password'].hasError('required') || formulario.controls['password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                  <i class="material-icons">{{ formulario.controls['password'].hasError('required') ||
                    formulario.controls['password'].hasError('hasNumber') ? 'cancel' :
                    'check_circle' }}</i>
                    Debe contener al menos 1 número!
                </label>
                <label class="col" [ngClass]="formulario.controls['password'].hasError('required') || formulario.controls['password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                  <i class="material-icons">{{ formulario.controls['password'].hasError('required') ||
                    formulario.controls['password'].hasError('hasCapitalCase') ? 'cancel' :
                    'check_circle' }}</i>
                    ¡Debe contener al menos una Mayúsculas!
                </label>
                <label class="col" [ngClass]="formulario.controls['password'].hasError('required') || formulario.controls['password'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                  <i class="material-icons">{{ formulario.controls['password'].hasError('required') ||
                    formulario.controls['password'].hasError('hasSmallCase') ? 'cancel' :
                    'check_circle' }}</i>
                    ¡Debe contener al menos 1 letra en minúsculas!
                </label>
                <label class="col" [ngClass]="formulario.controls['password'].hasError('required') || formulario.controls['password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                  <i class="material-icons">{{ formulario.controls['password'].hasError('required') ||
                    formulario.controls['password'].hasError('hasSpecialCharacters') ? 'cancel' :
                    'check_circle' }}</i>
                    ¡Debe contener al menos 1 carácter especial!
                </label>
              </div>
            </div>
            <app-msg-error 
            [messages]="mensajes['password']"
            [control]="formulario.controls['password']"
            ></app-msg-error>
            <div class="col-12 mt-5">
              <label for="inputPassword4" class="form-label">Repetir contraseña *</label>
              <input type="password" class="form-control" id="inputPassword4" formControlName="confirmPassword">
            </div>
            <app-msg-error 
            [messages]="mensajes['confirmPassword']"
            [control]="formulario.controls['confirmPassword']"
            ></app-msg-error>
            <div class="d-flex align-items-center flex-column my-5">
              <button class="btn btn-primary mb-4 w-100" (click)="submit()">Modificar</button>
              <p class="mb-0">¿Ya tienes una cuenta? </p>
              <a routerLink="/login" class="text-decoration-none">Iniciar sesión</a>
            </div>
            <div class="alert alert-danger" role="alert" *ngIf="msgError !== ''">
              {{msgError}}
            </div>
          </div>
          
        </form>
      </div>
      <div class="col-6 d-none d-md-inline-block">
        <img src="/assets/images/img-sesion.webp" alt="Seguros 100% digital y simple" class="h-100">
      </div>
    </div>
  </div>