export class RegistroModel {
    costumerName: string;
    confirmarContrasena: string;
    contrasena: string;
    email: string;
    terminosCondiciones: Boolean;
    fechaAcepta: number;

    constructor(data: any){
        this.costumerName = data.costumerName ?? '';
        this.confirmarContrasena = data.confirmarContrasena ?? '';
        this.contrasena = data.contrasena ?? '';
        this.email = data.email ?? '';
        this.terminosCondiciones = data.terminosCondiciones ?? '';
        this.fechaAcepta = data.fechaAcepta ?? Date.now();
    }
}
