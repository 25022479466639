import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { GeneralCoreService } from 'src/app/core/services/general-core/general-core.service';
import { OnBoardingAutos } from 'src/app/onboarding/models/onboarding.model';
import { OnboardingService } from 'src/app/onboarding/services/onboarding/onboarding.service';
import { InformacionPagoComponent } from '../../informacion-pago/informacion-pago.component';
import { InformacionCoberturasComponent } from '../../autos/informacion-coberturas/informacion-coberturas.component';
import { InformacionPlanesComponent } from '../../informacion-planes/informacion-planes.component';
import { InformacionTomadorMotosComponent } from '../informacion-tomador-motos/informacion-tomador-motos.component';
import { InformacionMotoComponent } from '../informacion-moto/informacion-moto.component';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-onboarding-moto',
    templateUrl: './onboarding-moto.component.html',
    styleUrls: ['./onboarding-moto.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, InformacionMotoComponent, InformacionTomadorMotosComponent, InformacionPlanesComponent, InformacionCoberturasComponent, InformacionPagoComponent]
})
export class OnboardingMotoComponent implements OnInit {
  onboardingData: OnBoardingAutos;
  pasoActual = '';
  localUrlMotos = '/onboarding/motos';

  pasos: any = {
    datosVehiculo: true,
    datosTomador: false,
    datosCobertura: false,
    datosPago: false,
    datosFinalizar: false,
  };

  pasosNew: any[] = [
    {
      paso: 'datosVehiculo',
      estado: false,
      visible: true,
      titulo: 'Para empezar vamos a necesitar la <b class="fw-bold">placa de tu moto</b>'
    },
    {
      paso: 'datosTomador',
      estado: false,
      visible: false,
      titulo: 'Ingresa los <b class="fw-bold"> datos del propietario</b>',
      subtitulo: 'Ten en cuenta que es la persona registrada en la <b class="fw-bold"> tarjeta de propiedad</b>'
    },
    {
      paso: 'datosPlanes',
      estado: false,
      visible: false,
      titulo: '<b class="fw-bold">Elige el plan </b> que más se ajuste a tus necesidades'
    },
    {
      paso: 'datosCobertura',
      estado: false,
      visible: false,
      titulo: 'A medida que seleccioes las coberturas, se irá calculando el valor'
    },
    {
      paso: 'datosPago',
      estado: false,
      visible: false,
      titulo: 'Resumen de tu<b class="fw-bold"> cotización </b>'
    },
    {
      paso: 'datosFinalizar',
      estado: false,
      visible: false,
    },
  ];


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private generalCoreService: GeneralCoreService,
    private onboardingService: OnboardingService
  ) {

    this.onboardingData = JSON.parse(localStorage.getItem('onboarding'));

    if (this.onboardingData){
      this.generalCoreService.openDialog({
          titulo: 'Cotización pendiente',
          mensaje: `Tiene una cotización pendiente, ¿Deseas continuar con la cotización?`,
          tipo: 'warning',
          confirmButtonText: 'Si, continuar',
          cancelButtonText: 'No, volver',
          hasCancelButton: true,
          callbackConfirm: () => {

          },
          callbackCancel: () => {
            localStorage.removeItem('onboarding');
            location.reload();
          }
      });
    }
    this.validaParametros();
  }

  private validaParametros(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.paso) {
        let tempo = 0;
        this.pasosNew.forEach(e => {
          if (e.paso === params.paso) {
            tempo++;
          }
        });

        if (params.paso === 'datosPago') {
          if (!this.onboardingService.planSeleccionado) {
            this.router.navigate([this.localUrlMotos], { queryParams: { paso: 'datosPlanes' } });
          }
        }

        if (tempo === 1) {
          if (this.onboardingData) {
            this.pasosNew.forEach(element => {
              element.visible = element.paso === params.paso ? true : false;
            });
          } else {
            this.router.navigate([this.localUrlMotos], { queryParams: { paso: 'datosVehiculo' } });
          }

          this.pasosNew.forEach(element => {
            element.visible = element.paso === params.paso ? true : false;
          });
        } else {
          this.router.navigate([this.localUrlMotos], { queryParams: { paso: 'datosVehiculo' } });
        }
      } else {
        if (this.onboardingData) {
          this.router.navigate([this.localUrlMotos], { queryParams: { paso: this.onboardingData.siguienteComponente } });
        } else {
          this.router.navigate([this.localUrlMotos], { queryParams: { paso: 'datosVehiculo' } });
        }
      }
    });
  }

  ngOnInit(): void {
  }

  private validarPasoActual(paso: string, siguiente: boolean = false): void {

    if (siguiente){
      this.router.navigate([this.localUrlMotos], { queryParams: { paso } });
    }else{
      if (!this.onboardingData[paso]){
        this.router.navigate([this.localUrlMotos], { queryParams: { paso: 'datosVehiculo' } });
      }
    }
  }

  guardar(data: any): void{

    this.consultarCoberturasPorPlanes(data);

    if (!this.onboardingData){
      this.onboardingData = new OnBoardingAutos({});
    }else{
      const dataOnb = JSON.parse(localStorage.getItem('onboarding'));
      this.onboardingData = new OnBoardingAutos(dataOnb);
    }

    this.onboardingData = {... this.onboardingData,  ... data};
    this.onboardingData.idCotizacion =  this.onboardingData.idCotizacion;

    localStorage.setItem('onboarding', JSON.stringify(this.onboardingData));
    this.validarPasoActual(data.siguienteComponente, true);

  }

  private consultarCoberturasPorPlanes(data: any): void {
    if (data.datosVehiculo) {
      const requestFork: any = {};
      const responseFork: any = {};
      const dataOnboardingTemp = new OnBoardingAutos(JSON.parse(localStorage.getItem('onboarding')));

      if ( dataOnboardingTemp.datosCobertura){
        dataOnboardingTemp.datosCobertura = null;
        dataOnboardingTemp.datosTarifa = null;
        localStorage.setItem('onboarding', JSON.stringify({ ...dataOnboardingTemp}));
      }

      data.datosPlanes.forEach(element => {
        // tslint:disable-next-line: max-line-length
        requestFork[element.codigoPlan] = this.onboardingService.obtenerCoberturasPorPlan(data.idCotizacion, data.datosVehiculo.ciudadCirculacion.id, element.codigoPlan);
        responseFork[element.codigoPlan] = {};
      });

      forkJoin(requestFork)
        .subscribe((respFork) => {
          const dataOnboarding = JSON.parse(localStorage.getItem('onboarding'));
          localStorage.setItem('onboarding', JSON.stringify({ ...dataOnboarding, ...{ datosCobertura: respFork } }));
        });
    }
  }

  volver(data: any): void{
    this.router.navigate([this.localUrlMotos], { queryParams: { paso: data.componente } });
    // this.validarPasoActual(data.componente);
  }

  getPasoVisible(): any {
    return this.pasosNew.find(paso => paso.visible );
  }

  validarOnBoarding(): void {
    const dataOnb = new OnBoardingAutos(JSON.parse(localStorage.getItem('onboarding')));

    const fechaRegistro = new Date(dataOnb.fecha).getTime();
    const fechaActual = new Date().getTime();

    const diff = ((fechaActual - fechaRegistro) / 1000 ) / 60;

    if (diff > 30) {
      localStorage.clear();
    }
  }
}