<div class="container">
    <div class="row">
      <div class="col-12 col-md-6 pt-md-5 mt-md-5 pe-md-5">
        <div routerLink="/login" class="mt-4 d-inline-block py-2">
          <i-feather class="text-light cursor-pointer btn-volver__icon" name="arrow-left"></i-feather>
        </div>
        <h1 class="pt-5 mt-md-5 text-primary fw-bold">{{titulo}}</h1>
        <form action="" [formGroup]="formulario" *ngIf="!validarCodigo">
          
          <div class="row pe-md-5">
            <div class="col-12 mt-5">
              <label for="inputEmail4" class="form-label">Correo electrónico </label>
              <input type="email" class="form-control" id="inputEmail4" formControlName="email">
            </div>
            <app-msg-error 
            [messages]="mensajes['email']"
            [control]="formulario.controls['email']"
            ></app-msg-error>
            
           
            <div class="d-flex align-items-center flex-column my-5">
              <button class="btn btn-primary mb-4 w-100" (click)="submit()">Solicitar</button>
             
            </div>
            <div class="alert alert-success" role="alert" *ngIf="estadoSolicituContrasena">
                Revisa tu correo, te hemos enviado la información de cambio de contraseña. 
              </div>

              <div class="alert alert-danger" role="alert" *ngIf="msgError !== ''">
               {{msgError}}
              </div>
          </div>
        </form>
        
      </div>
      <div class="col-6 d-none d-md-inline-block">
        <img src="/assets/images/img-sesion.webp" alt="Seguros 100% digital y simple" class="h-100">
      </div>
    </div>
  </div>