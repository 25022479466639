<div class="container">
    <div class="row">
      <div class="col-12 col-md-6 pt-md-5 mt-md-5 pe-md-5">
        <h1 class="pt-5 mt-md-5 text-primary fw-bold">Verificar cuenta</h1>
        <div *ngIf="estadoValidacion === 'validado'">
            <h3 class="pt-5 mt-md-5 text-primary fw-bold">La cuenta se verifico correctamente, </h3>
            <div class="d-flex  flex-column my-5">
                <a routerLink="/login" class="text-decoration-none">Iniciar sesión</a>
            </div>
        </div>
        <div *ngIf="estadoValidacion === 'error'">
            <h3 class="pt-5 mt-md-5 text-primary fw-bold">Algo salio mal, Comunicate con el administrador</h3>
            <div class="d-flex  flex-column my-5">
            </div>
        </div>
      </div>
      <div class="col-6 d-none d-md-inline-block">
        <img src="/assets/images/img-sesion.webp" alt="Seguros 100% digital y simple" class="h-100">
      </div>
    </div>
  </div>