import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterLink } from '@angular/router';
import { GeneralCoreService } from 'src/app/core/services/general-core/general-core.service';
import { patronValidacion } from 'src/app/onboarding/enums/validaciones';
import { ModalTerminosComponent } from 'src/app/shared/components/modal-terminos/modal-terminos.component';
import { RegistroModel } from '../../models/registro.model';
import { AuthService } from '../../services/auth/auth.service';
import { CustomValidators } from './custom-validators';
import { MsgErrorComponent } from '../../../shared/components/msg-error/msg-error.component';
import { NgIf, NgClass } from '@angular/common';
@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule,
        MsgErrorComponent,
        NgClass,
        RouterLink,
    ],
})
export class RegisterComponent implements OnInit {
  formulario: UntypedFormGroup;
  formularioVerificarCodigo: UntypedFormGroup;
  mensajes = {};
  validarCodigo = false;
  titulo = 'Crear cuenta';

  constructor(
    private authService: AuthService,
    private generalCoreService: GeneralCoreService,
    private route: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.inicializarFormulario();

    this.formulario.controls.contrasena.valueChanges.subscribe(() => {
      this.validaValoresContrasena();
    });
    this.formulario.controls.confirmarContrasena.valueChanges.subscribe(() => {
      this.validaValoresContrasena();
    });
  }

  private validaValoresContrasena(): void {
    const password = this.formulario.controls.contrasena.value;
    const confirmPassword = this.formulario.controls.confirmarContrasena.value;

    if (password !== '' && confirmPassword !== '') {
      if (password !== confirmPassword) {
        this.formulario.controls.confirmarContrasena.setErrors({
          NoPassswordMatch: true,
        });
      }
    }
  }

  private inicializarFormulario(): void {
    this.formulario = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(patronValidacion.email),
      ]),
      contrasena: new UntypedFormControl('', [
        Validators.required,
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        // check whether the entered password has upper case letter
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        // check whether the entered password has a lower case letter
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        // check whether the entered password has a special character
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          { hasSpecialCharacters: true }
        ),
        Validators.minLength(6),
      ]),
      confirmarContrasena: new UntypedFormControl('', [Validators.required]),
      costumerName: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(patronValidacion.soloTexto),
      ]),
      check: new UntypedFormControl('', [Validators.requiredTrue]),
    });

    this.formularioVerificarCodigo = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(patronValidacion.email),
      ]),
      codigo: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(patronValidacion.soloNumeros),
      ]),
    });

    this.mensajes = {
      email: [
        { error: 'required', message: 'Campo requerido' },
        { error: 'pattern', message: 'Formato incorrecto' },
      ],
      costumerName: [
        { error: 'required', message: 'Campo requerido' },
        {
          error: 'pattern',
          message: 'No se aceptan números o caracteres especiales',
        },
      ],
      contrasena: [{ error: 'required', message: 'Campo requerido' }],
      confirmarContrasena: [
        { error: 'required', message: 'Campo requerido' },
        { error: 'NoPassswordMatch', message: 'Contraseña no coincide' },
      ],
      check: [{ error: 'required', message: 'Campo requerido' }],
      codigo: [
        { error: 'required', message: 'Campo requerido' },
        { error: 'pattern', message: 'Formato incorrecto' },
      ],
    };
  }

  submit(): void {
    if (this.formulario.invalid) {
      this.formulario.markAllAsTouched();
    } else {
      let form = this.formulario.getRawValue();
      const data = {
        terminosCondiciones: form.check,
        fechaAcepta: new Date(),
        confirmarContrasena: form.confirmarContrasena,
        contrasena: form.contrasena,
        costumerName: form.costumerName,
        email: form.email,
      };

      this.authService.registro(new RegistroModel(data)).subscribe((resp) => {
        this.validarCodigo = true;
        this.titulo = 'Verificar código';
        this.formularioVerificarCodigo.controls.email.setValue(
          this.formulario.controls.email.value
        );
        this.formulario.reset();
      });
    }
  }

  openModal() {
    const dialogRef = this.dialog.open(ModalTerminosComponent);

    dialogRef.afterClosed().subscribe((result) => {});
  }

  verificarCodigo() {
    if (this.formularioVerificarCodigo.invalid) {
      this.formularioVerificarCodigo.markAllAsTouched();
    } else {
      this.authService
        .verificarCodigo(this.formularioVerificarCodigo.getRawValue())
        .subscribe(
          (resp) => {
            this.formularioVerificarCodigo.reset();
            this.generalCoreService.openDialog({
              titulo: 'Felicidades',
              mensaje: resp.message,
              tipo: 'success',
              callbackConfirm: () => {
                this.route.navigate(['login']);
              },
              hasCancelButton: false,
            });
          },
          (err) => {}
        );
    }
  }
}
