import { Injectable } from '@angular/core';
import { GeneralHttpService } from '../general-http/general-http.service';

@Injectable({
  providedIn: 'root'
})
export class TerminosPoliticasService {

  constructor(private http: GeneralHttpService) { }

  setTerminosCondiciones = (data:any) => this.http.post(`api/v1/tucotizacion/terminoscondiciones`,data);
}
