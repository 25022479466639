import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ComponentService } from 'src/app/shared/services/component/component.service';
import { AccidentesService } from 'src/app/onboarding/services/accidentes/accidentes.service';

import { map } from 'rxjs/operators';
import { OnboardingAccidentes } from 'src/app/onboarding/models/onboardingAccidentes.model';
import { BotonesOnboardingComponent } from '../../botones-onboarding/botones-onboarding.component';
import { BottomSheetComponent } from '../../../../shared/components/bottom-sheet/bottom-sheet.component';
import { NgFor, NgIf } from '@angular/common';
import { CheckboxCardOnboardingComponent } from './checkbox-card-onboarding/checkbox-card-onboarding.component';

@Component({
    selector: 'app-coberturas-onboarding',
    templateUrl: './coberturas-onboarding.component.html',
    styleUrls: ['./coberturas-onboarding.component.scss'],
    standalone: true,
    imports: [CheckboxCardOnboardingComponent, NgFor, BottomSheetComponent, NgIf, BotonesOnboardingComponent]
})
export class CoberturasOnboardingComponent implements OnInit {
  @Output() eventoVolver: EventEmitter<any> = new EventEmitter();
  @Output() eventoGuardar: EventEmitter<any> = new EventEmitter();

  public selectedCover: any;
  public isActiveModal = false;
  public codigoCoberturas: string[] = ['GTS MED', 'RDH', 'AUX. INUN', 'REN. IT', 'AUX. FUN', 'DESM'] 
  public listaPlanes: [] = [];
  public contenidos: any[] = [];
  public planSeleccionado: any;
  public dataLocal: OnboardingAccidentes;
  public coberturaPrincipal = {
    url_img: "https://cliente-market.s3.amazonaws.com/assets/moto/cobertura-transporte.webp",
    titulo: "Muerte <b>accidental</b>",
    descripcion: "Ofrecemos un auxilio económico de $400,000 mil pesos durante 36 meses para tus beneficiarios, en caso de que tu o tu pareja fallezcan por muerte accidental se pagara ese valor. Recuerda que este seguro te da el beneficio de asegurarte a ti y a tu pareja por el mismo costo.",
    codigo: "MUE ACC",
    active: true
  }

  constructor(private componentService: ComponentService,
              private accidentesService: AccidentesService) { }

  ngOnInit(): void {
    localStorage.removeItem('onboardingAccidentes');
    this.accidentesService.obtenerPlanes().subscribe(resp => {
      this.listaPlanes = resp.data
    })
    
    this.dataLocal = new OnboardingAccidentes(JSON.parse(localStorage.getItem('onboardingAccidentes')));

    
    this.componentService.getContentComponetByPage<any>('coberturas', 'accidentes').pipe(
    map(coberturas => {
     return coberturas.data.map((value, index) => {
        return {
          ...value,
          active: false,
          codigo: this.codigoCoberturas[index]
        }
      })
    })
    ).subscribe((resp: any) => {
      this.contenidos = resp;
      if (this.dataLocal.plan) {
        this.planSeleccionado = this.dataLocal.plan
        this.dataLocal.plan.coberturasSeleccionadas.forEach(element => {
          this.contenidos.forEach(item => {
            if (item.codigo === element.codigo) {
              item.active = true
            }
          })
        });
      }
    });

  }

  public activeBottomSheet(cover): void {
    this.selectedCover = cover;
    this.isActiveModal = true;
  }

  public closeBottomSheet(): void {
    this.isActiveModal = false;
  }

  public selectCover(value) {
    let coberturasSeleccionadas = this.contenidos.filter(cobertura => cobertura.active)
    
    if (coberturasSeleccionadas.length >= 2 && value.active) {
      value.active = false
      coberturasSeleccionadas = this.contenidos.filter(cobertura => cobertura.active)
    }else if (coberturasSeleccionadas.length < 2) {
      value.active = !value.active
      coberturasSeleccionadas = this.contenidos.filter(cobertura => cobertura.active)
    }

    if (coberturasSeleccionadas.length === 2) {
      this.planSeleccionado = this.listaPlanes.filter((plan: any) => plan.txtDesc.includes(coberturasSeleccionadas[0].codigo))
        .find((plan: any) => plan.txtDesc.includes(coberturasSeleccionadas[1].codigo))
        this.planSeleccionado = {...this.planSeleccionado, coberturasSeleccionadas}
    } else {
      this.planSeleccionado = null
    } 
  }

  public submit() {
    const data = {
      plan: this.planSeleccionado,
      siguienteComponente: 'datosTomador'
    }
    this.eventoGuardar.emit(data)
  }

}
