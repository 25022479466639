<form [formGroup]="formulario">
  <div class="">
    <div class="form-floating mb-4 col-md-12">
      <input type="text" class="form-control text-uppercase" id="input-placa" placeholder="placa"
      (keyup)="onKeydown($event)" formControlName="placa" aria-describedby="emailHelp" maxlength="6">
      <label for="input-placa" class="form-label">Placa </label>
        <app-msg-error
          [messages]="dataFormulario.placa.errors"
          [control]="formulario.controls['placa']"
          ></app-msg-error>
    </div>

  </div>

  <app-terminos-politicas [form]="formulario"></app-terminos-politicas>

  <div class="row" *ngIf="!informacionVehiculo">
    <div class="mb-3 d-flex justify-content-center my-4 col-md-12">
      <button type="button" class="btn btn-primary" (click)="consultarPlaca()">
        <span *ngIf="estadoConsultaPlaca" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span *ngIf="estadoConsultaPlaca"> Consultando...</span>
        <span *ngIf="!estadoConsultaPlaca">Consultar</span>
      </button>
    </div>
  </div>


    <div class="card" *ngIf="informacionVehiculo">
      <div class="card-body row">
        <h5 class="card-title mb-3">
          <i-feather class="text-primary me-2" name="info"></i-feather>
          Información del vehículo
        </h5>
        <h6 class="card-subtitle mb-2 text-muted"></h6>
        <div class="col-12 col-md-6 mb-3">
          <small class="text-grey-dark fw-bold">Clase de vehículo</small>
          <p class="card-text">{{informacionVehiculo.dsClase}}</p>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <small class="text-grey-dark fw-bold">Modelo del vehículo</small>
          <p class="card-text">{{informacionVehiculo.modelo}}</p>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <small class="text-grey-dark fw-bold">Marca</small>
          <p class="card-text">{{informacionVehiculo.dsMarca}}</p>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <small class="text-grey-dark fw-bold">Línea</small>
          <p class="card-text">{{informacionVehiculo.dsLinea}}</p>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="informacionVehiculo">
      <div class="mb-3 mt-4" >
        <label for="ciudadCirculacionId" id="ciudadCirculacion" class="form-label label-select mb-3">Cuidad de circulación</label>
        <ng-select
            ngDefaultControl
            formControlName="ciudadCirculacion"
            id="ciudadCirculacionId"
            [items]="listaCiudades"
            bindLabel="descripcion"
            bindValue="id"
            placeholder="Seleccionar"
            searchable="true"
            aria-labelledby="ciudadCirculacion">
        </ng-select>
        <app-msg-error
        [messages]="dataFormulario.ciudadCirculacion.errors"
        [control]="formulario.controls['ciudadCirculacion']"
        ></app-msg-error>
      </div>
    </div>
  </form>

  <div *ngIf="informacionVehiculo" class="d-flex justify-content-md-between justify-content-center flex-column-reverse flex-md-row pb-2">
    <button class="btn btn-link ps-md-0 h-100 mt-md-2" (click)="nuevaCotizacion()">Nueva cotizacion</button>
    <app-botones-onboarding class="d-flex justify-content-center justify-content-md-end"
    (eventoSiguiente)="submit()"
    ></app-botones-onboarding>
  </div>
