<div [formGroup]="formulario" *ngIf="formulario">
  <app-informacion-tomador-accidentes [titulo]="'Datos del tomador'" [formulario]="tomador" [listaFormulario]="listaFormulario"></app-informacion-tomador-accidentes>
  <app-informacion-tomador-accidentes *ngIf="tieneBeneficiario" [titulo]="'Datos del beneficiario'" [formulario]="asegurado" [listaFormulario]="listaFormulario" [tieneBotonCerrar]="true" (cerrarFormulario)="agregarFormularioBeneficiario()"></app-informacion-tomador-accidentes>
  <app-terminos-politicas *ngIf="!session" [form]="tomador"></app-terminos-politicas>
  <div *ngIf="!tieneBeneficiario" class="p-4 box-alert d-flex text-primary border-start border-5 border-primary mb-2">
    <i-feather class="me-3" name="info"></i-feather>
    Recuerda que tu pareja puede beneficiarse contigo
  </div>
</div>
<div class="d-flex justify-content-md-between justify-content-center align-items-baseline flex-column-reverse flex-md-row pb-5">
  <button class="btn btn-outline-primary bg-white text-primary order-1 ps-4 mt-lg-0" (click)="agregarFormularioBeneficiario()">
    <i-feather *ngIf="!tieneBeneficiario" class="me-2" name="plus"></i-feather>
    <i-feather *ngIf="tieneBeneficiario" class="me-2 trash-icon" name="trash-2"></i-feather>
    {{tieneBeneficiario ? 'Eliminar' : 'Agregar'}} beneficiario
  </button>
  <app-botones-onboarding class="d-flex justify-content-center justify-content-md-end order-md-1" [activarBotonVolver]="true"
  (eventoVolver)="volver($event)" (eventoSiguiente)="enviarDatos()">
  </app-botones-onboarding>
</div>