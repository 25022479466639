<form [formGroup]="formulario" #form="ngForm">

  <div class="card" *ngIf="informacionVehiculo">
    <div class="card-header bg-primary d-md-flex justify-content-between text-center align-items-center py-3"
      *ngIf="dataLocalFormulario">
      <h2 class="text-white mb-0">{{planSeleccionado?.nombre}}</h2>
      <div class="d-flex align-items-end justify-content-center">
        <h1 class="card-text text-white mb-0">{{dataValorCotizacion?.primaTotal | currency:'$':'code':'1.0':'en' }}</h1>
        <small class="mb-2 text-white fw-light"> / anual</small>
      </div>
    </div>

    <div class="card-body row">
      <app-datos-vehiculo *ngIf="currentPage === 'autos'" [informacionVehiculo]="informacionVehiculo"></app-datos-vehiculo>
      <app-datos-bici *ngIf="currentPage === 'bicicleta'" [informacionVehiculo]="informacionVehiculo"></app-datos-bici>
      <app-datos-moto *ngIf="currentPage === 'motos'" [informacionVehiculo]="informacionVehiculo"></app-datos-moto>
      <h5 class="card-title mb-3">
        <i-feather class="text-primary me-2" name="calendar"></i-feather>
        Vigencia de la póliza
      </h5>
      <div class="col-12 col-md-6 mb-3 ps-5">
        <small class="text-grey-dark fw-bold">Fecha inicio</small>
        <p class="card-text">{{dataValorCotizacion?.periodoInicial | date: 'dd/MM/yyyy'}}</p>
      </div>
      <div class="col-12 col-md-6 mb-3 ps-5">
        <small class="text-grey-dark fw-bold">Fecha final</small>
        <p class="card-text">{{dataValorCotizacion?.periodoFinal | date: 'dd/MM/yyyy'}}</p>
      </div>
      <h5 class="card-title mb-3">
        <i-feather class="text-primary me-2" name="user"></i-feather>
        Datos personales
      </h5>
      <div class="col-12 col-md-6 mb-3 ps-5">
        <small class="text-grey-dark fw-bold">Nombre completo</small>
        <p class="card-text">{{datosTomador?.primerNombre}} {{datosTomador?.segundoNombre}} {{datosTomador?.primerApellido}} {{datosTomador?.segundoApellido}}</p>
      </div>
      <div class="col-12 col-md-6 mb-3 ps-5">
        <small class="text-grey-dark fw-bold">Documento de identidad</small>
        <p class="card-text">{{datosTomador?.numeroDocumento}}</p>
      </div>
    </div>
  </div>

  <div class="accordion mt-4" id="accordionExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingTwo">
        <button class="accordion-button collapsed p-4 bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          <h5 class="mb-0">
            <i-feather class="text-primary me-2" name="layers"></i-feather>
            Coberturas incluidas
          </h5>
        </button>
      </h2>
      <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
        <div class="accordion-body px-5 py-4">
          <div class="row">
            <div class="col-12 col-md-6 " *ngFor="let itemCobertura of coberturasHabilitadas">
              <div class="d-flex">
                <i-feather class="text-success me-2 d-flex" name="check"></i-feather>
                <p class="text-primary fw-bold">{{itemCobertura.dsGrupo}}</p>
              </div>
              <ul>
                <li *ngFor="let itemC of itemCobertura.coberturas">
                  {{itemC.dsCobertura}}
                  <ul>
                    <li *ngFor="let itemOpciones of itemC.gruposOpciones">{{itemOpciones.titulo}} 
                      <ng-container *ngFor="let opp of itemOpciones.opciones">
                        <span *ngIf="opp.cdOpcion === itemOpciones.cdOpcionSeleccionada">  ( {{opp.dsOpcion | coberturas}} )</span>
                      </ng-container>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="my-4 ms-2" *ngIf="otrosMediosDePago">
    <h5 class="mb-4 text-dark">Selecciona el medio de pago</h5>
    <div class="form-group">
      <div class="text-center mb-4">
        <ul class="nav nav-pills radio-tabs mb-4">
          <li class="nav-item me-md-4 mb-3 mb-md-0">
            <button type="button" class="nav-link py-4" [ngClass]="{active: idMetodo === 2 }" (click)="changeMetodo(2)">
              <img src="/assets/images/logos/sura.webp" alt="Logo de Sura" height="40">
            </button>
          </li>
          <li class="nav-item">
            <button type="button" class="nav-link border-1 active py-4" [ngClass]="{active: idMetodo === 1 }"
              (click)="changeMetodo(1)">
              <img src="/assets/images/logos/coink.svg" alt="Logo de Coink" height="40">
            </button>
          </li>
        </ul>
        <app-msg-error 
                [messages]="errors"
                [control]="formulario.controls.metodoPago"
              ></app-msg-error>
      </div>
    </div>
  </div>
</form> 

<div _ngcontent-axu-c131="" class="d-grid gap-3 d-lg-flex justify-content-center mt-5">
    <button 
      *ngIf="!volverHome"
      type="button" 
      class="btn btn-danger"  
      (click)="finalizar()">¡Quiero este plan!
  </button>
  <button *ngIf="volverHome" type="button" class="btn btn-primary" [routerLink]="'/'">Volver a inicio</button>
  <button *ngIf="volverHome" type="button" class="btn btn-success d-flex align-items-center" (click)="redirectWhatsapp()">
    <img src="assets/images/whatsapp.svg" alt="icono whatsapp" height="30" class="me-1">  Contactar con un asesor
  </button>
</div>

<app-botones-onboarding class="botones-onboarding"
  [activarBotonContinuar]=onboardingService?.riesgoEstandar
  (eventoVolver)="volver($event)"
  (eventoSiguiente)="submit()" labelBtnContinuar="Ir a pagar">
</app-botones-onboarding>

<div class="d-flex justify-content-between pb-5 flex-column flex-md-row">
  <div class="text-center order-2 order-md-1">
    <button class="btn btn-link" (click)="print()"><i-feather class="me-2" name="printer"></i-feather>Imprimir</button>
  </div>
  <div class="d-flex justify-content-center align-items-end order-1 order-md-2">
    <img src="assets/images/logos/sura.webp" alt="Logo de sura" height="40">
  </div>
  
</div>