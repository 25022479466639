import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { GeneralHttpService } from 'src/app/shared/services/general-http/general-http.service';

@Injectable({
  providedIn: 'root'
})
export class AccidentesService {

  codigoToken:string;

  constructor(private http: GeneralHttpService) { }

  obtenerPlanes(): Observable<any> {
    return this.http.get(`api/v1/Mundial/Accidentes/Planes`)
  }

  obtenerDepartamentos(): Observable<any> {
    return this.http.get(`api/v1/Parametricos/Departamentos`)
  }

  obtenerMunicipios(idDepartamento: number): Observable<any> {
    return this.http.get(`api/v1/Parametricos/Departamento/${idDepartamento}/Municipios`)
  }

  obtenerTipoDocumento(): Observable<any> {
    return this.http.get(`api/v1/Mundial/Accidentes/TiposDocumento`)
  }

  enviarCotizacion(data: any): Observable<any> {
    const codigoPartner = localStorage.getItem('partner');
    data["CodigoPartner"] = codigoPartner;
    return this.http.post(`api/v1/Mundial/Accidentes/Cotizar`, data)
  }

  ejecutarPagoCoink(data: any): Observable<any>{
    return this.http.post(`api/v1/pasarelaspagos/coink`, data);
  }

  existeUsuario(idCotizacion: number): Observable<any> {
    return this.http.get(`api/v1/account/user/exist/${idCotizacion}`)
  }

  enviarCodigoEmail(idCotizacion: number): Observable<any> {
    return this.http.get(`api/v1/Account/enviaremailconfirmacion/${idCotizacion}`)
  }

  test(): Observable<any> {
    return this.http.get(`api/v1/pasarelaspagos/Mundial/Pagos`,)
  }

}
