import { Validators } from '@angular/forms';
import { patronValidacion } from 'src/app/onboarding/enums/validaciones';
import { Validation } from 'src/app/onboarding/utils/validation';

export class InformacionBiciForm{
    static getForm(): any{
        return [
            {
                label: 'Fecha de compra',
                value: null,
                disabled: false,
                readonly: false,
                formControlName: 'fechaCompra',
                placeholder: '',
                type: 'date',
                col: 'col-md-6',
                validations:  [ Validators.required ],
                errors: [
                  { error: 'required', message: 'Campo requerido'}
                ],
            },
            {
                label: 'Modelo',
                value: '',
                disabled: false,
                readonly: false,
                formControlName: 'anio',
                opciones: [],
                placeholder: '',
                type: 'select',
                col: 'col-md-6',
                validations:  [ Validators.required ],
                errors: [
                  { error: 'required', message: 'Campo requerido'}
                ],
            },
            {
              label: 'Ingresa el valor de tu bici o patineta según factura',
              value: '',
              disabled: false,
              readonly: false,
              formControlName: 'titulo',
              placeholder: '',
              type: 'title',
              col: 'col-12',
              validations: [],
              errors: []
          },
            {
              label: 'Valor',
              value: '',
              disabled: false,
              readonly: false,
              formControlName: 'valor',
              placeholder: 'ej: $600.000',
              type: 'text',
              col: 'col-12',
              // validations:  [ Validators.required, Validators.pattern(patronValidacion.validacionPunto), Validation.rangePricing ],
              validations:  [],
              errors: [
                { error: 'required', message: 'Campo requerido'},
                { error: 'pattern', message: 'Se aceptan solo números'},
                { error: 'rangePricingBici', message: 'El precio tiene que ser desde $600.000 hasta $45.000.000'},
                { error: 'rangePricingPatineta', message: 'El precio tiene que ser desde $600.000 hasta $10.000.000'}
              ],
            },
            {
              label: 'Ciudad de circulación',
              value: '',
              disabled: false,
              readonly: false,
              formControlName: 'ciudadCirculacion',
              placeholder: '',
              type: 'select',
              col: 'col-12',
              validations: [Validators.required ],
              errors: [
                { error: 'required', message: 'Campo requerido'}
              ],
            },
            {
              label: 'Marca',
              value: '',
              disabled: false,
              readonly: false,
              formControlName: 'marca',
              placeholder: '',
              opciones: [],
              type: 'selectMarca',
              col: 'col-md-6',
              validations:  [ Validators.required ],
              errors: [
                { error: 'required', message: 'Campo requerido'}
              ],
            },
            {
              label: 'Tipo de bicicleta',
              value: '',
              disabled: false,
              readonly: false,
              formControlName: 'tipoBici',
              placeholder: '',
              opciones: [],
              type: 'select',
              col: 'col-md-6',
              validations:  [ Validators.required ],
              errors: [
                { error: 'required', message: 'Campo requerido'}
              ],
            }
        ];
    }
}
