<!-- <div class="my-5 text-center d-flex justify-content-center align-items-center flex-column titulo">
  <h3 class="text-primary fw-light px-5 titulo__onboarding" [innerHTML]="getPasoVisible().titulo"></h3>
</div> -->

<div *ngFor="let item of pasosNew">
  <app-informacion-soat 
    (eventoGuardar)="guardar($event)" 
    *ngIf="item.visible && item.paso == 'datosSoat'">
  </app-informacion-soat>
  <app-informacion-tomador-soat
    (eventoVolver)="volver($event)"
    (eventoGuardar)="guardar($event)" 
    *ngIf="item.visible && item.paso == 'datosTomador'"
  ></app-informacion-tomador-soat>
</div>