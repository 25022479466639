import { DatosVehiculo } from './informacion-vehiculo.interfaz';
export class OnBoardingAutos {
    public idCotizacion: number;
    public datosVehiculo: DatosVehiculo;
    public datosTomador: any;
    public datosCobertura: any;
    public datosPago: any;
    public datosFinalizar: any;
    public datosCotizacion: any;
    public datosTarifa: any;
    public datosPlanes: any[];
    public siguienteComponente: string;
    public sarlaft: string;
    public riesgoEstandar: boolean;
    public fecha: number;

    constructor(data: any){
        this.idCotizacion = data?.idCotizacion ?? null;
        this.datosVehiculo = data?.datosVehiculo ?? null;
        this.datosTomador = data?.datosTomador ?? null;
        this.datosCobertura = data?.datosCobertura ?? null;
        this.siguienteComponente = data?.siguienteComponente ?? '';
        this.datosPago = data?.datosPago ?? '';
        this.datosFinalizar = data?.datosFinalizar ?? null;
        this.datosPlanes = data?.datosPlanes ?? [];
        this.datosCotizacion = data?.datosCotizacion ?? null;
        this.datosTarifa = data?.datosTarifa ?? null;
        this.sarlaft = data?.sarlaft ?? '';
        this.riesgoEstandar = data?.riesgoEstandar ?? null;
        this.fecha = data?.fecha ?? Date.now();
    }
}
