import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { GeneralHttpService } from 'src/app/shared/services/general-http/general-http.service';

@Injectable({
  providedIn: 'root'
})
export class ViajesService {

  constructor(private http: GeneralHttpService) { }

  obtenerCoberturas(): Observable<any> {
    return this.http.json(`assets/json/coberturas-viajes.json`);
  }

  enviarDatosTomadorViajes(data: any): Observable<any> {
    return this.http.post(`api/v1/sura/viajes/cotizacion/tomador`, data)
    // return this.http.json(`assets/json/tomador-viajes.json`);
  }

  obtenerGenero(): Observable<any> {
    return this.http.get(`api/v1/sura/viajes/genero`)
  }

  obtenerTipoDocumento(): Observable<any> {
    return this.http.get(`api/v1/sura/viajes/tipodocumento`)
  }

  consultarTarifa(data: any): Observable<any> {
    return this.http.post(`api/v1/sura/viajes/tarifar/coberturas`, data)
  }

  
}
