export class LoginModel {
    passWord: string;
    userName: string;


    constructor(data: any){
        this.passWord = data.passWord ?? '';
        this.userName = data.userName ?? '';
    }
}
