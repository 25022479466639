import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SalirOnboarding } from 'src/app/core/guards/onboarding/onboarding.guard';
import { GeneralCoreService } from 'src/app/core/services/general-core/general-core.service';
import { AuthService } from 'src/app/sesion/services/auth/auth.service';
import { OnboardingService } from '../../services/onboarding/onboarding.service';
import { OnboardingViajesComponent } from '../../components/viajes/onboarding-viajes/onboarding-viajes.component';
import { OnboardingAccidentesComponent } from '../../components/accidentes/onboarding-accidentes/onboarding-accidentes.component';
import { OnboardingSoatComponent } from '../../components/soat/onboarding-soat/onboarding-soat.component';
import { OnboardingMotoComponent } from '../../components/motos/onboarding-moto/onboarding-moto.component';
import { OnboardingBiciComponent } from '../../components/bici/onboarding-bici/onboarding-bici.component';
import { OnboardingAutosComponent } from '../../components/autos/onboarding-autos/onboarding-autos.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss'],
    standalone: true,
    imports: [NgIf, OnboardingAutosComponent, OnboardingBiciComponent, OnboardingMotoComponent, OnboardingSoatComponent, OnboardingAccidentesComponent, OnboardingViajesComponent]
})
export class OnboardingComponent implements OnInit,  SalirOnboarding {

  objeto: any = {
    currentStep: 1
  };
  public currentPage: string;
  public productosSoportados = ['autos', 'accidentes', 'motos', 'bicicleta', 'viajes']

  constructor(
    public onboardingService: OnboardingService,
    private activatedRoute: ActivatedRoute,
    private generalCoreService: GeneralCoreService,
    private authService: AuthService,
    private router: Router
  ) {
    this.activatedRoute.paramMap.subscribe((paramMap: ParamMap) => {
      this.currentPage = paramMap.get('pagina')
      if (!this.productosSoportados.includes(this.currentPage)) {
        this.router.navigate(['/'])
      }
    });
  }

  ngOnInit(): void {
  }

  permitirSalir(): Promise<boolean>{

    const dataSession = this.authService.getSession();

    return new Promise((resolve) => {

      if(this.authService.currentUrlIsPlanes() && dataSession.roles[0] == 'Onbording'){
        resolve(true);
      }else{
        if (dataSession && this.productosSoportados.includes(this.currentPage)){
          this.generalCoreService.openDialog({
            titulo: 'Atención',
            mensaje: '¿Estas seguro que deseas salir de tu cotización?',
            tipo: 'error',
            hasCancelButton: true,
            callbackConfirm: () => {
              localStorage.removeItem('onboarding');
              localStorage.removeItem('onboardingAccidentes');
              localStorage.removeItem('pasoActual');
              // this.router.navigate(['home']);
              resolve(true);
            }
          });
        }else{
          localStorage.removeItem('onboarding');
          localStorage.removeItem('pasoActual');
          resolve(true);
        }
      }

      
    });
  }



}
