import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViajesService } from 'src/app/onboarding/services/viajes/viajes.service';
import { CoberturasPipe } from '../../../../pipes/coberturas/coberturas.pipe';
import { NgFor, NgIf, CurrencyPipe } from '@angular/common';
import { FeatherModule } from 'angular-feather';

@Component({
    selector: 'app-plan-viajes',
    templateUrl: './plan-viajes.component.html',
    styleUrls: ['./plan-viajes.component.scss'],
    standalone: true,
    imports: [FeatherModule, NgFor, NgIf, CurrencyPipe, CoberturasPipe]
})
export class PlanViajesComponent {
  @Output() eventoPlanViajes: EventEmitter<any> = new EventEmitter();
  @Output() eventoInfoPasajeros: EventEmitter<any> = new EventEmitter();

  @Input() coberturasViajes: any;
  @Input() dataViaje: any;
  

  onClickPlanViajes() {
    this.eventoPlanViajes.emit()
  }

  onClickInfoPasajeros() {
    this.eventoInfoPasajeros.emit()
  }

}
