<form  [formGroup]="formulario"  class="row g-4">
  <ng-container *ngFor="let item of listaFormulario" class="mb-3 pe-4">
    <div [class]="item.col" *ngIf="item.type != 'select' && item.type != 'date' && item.visible">
      <label for="input-{{item.formControlName}}" class="form-label">{{item.label}}</label>
      <input 
        (keydown)="validarInput($event, item.type)"
        id="input-{{item.formControlName}}"  
        [formControlName]="item.formControlName"
        [type]="item.type" 
        class="form-control" 
        > 
        <app-msg-error 
      [messages]="item?.errors"
      [control]="formulario.controls[item.formControlName]">
    </app-msg-error>
    </div>
    <div [class]="item.col" *ngIf="item.type == 'date' && item.visible"  class="d-flex flex-column">
      <label id="label-{{item.formControlName}}" for="input-{{item.formControlName}}" class="form-label">{{item.label}}</label>
      <p-calendar ngDefaultControl 
                  class="d-grid mt-1" 
                  inputStyleClass="form-control" 
                  ariaLabelledBy="label-{{item.formControlName}}" 
                  dateFormat="dd/mm/yy" 
                  inputId="{{item?.formControlName}}" 
                  [type]="item?.type"  
                  [formControlName]="item.formControlName"
                  placeholder="dd/mm/yyyy" 
                  monthNavigator="true" 
                  yearNavigator="true"
                  [maxDate]="maxDate"
                  [yearRange]="yearRange">
      </p-calendar>
      <app-msg-error 
      [messages]="item?.errors"
      [control]="formulario.controls[item.formControlName]">
    </app-msg-error>
    </div>
    <div [class]="item.col" *ngIf="item.type == 'select'">
      <label for="input-{{item.formControlName}}" id="{{item.formControlName}}" class="form-label">{{item.label}}</label>
      <ng-select 
          ngDefaultControl
          [formControlName]="item.formControlName"
          id="item.formControlName"
          [items]="item.opciones"
          [bindLabel]="item.bindLabel ? item.bindLabel : 'descripcion'" 
          [bindValue]="item.bindValue ? item.bindValue : 'id'"
          placeholder="Seleccionar"
          searchable="true"
          notFoundText="No hay datos"
          aria-labelledby="item.formControlName"
          (change)="changeSelect($event, item)">
      </ng-select>
      <app-msg-error 
        [messages]="item?.errors"
        [control]="formulario.controls[item.formControlName]">
      </app-msg-error>
    </div>  
  </ng-container>
</form>