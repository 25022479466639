import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { OnBoardingAutos } from 'src/app/onboarding/models/onboarding.model';
import { OnboardingService } from 'src/app/onboarding/services/onboarding/onboarding.service';
import { AuthService } from 'src/app/sesion/services/auth/auth.service';
import { BotonesOnboardingComponent } from '../botones-onboarding/botones-onboarding.component';
import { CardPlanesComponent } from '../../../shared/components/card-planes/card-planes.component';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-informacion-planes',
    templateUrl: './informacion-planes.component.html',
    styleUrls: ['./informacion-planes.component.scss'],
    standalone: true,
    imports: [
        NgFor,
        CardPlanesComponent,
        BotonesOnboardingComponent,
    ],
})
export class InformacionPlanesComponent implements OnInit {
  @Output() eventoVolver: EventEmitter<any> = new EventEmitter();
  @Output() eventoGuardar: EventEmitter<any> = new EventEmitter();

  public planes = [];
  dataLocalOnboarding: OnBoardingAutos;

  constructor(
    private onboardingServices: OnboardingService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.dataLocalOnboarding = new OnBoardingAutos(
      JSON.parse(localStorage.getItem('onboarding'))
    );
    
    this.dataLocalOnboarding.datosPlanes.forEach((element, index) => {
      if (this.dataLocalOnboarding.datosTarifa[element.codigoPlan]?.primaTotal) {
        this.planes.push({
          codigo: element.codigoPlan,
          nombre: element.nombrePlan,
          valor:
            this.dataLocalOnboarding.datosTarifa[element.codigoPlan]?.primaTotal,
          coberturas:
            this.dataLocalOnboarding.datosCobertura[element.codigoPlan]?.data,
        });
      }
    });

    if (this.dataLocalOnboarding.datosPlanes.length === 1) {
      const data: any = {
        siguienteComponente: 'datosPago',
      };
      this.onboardingServices.planSeleccionado = this.planes[0];
      
      this.eventoGuardar.emit(data);
    } 

  }

  redirect(plan): void {
    const data: any = {
      siguienteComponente: 'datosPago',
    };
    this.onboardingServices.planSeleccionado = plan;
    this.eventoGuardar.emit(data);
  }

  volver(data: any): void {
    this.eventoVolver.emit({ componente: 'datosTomador' });
  }
}
