import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { AccidentesService } from 'src/app/onboarding/services/accidentes/accidentes.service';
import { InformacionPasajerosForm } from './informacion-pasajeros.form';
import { HeroViajesService } from 'src/app/shared/services/hero-viajes/hero-viajes.service';
import { ViajesService } from 'src/app/onboarding/services/viajes/viajes.service';
import { MsgErrorComponent } from '../../../../../shared/components/msg-error/msg-error.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CalendarModule } from 'primeng/calendar';
import { NgIf, NgFor, LowerCasePipe, TitleCasePipe } from '@angular/common';

@Component({
    selector: 'app-informacion-pasajeros',
    templateUrl: './informacion-pasajeros.component.html',
    styleUrls: ['./informacion-pasajeros.component.scss'],
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, NgFor, CalendarModule, NgSelectModule, MsgErrorComponent, LowerCasePipe, TitleCasePipe]
})
export class InformacionPasajerosComponent implements OnInit {
  @Input() formulario: UntypedFormGroup = new UntypedFormGroup({})
  @Input() listaFormulario: any[] = [];
  @Input() index = 0;

  maxDate: Date;
  yearRange: string;

  constructor(private accidentesService: AccidentesService,
              private heroViajesService: HeroViajesService,
              private viajesService: ViajesService) {
    this.maxDate = new Date();
    this.listaFormulario = InformacionPasajerosForm.getForm();
  }

  ngOnInit(): void {
    const minYear = new Date().getFullYear() - 70;
    const maxYear = new Date().getFullYear();
    this.yearRange = minYear + ':' + maxYear;
    this.obtenerCiudades()
    this.obtenerTipoDocumento()
  }

  public obtenerCiudades(): void{
    this.heroViajesService.obtenerCiudades().subscribe((resp: any) => {
      this.listaFormulario.forEach( el => {
        if (el.formControlName === 'codMunicipio') {
          el.opciones = resp.data;
        }
      })
    })
  }

  public obtenerTipoDocumento(): void{
    this.viajesService.obtenerTipoDocumento().subscribe((resp: any) => {
      this.listaFormulario.forEach( el => {
        if (el.formControlName === 'tipoDocumento') {
          el.opciones = resp.data;
        }
      })
    })
  }

}
