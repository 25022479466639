import { Validators } from '@angular/forms';
import { patronValidacion } from 'src/app/onboarding/enums/validaciones';


export class InformacionMotoForm{
    static getForm(): any{
        return {
            placa : {
                label: '',
                value: '',
                col: 'col-md-4',
                disabled: false,
                readonly: false,
                formControlName: 'placa',
                placeholder: '',
                type: 'text',
                validations:  [ Validators.required, Validators.pattern(patronValidacion.alfanumerico) ],
                errors: [
                    { error: 'required', message: 'Campo requerido' },
                    { error: 'pattern', message: 'No se aceptan caracteres especiales' }
                ],
            },
            ciudadCirculacion : {
                label: '',
                value: '',
                col: 'col-md-4',
                disabled: false,
                readonly: false,
                formControlName: 'ciudadCirculacion',
                placeholder: '',
                type: 'select',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido' }
                ],

            },
        };
    }
}
