<h5 class="card-title mb-3">
  <i-feather class="text-primary me-2" name="info"></i-feather>
  Datos de la bici
</h5>
<h6 class="card-subtitle mb-2 text-muted"></h6>
<div class="row ms-md-4">
  <div class="col-12 col-md-6 mb-3">
    <small class="text-grey-dark fw-bold">Marca</small>
    <p class="card-text">{{informacionVehiculo?.dsMarca}}</p>
  </div>
  <div class="col-12 col-md-6 mb-3">
    <small class="text-grey-dark fw-bold">Línea</small>
    <p class="card-text">{{informacionVehiculo?.dsLinea}}</p>
  </div>
  <div class="col-12 col-md-6 mb-3">
    <small class="text-grey-dark fw-bold">Modelo</small>
    <p class="card-text">{{informacionVehiculo?.modelo}}</p>
  </div>
  <div class="col-12 col-md-6 mb-3">
    <small class="text-grey-dark fw-bold">Tipo de bicicleta</small>
    <p class="card-text">{{informacionVehiculo?.cdTipoServicio}}</p>
  </div>
</div>