<form  [formGroup]="formulario"  class="row g-3 mt-4">
  <div [class]="item.col" *ngFor="let item of listaFormulario" class="pe-4">
    <div *ngIf="item.type != 'radio' && item.type != 'select' && item.type != 'date'" class="form-floating">
      <input 
      (keydown)="validarInput($event, item.type)"
      id="input-{{item.formControlName}}"  
      [formControlName]="item.formControlName"
      [type]="item.type" placeholder="item.placeholder"
      class="form-control" 
      > 
      <label for="input-{{item.formControlName}}" class="form-label">{{item.label}}</label>
    </div>
    <div *ngIf="item.type == 'date'"  class="d-flex flex-column">
      <label id="label-{{item.formControlName}}" for="input-{{item.formControlName}}" class="form-label label-select">{{item.label}}</label>
      <p-calendar ngDefaultControl 
                  class="d-grid mt-1" 
                  inputStyleClass="form-control" 
                  ariaLabelledBy="label-{{item.formControlName}}" 
                  dateFormat="dd/mm/yy" 
                  inputId="{{item?.formControlName}}" 
                  [type]="item?.type"  
                  [formControlName]="item.formControlName"
                  placeholder="dd/mm/yyyy" 
                  monthNavigator="true" 
                  yearNavigator="true"
                  [maxDate]="maxDate"
                  [yearRange]="yearRange"
      ></p-calendar>
    </div>
    <div *ngIf="item.type == 'radio'">
      <label for="input-{{item.formControlName}}" class="form-label w-100">{{item.label}}</label>
      <div class="form-check form-check-inline" *ngFor="let op of item.options">
        <input 
          id="input-{{item.formControlName}}-{{op.descripcion}}"
          [formControlName]="item.formControlName"
          class="form-check-input" 
          [value]="op.codigo | lowercase"
          type="radio">
        <label class="form-check-label" for="flexRadioDefault1">
          {{op.descripcion | titlecase}}
        </label>
      </div>       
    </div>
    <div *ngIf="item.type == 'select'">
      <label for="input-{{item.formControlName}}" id="{{item.formControlName}}" class="form-label label-select">{{item.label}}</label>
      <ng-select 
          ngDefaultControl
          [formControlName]="item.formControlName"
          id="item.formControlName"
          [items]="item.opciones"
          bindLabel="descripcion" 
          bindValue="id"
          placeholder="Seleccionar"
          searchable="true"
          notFoundText="No hay datos"
          aria-labelledby="item.formControlName">
      </ng-select>
    </div>  
    <div *ngIf="item.type == 'checkbox'">
      <div class="custom-control custom-checkbox custom-checkbox-green">
        <input class="form-check-input custom-control-input custom-control-input-green" [formControlName]="item.formControlName"
         type="checkbox" id="input-{{item.formControlName}}">
        <label class="custom-control-label" for="input-{{item.formControlName}}"></label>
        <span class="ps-2 t-center d-inline-block" > He leído y acepto la <u class="cursor-pointer" (click)="openModal()">política de tratamiento de datos</u> , así como los término y condiciones.</span> 
        
      </div>
    </div>
      <app-msg-error 
        [messages]="item?.errors"
        [control]="formulario.controls[item.formControlName]"
        ></app-msg-error>
  </div>
</form>

<app-botones-onboarding
(eventoVolver)="volver($event)"
[activarBotonVolver]="false"
(eventoSiguiente)="submit()"
[desactivarBoton]="desactivarBoton"
></app-botones-onboarding>