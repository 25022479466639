import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

import { GeneralCoreService } from 'src/app/core/services/general-core/general-core.service';
import { OnboardingService } from 'src/app/onboarding/services/onboarding/onboarding.service';
import { ParametricosService } from 'src/app/onboarding/services/parametricos/parametricos.service';

import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { OnBoardingAutos } from 'src/app/onboarding/models/onboarding.model';
import { OnBoardingSoat } from '../../models/onboardingSoat.model';
import { InformacionTomadorForm } from './informacion-tomador.form';
import { BotonesOnboardingComponent } from '../botones-onboarding/botones-onboarding.component';
import { MsgErrorComponent } from '../../../shared/components/msg-error/msg-error.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CalendarModule } from 'primeng/calendar';
import { NgFor, NgIf, LowerCasePipe, TitleCasePipe } from '@angular/common';
@Component({
    selector: 'app-informacion-tomador',
    templateUrl: './informacion-tomador.component.html',
    styleUrls: ['./informacion-tomador.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgFor,
        NgIf,
        CalendarModule,
        NgSelectModule,
        MsgErrorComponent,
        BotonesOnboardingComponent,
        LowerCasePipe,
        TitleCasePipe,
    ],
})
export class InformacionTomadorComponent implements OnInit {
  @Output() eventoVolver: EventEmitter<any> = new EventEmitter();
  @Output() eventoGuardar: EventEmitter<any> = new EventEmitter();

  estadoCoberturas = false;
  notifier = new Subject();

  formulario: UntypedFormGroup;
  listaFormulario: any[] = [];
  generos: any[] = [];
  tiposDocumento: any[] = [];
  dataLocalFormulario: OnBoardingAutos;
  dataLocalFormularioSoat: OnBoardingSoat;
  maxDate: Date;
  yearRange: string;
  notFoundText = 'No hay datos';
  desactivarBoton = false;
  url = 'Movilidad';

  constructor(
    private onboardingServices: OnboardingService,
    private generalCoreService: GeneralCoreService,
    private parametricosService: ParametricosService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.yearRange = '';
    this.listaFormulario = InformacionTomadorForm.getForm();
    this.maxDate = new Date();
  }

  ngOnInit(): void {
    this.formulario = this.crearFormulario();
    this.obtenerGenero();
    this.obtenerTipoDocumento();
    this.obtenerTipoDireccion();
    this.obtenerCiudades();
    const minYear = new Date().getFullYear() - 70;
    const maxYear = new Date().getFullYear();
    this.yearRange = minYear + ':' + maxYear;
    this.cargarDatosFormulario();
    window.history.forward();
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }

  private cargarDatosFormulario(): void {
    this.dataLocalFormulario = new OnBoardingAutos(
      JSON.parse(localStorage.getItem('onboarding'))
    );

    if (this.dataLocalFormulario && this.dataLocalFormulario.datosTomador) {
      for (const key in this.dataLocalFormulario.datosTomador) {
        if (key === 'fechaNacimiento') {
          this.formulario.controls[key].setValue(
            new Date(this.dataLocalFormulario.datosTomador[key])
          );
        } else {
          this.formulario.controls[key].setValue(
            this.dataLocalFormulario.datosTomador[key]
          );
        }
      }
    }
  }

  private crearFormulario(): UntypedFormGroup {
    const formulario = new UntypedFormGroup({});
    this.listaFormulario.forEach((element) => {
      formulario.addControl(
        element.formControlName,
        new UntypedFormControl(
          {
            value: element.value,
            disabled: element.disabled,
          },
          element.validations
        )
      );
    });

    return formulario;
  }

  public tieneCoberturas() {
    this.dataLocalFormulario = new OnBoardingAutos(
      JSON.parse(localStorage.getItem('onboarding'))
    );
    const coberturas = Object.keys(
      this.dataLocalFormulario?.datosCobertura || {}
    ).filter((key) => !!this.dataLocalFormulario.datosCobertura[key]);
    return coberturas.length > 0;
  }

  submit(): void {
    if (this.formulario.invalid) {
      this.formulario.markAllAsTouched();
    } else if (
      !this.generalCoreService.estadoCoberturas &&
      this.dataLocalFormulario.datosPlanes.length !== 0
    ) {
      this.generalCoreService.openSpinner(true);
      this.generalCoreService.estadoCoberturas$
        .pipe(takeUntil(this.notifier))
        .subscribe((resp) => {
          if (resp) {
            this.generalCoreService.openSpinner(false);
            this.validarPlanesOCoberturas();
          }
        });
    } else {
      this.validarPlanesOCoberturas();
    }
  }

  validarPlanesOCoberturas() {
    if (this.dataLocalFormulario.datosPlanes.length && this.tieneCoberturas()) {
      const data: any = {
        siguienteComponente: 'datosPlanes',
        datosTomador: this.formulario.getRawValue(),
        sarlaft: '',
      };
      this.guardarDatosTomador(data);
    } else {
      this.onboardingServices
        .cotizacionTomador({
          tuCotizacionId: this.dataLocalFormulario.idCotizacion,
          ...this.formulario.getRawValue(),
        })
        .subscribe((resp) => {
          this.generalCoreService.openDialog({
            titulo: 'Cotización enviada',
            mensaje: `Hemos enviado el detalle del plan seleccionado al correo <b>${this.formulario?.controls.email.value}</b>, en unos minutos uno de nuestros asesores se comunicará contigo para continuar con el proceso.`,
            tipo: 'success',
            hasCancelButton: false,
            callbackConfirm: () => {
              localStorage.removeItem('onboarding');
              this.router.navigate(['onboarding/autos']);
              window.location.reload();
            },
          });
        });
    }
  }

  obtenerGenero(): void {
    this.parametricosService
      .obtenerParametricas('Genero')
      .subscribe((resp: any) => (this.generos = resp));
  }

  obtenerTipoDocumento(): void {
    this.parametricosService
      .obtenerParametricas('TipoDocumento', this.url)
      .subscribe((resp: any) => {
        this.listaFormulario.forEach((el) => {
          if (el.formControlName === 'tipoDoumento') {
            el.opciones = resp.filter(
              (documento) => documento.tipoPersona === 'N'
            );
          }
        });
      });
  }

  obtenerTipoDireccion(): void {
    this.parametricosService
      .obtenerParametricas('TipoDireccion')
      .subscribe((resp: any) => {
        this.listaFormulario.forEach((el) => {
          if (el.formControlName === 'tipoDireccion') {
            el.opciones = resp;
          }
        });
      });
  }

  guardarDatosTomador(data: any): void {
    this.desactivarBoton = true;
    const validacionEstadoCoberturas = new Observable((observer) => {
      let tempSpinner = false;

      const ciclo = setInterval(() => {
        this.dataLocalFormulario = new OnBoardingAutos(
          JSON.parse(localStorage.getItem('onboarding'))
        );
        if (this.dataLocalFormulario.datosCobertura) {
          clearInterval(ciclo);
          observer.next(true);
        } else {
          if (!tempSpinner) {
            this.generalCoreService.openSpinner(true);
            tempSpinner = true;
          }
          observer.next(false);
        }
      }, 500);
    });

    validacionEstadoCoberturas.subscribe((resp) => {
      if (resp) {
        this.ejecutarObtenerTarifas(data);
        this.desactivarBoton = false;
      }
    });
  }

  private ejecutarObtenerTarifas(data: any): void {
    this.onboardingServices
      .cotizacionTomador({
        tuCotizacionId: this.dataLocalFormulario.idCotizacion,
        ...this.formulario.getRawValue(),
      })
      .subscribe((resp: any) => {
        this.obtenerTarifas().subscribe(
          (respTarifa) => {
            const existeTarifa = Object.keys(respTarifa).find(
              (key) => respTarifa[key] !== null
            );
            if (respTarifa && existeTarifa) {
              data.datosCotizacion = resp.data;
              data.datosTarifa = respTarifa;
              data.sarlaft = resp.data.estado;
              this.eventoGuardar.emit(data);
            } else {
              this.generalCoreService.openDialog({
                titulo: 'Cotización enviada',
                mensaje: `Hemos enviado el detalle del plan seleccionado al correo <b>${this.formulario?.controls?.email.value}</b>, en unos minutos uno de nuestros asesores se comunicará contigo para continuar con el proceso.`,
                tipo: 'success',
                hasCancelButton: false,
                callbackConfirm: () => {
                  localStorage.removeItem('onboarding');
                  this.router.navigate(['onboarding/autos']);
                  window.location.reload();
                },
              });
            }
          },
          (error) => {}
        );
      });
  }

  obtenerCiudades(): void {
    this.parametricosService
      .obtenerParametricas('Ciudades', this.url)
      .subscribe((resp: any) => {
        this.listaFormulario.forEach((el) => {
          if (el.formControlName === 'codMunicipio') {
            el.opciones = resp;
          }
        });
      });
  }

  private obtenerTarifas(): Observable<any> {
    return new Observable((observer) => {
      this.dataLocalFormulario = new OnBoardingAutos(
        JSON.parse(localStorage.getItem('onboarding'))
      );
      const requestFork: any = {};
      const responseFork: any = {};

      if (
        this.dataLocalFormulario.datosCotizacion &&
        this.dataLocalFormulario?.datosTarifa &&
        this.dataLocalFormulario?.datosTarifa.length ===
          this.dataLocalFormulario?.datosCobertura.length
      ) {
        observer.next(this.dataLocalFormulario.datosTarifa);
      } else {
        // tslint:disable-next-line: forin
        this.dataLocalFormulario.datosPlanes.forEach((element) => {
          if (
            this.dataLocalFormulario.datosCobertura[element.codigoPlan]?.data
          ) {
            const dataRequest = {
              tuCotizacionId: this.dataLocalFormulario.idCotizacion,
              codigoPlan: element.codigoPlan,
              GrupoCoberturas:
                this.dataLocalFormulario.datosCobertura[element.codigoPlan]
                  .data,
            };
            requestFork[element.codigoPlan] =
              this.onboardingServices.cotizacionTarifa(dataRequest);
            responseFork[element.codigoPlan] = {};
          }
        });

        forkJoin(requestFork).subscribe(
          (resultFork: any) => {
            observer.next(resultFork);
          },
          (err) => {
            observer.error(true);
          }
        );
      }
    });
  }

  volver(data: any): void {
    this.eventoVolver.emit({ componente: 'datosVehiculo' });
  }

  validarInput(event: any, tipoCampo: string): void {
    if (tipoCampo === 'number' && event.code === 'Period') {
      event.preventDefault();
    }

    if (tipoCampo === 'text') {
      if (event.code === 'Period' || event.code === 'Space') {
        event.preventDefault();
      }
    }
  }
}
