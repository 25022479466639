import { Validators } from '@angular/forms';
import { patronValidacion } from 'src/app/onboarding/enums/validaciones';
import { Validation } from 'src/app/onboarding/utils/validation';

export class InformacionTomadorAccidentesForm {
  static getForm(): any {
    return [
      {
        label: 'Tipo de documento *',
        value: null,
        disabled: false,
        readonly: false,
        formControlName: 'tipoDocumento',
        placeholder: '',
        opciones: [],
        bindValue: 'codigo',
        bindLabel: 'descripcion',
        type: 'select',
        col: 'col-md-6',
        validations: [Validators.required],
        errors: [
          { error: 'required', message: 'Campo requerido' }
        ],
      },
      {
        label: 'Número de documento *',
        value: '',
        disabled: false,
        readonly: false,
        formControlName: 'numeroDocumento',
        placeholder: '',
        type: 'number',
        col: 'col-md-6',
        validations: [Validators.required, Validators.minLength(6), Validators.maxLength(10), Validators.pattern(patronValidacion.soloNumeros)],
        errors: [
          { error: 'required', message: 'Campo requerido' },
          { error: 'minlength', message: 'Mínimo debe tener más de 6 caracteres' },
          { error: 'maxlength', message: 'Debe tener  máximo 10 caracteres' }
        ],
      },
      {
        label: 'Nombres *',
        value: '',
        disabled: false,
        readonly: false,
        formControlName: 'nombres',
        placeholder: '',
        type: 'text',
        col: 'col-md-6',
        validations: [Validators.required, Validators.pattern(patronValidacion.soloTexto), Validators.minLength(2)],
        errors: [
          { error: 'required', message: 'Campo requerido' },
          { error: 'pattern', message: 'No se aceptan números o caracteres especiales' },
          { error: 'minlength', message: 'Debe tener más de dos letras' }
        ],
      },
      // {
      //   label: 'Segundo nombre',
      //   value: '',
      //   disabled: false,
      //   readonly: false,
      //   formControlName: 'segundoNombre',
      //   placeholder: '',
      //   type: 'text',
      //   col: 'col-md-6',
      //   validations: [Validators.pattern(patronValidacion.soloTexto), Validators.minLength(2)],
      //   errors: [
      //     { error: 'pattern', message: 'No se aceptan números o caracteres especiales' },
      //     { error: 'minlength', message: 'Debe tener más de dos letras' }
      //   ],
      // },
      {
        label: 'Primer apellido *',
        value: '',
        disabled: false,
        readonly: false,
        formControlName: 'primerApellido',
        placeholder: '',
        type: 'text',
        col: 'col-md-6',
        validations: [Validators.required, Validators.pattern(patronValidacion.soloTexto), Validators.minLength(2)],
        errors: [
          { error: 'required', message: 'Campo requerido' },
          { error: 'pattern', message: 'No se aceptan números o caracteres especiales' },
          { error: 'minlength', message: 'Debe tener más de dos letras' }
        ],
      },
      {
        label: 'Segundo apellido',
        value: '',
        disabled: false,
        readonly: false,
        formControlName: 'segundoApellido',
        placeholder: '',
        type: 'text',
        col: 'col-md-6',
        validations: [Validators.pattern(patronValidacion.soloTexto), Validators.minLength(2)],
        errors: [
          { error: 'pattern', message: 'No se aceptan números o caracteres especiales' },
          { error: 'minlength', message: 'Debe tener más de dos letras' }
        ],
      },
      {
        label: 'Género *',
        value: '',
        disabled: false,
        readonly: false,
        formControlName: 'sexo',
        placeholder: '',
        type: 'radio',
        col: 'col-md-6',
        validations: [Validators.required],
        errors: [
          { error: 'required', message: 'Campo requerido' }
        ],
        options: [
          {
            codigo: 'F',
            descripcion: 'femenino'
          },
          {
            codigo: 'M',
            descripcion: 'masculino'
          }
        ]
      },
      {
        label: 'Fecha de nacimiento',
        value: "",
        disabled: false,
        readonly: false,
        formControlName: 'fechaNacimiento',
        placeholder: '',
        type: 'date',
        col: 'col-md-6',
        validations: [Validators.required, Validation.rangeDateAccidentes],
        errors: [
          { error: 'required', message: 'Campo requerido' },
          { error: 'rangeDateAccidentes', message: 'Tiene que ser mayor de 18 años y menor de 70 años' }
        ]
      },
      {
        label: 'Celular *',
        value: '',
        disabled: false,
        readonly: false,
        formControlName: 'numeroCelular',
        placeholder: '',
        type: 'number',
        col: 'col-md-6',
        validations: [Validators.required, Validators.minLength(10), Validators.maxLength(10)],
        errors: [
          { error: 'required', message: 'Campo requerido' },
          { error: 'minlength', message: 'Debe tener al menos 10 caracteres' },
          { error: 'maxlength', message: 'Debe tener  máximo 10 caracteres' }
        ],
      },
      {
        label: 'Correo electrónico *',
        value: '',
        disabled: false,
        readonly: false,
        formControlName: 'email',
        placeholder: '',
        type: 'email',
        col: 'col-md-6',
        validations: [Validators.required, Validators.pattern(patronValidacion.email)],
        errors: [
          { error: 'required', message: 'Campo requerido' },
          { error: 'pattern', message: 'Formato incorrecto' }
        ],
      },
      {
        label: 'Departamento *',
        value: null,
        disabled: false,
        readonly: false,
        visible: true,
        formControlName: 'codDepartamento',
        placeholder: '',
        type: 'select',
        col: 'col-md-6',
        opciones: [],
        bindValue: 'codDaneDepartamento',
        bindLabel: 'departamento',
        validations:  [ Validators.required ],
        errors: [
          { error: 'required', message: 'Campo requerido'}
        ],
      },
      {
        label: 'Ciudad *',
        value: null,
        disabled: true,
        readonly: false,
        visible: true,
        formControlName: 'codMunicipio',
        placeholder: '',
        type: 'select',
        col: 'col-md-6',
        opciones: [],
        bindValue: 'codDaneMunicipio',
        bindLabel: 'municipio',
        validations:  [ Validators.required ],
        errors: [
          { error: 'required', message: 'Campo requerido'}
        ],
      },
      {
        label: 'Dirección de residencia',
        value: '',
        disabled: false,
        readonly: false,
        formControlName: 'direccion',
        placeholder: '',
        type: 'email',
        col: 'col-md-6',
        validations: [Validators.required],
        errors: [
          { error: 'required', message: 'Campo requerido' }
        ],
      }
    ];
  }
}
