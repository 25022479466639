import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/sesion/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthOnboardingGuard  {

  constructor(private authService: AuthService, private router: Router){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let validaSession = false;

      const dataSession = this.authService.getSession();

      if (dataSession && dataSession?.token){
        validaSession = true;

        // if (this.authService.authUrl.current === '' && this.authService.authUrl.next === ''){
        //   validaSession = true;
        // } else {
        //   if(this.authService.authUrl.next === '/login'){
        //     this.authService.authUrl.current = '';
        //     this.authService.authUrl.next = '';
        //   }
        //   validaSession = true;
        // }
      }else {
        return new Observable<boolean>((observer) => {
          this.authService.onboardingUser().subscribe( resp => {
            this.authService.setSession(resp.data);
            observer.next(true);
           });
        });
      }
      return validaSession;
  }
  
}
