import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { GeneralCoreService } from 'src/app/core/services/general-core/general-core.service';
import { LoginModel } from '../../models/login.model';
import { AuthService } from '../../services/auth/auth.service';
import { patronValidacion } from 'src/app/onboarding/enums/validaciones';
import { FeatherModule } from 'angular-feather';
import { NgIf } from '@angular/common';
import { MsgErrorComponent } from '../../../shared/components/msg-error/msg-error.component';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, MsgErrorComponent, NgIf, FeatherModule, RouterLink]
})
export class LoginComponent implements OnInit {

  formulario: UntypedFormGroup;
  mensajes = {};
  tipoInput = 'password';

  constructor(
    private authService: AuthService,
    private route: Router,
    private generalCoreService: GeneralCoreService,
  ) { }

  ngOnInit(): void {
    this.crearFormulario();
    this.crearMensajes();
  }

  crearFormulario(): void {
    this.formulario = new UntypedFormGroup({
      passWord: new UntypedFormControl('', [Validators.required]),
      userName: new UntypedFormControl('', [Validators.required, Validators.pattern(patronValidacion.email)])
    });

  }

  crearMensajes(): void {
    this.mensajes = {
      userName: [
        { error: 'required', message: 'Campo requerido' },
        { error: 'pattern', message: 'Formato incorrecto' }
      ],
      passWord: [
        { error: 'required', message: 'Campo requerido' }
      ]
    };
  }

  submit(): void{
    if (this.formulario.invalid) {
      this.formulario.markAllAsTouched();
    } else {
      this.authService.login(new LoginModel(this.formulario.getRawValue())).subscribe( () => {
        if(this.authService.currentUrlIsPlanes()){
          try {
            this.route.navigateByUrl(this.authService.authUrl.current);
          } catch (error) {
          }
        }else{
          this.route.navigate(['/perfil']);
        }
      }, (error) => {
        if (error.error?.message?.includes('Tu cuenta no se encuentra activa')) {
          this.mostrarModal(`Tu cuenta no se encuentra activa, si deseas activarla solicita el código de activación`)
        } else {
          this.generalCoreService.openDialog({
            mensaje: error.error?.message,
            tipo: 'error',
            hasCancelButton: true,
          });
        }
      });
    }
  }

  mostrarModal(mensaje) {
    this.generalCoreService.openDialog({
      titulo: 'Cuenta no activa',
      mensaje,
      tipo: 'warning',
      confirmButtonText: 'Solicitar código',
      cancelButtonText: 'Cancelar',
      hasCancelButton: true,
      callbackConfirm: () => {
        this.authService.enviarCodigoAlCorreo(this.formulario.value.userName).subscribe(resp =>{
          this.mostrarModalCodigo()
        })
      },
      callbackCancel: () => {

      }
    });
  }

  mostrarModalCodigo() {
    this.generalCoreService.openDialog({
      componente: 'otp',
      titulo: 'Código de verificación',
      mensaje: `Se ha enviado un código al correo ${this.formulario.value.userName}`,
      tipo: 'warning',
      confirmButtonText: 'Verificar código',
      cancelButtonText: 'Cancelar',
      hasCancelButton: true,
      callbackConfirm: (value) => {
        const data = {
          email: this.formulario.value.userName,
          codigo: Number(value.otp)
        }
        this.authService.verificarCodigo(data).subscribe( resp => {
          this.generalCoreService.openDialog({
            titulo: 'Felicidades',
            mensaje: resp.message,
            tipo: 'success',
            callbackConfirm: () => {
              this.route.navigate(['login']);
            },
            hasCancelButton: false
          });
        })
      }
    });
  }

  cambiarTipoInput(tipo) {
    this.tipoInput = tipo;
  }

}
