import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';

import { OnboardingAccidentes } from 'src/app/onboarding/models/onboardingAccidentes.model';

import { IpService } from 'src/app/shared/services/ip/ip.service';
import { AccidentesService } from 'src/app/onboarding/services/accidentes/accidentes.service';
import { AuthService } from 'src/app/sesion/services/auth/auth.service';
import { GeneralCoreService } from 'src/app/core/services/general-core/general-core.service';
import { environment } from 'src/environments/environment';
import { BotonesOnboardingComponent } from '../../botones-onboarding/botones-onboarding.component';
import { MsgErrorComponent } from '../../../../shared/components/msg-error/msg-error.component';
import { BottomSheetComponent } from '../../../../shared/components/bottom-sheet/bottom-sheet.component';
import { NgIf, NgFor, NgClass, TitleCasePipe, CurrencyPipe, DatePipe } from '@angular/common';
import { FeatherModule } from 'angular-feather';

@Component({
    selector: 'app-informacion-pago-accidentes',
    templateUrl: './informacion-pago-accidentes.component.html',
    styleUrls: ['./informacion-pago-accidentes.component.scss'],
    standalone: true,
    imports: [
        FeatherModule,
        NgIf,
        NgFor,
        BottomSheetComponent,
        NgClass,
        ReactiveFormsModule,
        MsgErrorComponent,
        BotonesOnboardingComponent,
        TitleCasePipe,
        CurrencyPipe,
        DatePipe,
    ],
})
export class InformacionPagoAccidentesComponent implements OnInit {
  @Output() eventoVolver: EventEmitter<any> = new EventEmitter();

  public idMetodo = 0;
  public selectedCover: any;
  public isActiveModal = false;
  public formulario: UntypedFormGroup;
  public data: OnboardingAccidentes;
  public datosTomador: any;
  public datosAsegurado: any;
  public dataSession: any;
  public session: any;
  public form = new UntypedFormGroup({
    celularCoink: new UntypedFormControl('', [
      Validators.required,
      Validators.min(1000000000),
      Validators.max(9999999999),
    ]),
  });
  public errorsCelularCoink = [
    { error: 'required', message: 'Campo requerido' },
    { error: 'min', message: 'Debe tener al menos 10 caracteres' },
    { error: 'max', message: 'Debe tener  máximo 10 caracteres' },
  ];
  public coberturaPrincipal = {
    url_img: "https://cliente-market.s3.amazonaws.com/assets/moto/cobertura-transporte.webp",
    titulo: "Muerte <b>accidental</b>",
    descripcion: "Ofrecemos un auxilio económico de $400,000 mil pesos durante 36 meses para tus beneficiarios, en caso de que tu o tu pareja fallezcan por muerte accidental se pagara ese valor. Recuerda que este seguro te da el beneficio de asegurarte a ti y a tu pareja por el mismo costo.",
    codigo: "MUE ACC",
    active: false
  }

  constructor(
    private ipService: IpService,
    private accidentesService: AccidentesService,
    private authService: AuthService,
    private generalCoreService: GeneralCoreService
  ) {}

  ngOnInit(): void {
    this.session = JSON.parse(localStorage.getItem('session'));
    this.formulario = new UntypedFormGroup({});
    this.data = new OnboardingAccidentes(
      JSON.parse(localStorage.getItem('onboardingAccidentes'))
    );
    this.datosTomador = this.data.tomador;
    this.datosAsegurado = this.data.asegurado;
  }

  public changeMetodo(id): void {
    this.idMetodo = id;
  }

  public cambiarTokenCliente(codigo = null) {
    const dataSession = this.authService.getSession();
    if (dataSession.roles[0] === 'Onbording') {
      this.authService
        .getTokenCliente({
          tuCotizacionId: this.data.tuCotizacionId,
          codigo: codigo,
        })
        .subscribe((resp) => {
          this.authService.setSession(resp.data);

          if (this.idMetodo === 1) {
            this.enviarPasarelaPago();
          }
          if (this.idMetodo === 2) {
            this.enviarPasarelaMundial(resp.data.token, this.data.tuCotizacionId);
          }
        });
    } else {
      if (this.idMetodo === 1) {
        this.enviarPasarelaPago();
      }
      if (this.idMetodo === 2) {
        this.enviarPasarelaMundial(this.session.token, this.data.tuCotizacionId);
      }
    }
  }

  public existeUsuario() {
    this.accidentesService
      .existeUsuario(this.data.tuCotizacionId)
      .subscribe((resp) => {
        if (resp.data) {
          this.generalCoreService.openDialog({
            titulo: 'Usuario registrado',
            mensaje: resp.message,
            tipo: 'warning',
            confirmButtonText: 'Si, vincular',
            cancelButtonText: 'No, cancelar',
            hasCancelButton: true,
            callbackConfirm: () => {
              this.enviarCodigoEmail();
            },
            callbackCancel: () => {
              this.cambiarTokenCliente();
            },
          });
        } else {
          this.cambiarTokenCliente();
        }
      });
  }

  public enviarCodigoEmail() {
    this.accidentesService
      .enviarCodigoEmail(this.data.tuCotizacionId)
      .subscribe((resp) => {
        this.generalCoreService.openDialog({
          componente: 'otp',
          titulo: 'Código de verificación',
          mensaje: `Se ha enviado un código al correo ${this.data.tomador.email}`,
          tipo: 'warning',
          confirmButtonText: 'Verificar código',
          cancelButtonText: 'Cancelar',
          hasCancelButton: true,
          callbackConfirm: (value) => {
            this.cambiarTokenCliente(value.otp);
          },
        });
      });
  }

  public enviarPasarelaPago() {
    this.accidentesService
      .ejecutarPagoCoink({
        tuCotizacionId: this.data.tuCotizacionId,
        numeroCelular: this.form.controls.celularCoink.value.toString(),
        ipAddr: this.ipService.getIpCliente(),
      })
      .subscribe((resp) => {
        this.generalCoreService.openSpinner(true);
        this.dataSession = JSON.parse(localStorage.getItem('session'));
        if (this.dataSession.roles[0] === 'OnbordingCliente') {
          localStorage.removeItem('session');
        }
        localStorage.removeItem('onboardingAccidentes');
        window.open(resp.data.urlPago, '_self');
      });
  }

  public enviarPasarelaMundial(token: string, idCotizacion:number) {
    this.dataSession = JSON.parse(localStorage.getItem('session'));
    if (this.dataSession.roles[0] === 'OnbordingCliente') {
      localStorage.removeItem('session');
      localStorage.clear()
    }
    this.generalCoreService.openSpinner(true);
    localStorage.removeItem('onboardingAccidentes');
    const API = environment.api;
    window.open(
      `${API.url}/api/v1/pasarelaspagos/Mundial/Pagos/${ this.accidentesService.codigoToken }`,
      '_self'
    );
  }

  public irAPagar(idMetodo) {
    if (this.session.roles[0] == 'Onbording') {
      this.existeUsuario();
    } else {
      if (idMetodo === 1) {
        this.enviarPasarelaPago();
      }
      if (this.idMetodo === 2) {
        this.enviarPasarelaMundial(this.session.token, this.data.tuCotizacionId);
      }
    }
  }

  public verDetalle(cover): void {
    this.selectedCover = cover;
    this.isActiveModal = true;
  }

  public closeBottomSheet(): void {
    this.isActiveModal = false;
  }

  volver(): void {
    this.eventoVolver.emit({ componente: 'datosTomador' });
  }
}
