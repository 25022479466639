import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FeatherModule } from 'angular-feather';

@Component({
    selector: 'app-resultado-generico',
    templateUrl: './resultado-generico.component.html',
    styleUrls: ['./resultado-generico.component.scss'],
    standalone: true,
    imports: [FeatherModule, RouterLink]
})
export class ResultadoGenericoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
