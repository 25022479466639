import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { GeneralHttpService } from 'src/app/shared/services/general-http/general-http.service';
import { environment } from 'src/environments/environment';

const DATA_TEST = environment.informacionPrueba;

@Injectable({
  providedIn: 'root'
})
export class SoatService {
  $emitter = new EventEmitter();
  
  constructor(private http: GeneralHttpService) { }

  consultarPlacaSoat(data: any): Observable<any>{
    if (DATA_TEST){
      return this.http.json(`assets/json/soat.json`);
    } else {
      const codigoPartner = localStorage.getItem('partner');
      data["CodigoPartner"] = codigoPartner;
      return this.http.post(`api/v1/sura/soat/Cotizar/Placa`, data);
    }
  }

  obtenerDatosRunt(parametro: string, idMarca?:number): Observable<any> {
    if (idMarca) {
      return this.http.get(`api/v1/sura/soat/vehiculo/marca/${idMarca}/${parametro}`);
    } else {
      return this.http.get(`api/v1/sura/soat/${parametro}`);
    }
  }

  enviarDatosTomadorSoat(data: any): Observable<any> {
    return this.http.post(`api/v1/sura/soat/Cotizar/Tomador`, data)
  }

  obtenerCiudades(idDepartamento: number): Observable<any> {
    return this.http.get(`api/v1/sura/soat/ciudades/Departamento/${idDepartamento}`)
  }

  emitirEvento() {
      this.$emitter.emit();
  }   

}
