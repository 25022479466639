<div class="card h-100">
  <div class="card-body px-4 text-center" *ngIf="plan">
    <h4 class="fw-light" [ngClass]="getColors(plan.codigo, 'text')">{{plan.nombre}}</h4>
    <h1 class="text-primary fw-bold mb-0">{{plan.valor | currency:'$':'code':'1.0':'en'}}</h1>
    <span class="text-primary">anual</span>
    <div class="d-grid my-4">
      <button type="button" class="btn btn-block" [ngClass]="getColors(plan.codigo, 'btn')" (click)="redirect(plan)">Seleccionar
        <i-feather class="text-white ms-1" name="arrow-right"></i-feather>
      </button>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-start text-start" *ngFor="let cobertura of plan.coberturas">
        <i-feather class="text-success me-2 d-flex" name="check"></i-feather>
        <p class="fw-light">{{cobertura.tituloGrupo}}</p>
      </div>
    </div>
    <div class="mt-3">
      <!-- <a (click)="sendPlan(plan)" class="card-link text-decoration-none cursor-pointer">Ajusta tu plan</a> -->
    </div>
  </div>
</div>