import { Validators } from '@angular/forms';
import { patronValidacion } from 'src/app/onboarding/enums/validaciones';
import { SoatService } from 'src/app/onboarding/services/soat/soat.service';
import { Validation } from 'src/app/onboarding/utils/validation';


export class InformacionSoatForm {

  constructor(private soatService: SoatService){}

  static getForm(): any {
    return {
      placa: {
        label: '',
        value: '',
        col: 'col-12',
        disabled: false,
        readonly: false,
        visible: true,
        formControlName: 'placa',
        placeholder: '',
        type: 'text',
        validations: [Validators.required, Validators.pattern(patronValidacion.alfanumerico)],
        errors: [
          { error: 'required', message: 'Campo requerido' },
          { error: 'pattern', message: 'No se aceptan caracteres especiales' }
        ],
      }
    };
  }

  static getFormRunt(): any {
    return {
      capacidadCarga: {
        label: 'Capacidad de carga',
        value: '',
        col: 'col-12',
        disabled: false,
        readonly: false,
        visible: true,
        formControlName: 'capacidadCarga',
        placeholder: '',
        type: 'text',
        validations: [Validators.required],
        errors: [
          { error: 'required', message: 'Campo requerido' }
        ],
      },
      cilindraje: {
        label: 'Cilindraje',
        value: '',
        col: 'col-12',
        disabled: false,
        readonly: false,
        visible: true,
        formControlName: 'cilindraje',
        placeholder: '',
        type: 'text',
        validations: [Validators.required, Validators.pattern(patronValidacion.soloNumeros)],
        errors: [
          { error: 'required', message: 'Campo requerido' },
          { error: 'pattern', message: 'Se aceptan solo números' },
        ],
      },
      idClaseVehiculo: {
        label: 'Clase de Vehiculo',
        value: null,
        col: 'col-12',
        disabled: false,
        readonly: false,
        visible: true,
        formControlName: 'idClaseVehiculo',
        placeholder: '',
        opciones: [],
        bindValue: 'idClaseVehiculo',
        bindLabel: 'nombreClaseVehiculo',
        type: 'select',
        servicio: 'vehiculoclase',
        validations: [Validators.required],
        errors: [
          { error: 'required', message: 'Campo requerido' }
        ],
      },
      idTipoCombustible: {
        label: 'Tipo de Combustible',
        value: null,
        col: 'col-12',
        disabled: false,
        readonly: false,
        visible: true,
        formControlName: 'idTipoCombustible',
        placeholder: '',
        opciones: [],
        bindValue: 'idTipoCombustible',
        bindLabel: 'tipoCombustible',
        type: 'select',
        servicio: 'vehiculo/combustible',
        validations: [Validators.required],
        errors: [
          { error: 'required', message: 'Campo requerido' }
        ],
      },
      idTipoServicio: {
        label: 'Tipo de Servicio',
        value: null,
        col: 'col-12',
        disabled: false,
        readonly: false,
        visible: true,
        formControlName: 'idTipoServicio',
        placeholder: '',
        opciones: [],
        bindValue: 'idTipoServicio',
        bindLabel: 'tipoServicio',
        type: 'select',
        servicio: 'VehiculoUso',
        validations: [Validators.required],
        errors: [
          { error: 'required', message: 'Campo requerido' }
        ],
      },
      modelo: {
        label: 'Modelo',
        value: '',
        col: 'col-12',
        disabled: false,
        readonly: false,
        visible: true,
        formControlName: 'modelo',
        placeholder: '',
        type: 'text',
        validations: [Validators.required],
        errors: [
          { error: 'required', message: 'Campo requerido' }
        ],
      },
      numeroPasajeros: {
        label: 'Número de Pasajeros',
        value: '',
        col: 'col-12',
        disabled: false,
        readonly: false,
        visible: true,
        formControlName: 'numeroPasajeros',
        placeholder: '',
        type: 'text',
        validations: [Validators.required],
        errors: [
          { error: 'required', message: 'Campo requerido' }
        ],
      },
      idMarca: {
        label: 'Marca',
        value: null,
        col: 'col-12',
        disabled: false,
        readonly: false,
        visible: true,
        formControlName: 'idMarca',
        placeholder: '',
        opciones: [],
        bindValue: 'idMarcaVehiculo',
        bindLabel: 'nombreMarcaVehiculo',
        type: 'select',
        servicio:'vehiculo/marcas',
        validations: [Validators.required],
        errors: [
          { error: 'required', message: 'Campo requerido' }
        ],
      },
      idLinea: {
        label: 'Linea',
        value: null,
        col: 'col-12',
        disabled: false,
        readonly: false,
        visible: true,
        formControlName: 'idLinea',
        placeholder: '',
        opciones: [],
        bindValue: 'idLineaVehiculo',
        bindLabel: 'nombreLineaVehiculo',
        type: 'select',
        servicio: 'lineas',
        validations: [Validators.required],
        errors: [
          { error: 'required', message: 'Campo requerido' }
        ],
      },
      numeroMotor: {
        label: 'Número de Motor',
        value: '',
        col: 'col-12',
        disabled: false,
        readonly: false,
        visible: true,
        formControlName: 'numeroMotor',
        placeholder: '',
        type: 'text',
        validations: [Validators.required],
        errors: [
          { error: 'required', message: 'Campo requerido' }
        ],
      },
      vin: {
        label: 'Vin',
        value: '',
        col: 'col-12',
        disabled: false,
        readonly: false,
        visible: true,
        formControlName: 'vin',
        placeholder: '',
        type: 'text',
        validations: [Validators.required],
        errors: [
          { error: 'required', message: 'Campo requerido' }
        ],
      },
      numeroChasis: {
        label: 'Número de Chasis',
        value: '',
        col: 'col-12',
        disabled: false,
        readonly: false,
        visible: true,
        formControlName: 'numeroChasis',
        placeholder: '',
        type: 'text',
        validations: [Validators.required],
        errors: [
          { error: 'required', message: 'Campo requerido' }
        ],
      },
      paisOrigenId: {
        label: 'País origen',
        value: '57',
        col: 'col-12',
        disabled: false,
        readonly: false,
        visible: false,
        formControlName: 'paisOrigenId',
        placeholder: '',
        type: 'text',
        validations: []
      },
    };
  }
}