<div class="my-5 text-center titulo">
    <h3 class="text-primary w-75 fw-light titulo__onboarding mx-auto mb-0" [innerHTML]="getPasoVisible().titulo"></h3>
    <small class="w-50" [innerHTML]="getPasoVisible().subtitulo"></small>
</div>

<div *ngFor="let item of pasosNew">
     <app-informacion-moto
        (eventoGuardar)="guardar($event)"
        *ngIf="item.visible && item.paso == 'datosVehiculo'"
        ></app-informacion-moto>
    <app-informacion-tomador-motos
        (eventoVolver)="volver($event)"
        (eventoGuardar)="guardar($event)"
        *ngIf="item.visible && item.paso == 'datosTomador'"
        ></app-informacion-tomador-motos>
    <app-informacion-planes
        (eventoVolver)="volver($event)"
        (eventoGuardar)="guardar($event)"
        *ngIf="item.visible && item.paso == 'datosPlanes'"
        ></app-informacion-planes>
    <app-informacion-coberturas
        (eventoVolver)="volver($event)"
        (eventoGuardar)="guardar($event)"
        *ngIf="item.visible && item.paso == 'datosCobertura'"
        ></app-informacion-coberturas>  
    <app-informacion-pago
        (eventoVolver)="volver($event)"
        (eventoGuardar)="guardar($event)"
        *ngIf="item.visible && item.paso == 'datosPago'"
        ></app-informacion-pago>
    <!-- TODO: porque este componente se llama si no existe o donde esta?  -->
    <!-- <app-informacion-finalizar
        (eventoVolver)="volver($event)"
        (eventoGuardar)="guardar($event)"
        *ngIf="item.visible && item.paso == 'datosFinalizar'"
        ></app-informacion-finalizar> -->
</div>