<!--Navbar Start-->
<nav
  class="navbar navbar-expand-lg fixed-top navbar-custom navbar-light"
  #navbar
  id="navbar"
  (window:scroll)="windowScroll()"
>
  <div class="container wrapper-nav">
    <!-- LOGO -->
    <div class="logo cursor-pointer">
      <img
        src="assets/images/logo.webp"
        alt="logo TuPrimero"
        [routerLink]="'/'"
        height="45"
        width="50"
      />
    </div>
    <div
      class="collapse navbar-collapse justify-content-end"
      id="navbarCollapse"
    >
      <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
        <li
          class="nav-item"
          [class.link-blue]="isLinkBlue"
          (click)="hideExtendedNavbar()"
        >
          <a
            class="nav-link"
            [routerLink]="['/']"
            routerLinkActive="router-link-active"
            >Nosotros</a
          >
        </li>
        <li
          class="nav-item insurance d-flex justify-content-between align-items-center"
          (click)="toggleExtendedNavbar(); indexCategoria = 0"
          id="insurance"
          [class.link-blue]="isLinkBlue"
        >
          <div class="nav-link pe-0">Tu Seguro</div>
          <i-feather
            class="icon text-white cursor-pointer"
            name="chevron-down"
          ></i-feather>
        </li>
        <!-- <li class="nav-item" [class.link-blue]="isLinkBlue" (click)="hideExtendedNavbar()">
          <a class="nav-link" [routerLink]="['/tailor-made']" routerLinkActive="router-link-active">Empresa</a>
        </li>
        <li class="nav-item" [class.link-blue]="isLinkBlue" (click)="hideExtendedNavbar()">
          <a class="nav-link" [routerLink]="['/agro']" routerLinkActive="router-link-active">Agro</a>
        </li> -->
        <li
          class="nav-item"
          [class.link-blue]="isLinkBlue"
          (click)="hideExtendedNavbar()"
        >
          <a
            class="nav-link"
            [routerLink]="['/blog']"
            routerLinkActive="router-link-active"
            >Noticias</a
          >
        </li>
        <li
          class="nav-item"
          [class.link-blue]="isLinkBlue"
          (click)="hideExtendedNavbar()"
        >
          <a
            class="nav-link"
            [routerLink]="['/contacto']"
            routerLinkActive="router-link-active"
            >Contacto</a
          >
        </li>
        <li
          class="nav-item insurance ms-3"
          *ngIf="estadoSession"
          (click)="toggleExtendedNavbar(null, false)"
          id="avatar"
        >
          <img
            src="assets/images/user.webp"
            width="40"
            height="40"
            class="rounded-circle cursor-pointer"
          />
        </li>
      </ul>
      <button
        class="btn btn-primary navbar-btn ms-3"
        routerLink="/login"
        *ngIf="!estadoSession"
      >
        Mi cuenta
      </button>
    </div>
  </div>

  <div class="extended-navbar" id="extended_navbar">
    <div class="p-4">
      <div class="row" *ngIf="isNavbarProduct">
        <div class="col" *ngFor="let category of categories">
          <p class="text-dark mb-3">{{ category.name }}</p>
          <div
            class="tab-content"
            id="v-pills-tabContent"
            *ngIf="categories.length"
          >
            <div
              *ngFor="let product of category.products"
              (click)="toggleExtendedNavbar(product)"
              class="text-decoration-none pe-2"
            >
              <app-item-product
                class="item cursor-pointer"
                [label]="product?.name"
                [color]="category.color"
                [urlIcon]="product?.icon"
                [textBlack]="true"
              ></app-item-product>
            </div>
          </div>
        </div>
      </div>

      <!-- Menu perfil -->
      <div class="row" *ngIf="!isNavbarProduct">
        <div class="col-4 pe-5 border-end">
          <h3 class="fw-light text-black">
            Gestiona todos tus seguros
          </h3>
        </div>
        <div class="col-5 pb-5">
          <app-item-product class="item cursor-pointer" *ngFor="let product of menuProfile.products" [label]="product?.name" color="primary"
          [urlIcon]="product?.icon" (click)="redirect(product.url)"></app-item-product>
        </div>
        <div class="col-3 cursor-pointer">
          <div class="d-flex h-100">
            <ul
              class="col nav flex-column justify-content-end d-flex nav-pills h-100 text-black"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <li
                class="nav-item text-start fs-4 fw-lighter"
                (click)="hideExtendedNavbar()"
              >
                <a class="nav-link px-0 text-primary" (click)="cerrarSession()"
                  >Cerrar sesión</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="overlay"
    (click)="closeExtendedNavbar()"
    *ngIf="isVisibleMenu"
  ></div>
</nav>
