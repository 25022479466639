import { AbstractControl } from '@angular/forms';
import moment from 'moment';

export class Validation {
  static rangeDate(control: AbstractControl): any {
    const value = control.value;

    const agePerson = moment(value);
    const months = moment(new Date()).diff(agePerson, 'months');

    if (months >= 192) {
      return null;
    }
    return { rangeDate: true };
  }

  static rangePricingBici(control: AbstractControl): any {
    const value = control.value;

    if (value >= 600000 && value <= 45000000) {
      return null;
    }
    return { rangePricingBici: true };
  }

  static rangePricingPatineta(control: AbstractControl): any {
    const value = control.value;

    if (value >= 600000 && value <= 10000000) {
      return null;
    }
    return { rangePricingPatineta: true };
  }

  static rangeDateAccidentes(control: AbstractControl): any {
    const value = control.value;

    const agePerson = moment(value);
    const months = moment(new Date()).diff(agePerson, 'months');

    if (months >= 216 && months <= 840) {
      return null;
    }
    return { rangeDateAccidentes: true };
  }
}
