import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/sesion/services/auth/auth.service';
import { ProductosService } from 'src/app/shared/services/productos/productos.service';
import { ItemProductComponent } from '../../../../shared/components/item-product/item-product.component';
import { NgFor, NgIf } from '@angular/common';
import { FeatherModule } from 'angular-feather';

@Component({
    selector: 'app-nav-mobile',
    templateUrl: './nav-mobile.component.html',
    styleUrls: ['./nav-mobile.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        FeatherModule,
        NgFor,
        ItemProductComponent,
        NgIf,
    ],
})
export class NavMobileComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public isDrawerActive = false;
  public estadoSession = false;
  public navList = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private productosService: ProductosService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    if (this.authService.getToken()) {
      this.estadoSession = true;
    }

    this.authService.estadoSession$
      .pipe(takeUntil(this.destroy$))
      .subscribe((resp) => {
        this.estadoSession = resp;
      });

    this.obtenerProducto();
  }

  obtenerProducto() {
    this.productosService.obtenerProductos().subscribe((resp) => {
      this.navList = resp;
    });
  }

  public toggleMenu(): void {
    const drawer = document.getElementById('drawer');
    drawer.classList.toggle('active');
    this.isDrawerActive = !this.isDrawerActive;
  }

  public redirect(item): void {
    this.router.navigateByUrl(item.url);
    this.toggleMenu();
  }

  cerrarSession(): void {
    this.authService.closeSession();
    this.router.navigate(['home']);
    localStorage.removeItem('urlDetalle');
  }
}
