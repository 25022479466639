import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';

import { InformacionTomadorSoatForm } from './informacion-tomador-soat.form';

import { GeneralCoreService } from 'src/app/core/services/general-core/general-core.service';
import { patronValidacion } from 'src/app/onboarding/enums/validaciones';
import { ParametricosService } from 'src/app/onboarding/services/parametricos/parametricos.service';
import { PasarelaService } from 'src/app/onboarding/services/pasarela/pasarela.service';
import { SoatService } from 'src/app/onboarding/services/soat/soat.service';
import { AuthService } from 'src/app/sesion/services/auth/auth.service';
import { IpService } from 'src/app/shared/services/ip/ip.service';

import { OnBoardingSoat } from 'src/app/onboarding/models/onboardingSoat.model';
import { Validation } from 'src/app/onboarding/utils/validation';
import { NgSelectModule } from '@ng-select/ng-select';
import { CalendarModule } from 'primeng/calendar';
import { MsgErrorComponent } from '../../../../shared/components/msg-error/msg-error.component';
import { NgFor, NgIf } from '@angular/common';
@Component({
    selector: 'app-informacion-tomador-soat',
    templateUrl: './informacion-tomador-soat.component.html',
    styleUrls: ['./informacion-tomador-soat.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgFor,
        NgIf,
        MsgErrorComponent,
        CalendarModule,
        NgSelectModule,
    ],
})
export class InformacionTomadorSoatComponent implements OnInit {
  @Output() eventoVolver: EventEmitter<any> = new EventEmitter();
  @Output() eventoGuardar: EventEmitter<any> = new EventEmitter();
  @Input() formulario: UntypedFormGroup;

  // formulario: FormGroup;
  listaFormulario: any[] = [];
  dataLocalFormularioSoat: OnBoardingSoat;
  desactivarBoton = false;
  maxDate: Date;
  yearRange: string;
  tuCotizacionId: number;

  constructor(
    private soatService: SoatService,
    private parametricosService: ParametricosService,
    private pasarelaService: PasarelaService,
    private ipService: IpService,
    private authService: AuthService,
    private generalCoreService: GeneralCoreService
  ) {
    this.listaFormulario = InformacionTomadorSoatForm.getForm();
    this.maxDate = new Date();
  }

  ngOnInit(): void {
    this.cargarDatosFormularioSoat();
    this.obtenerTipoDocumento();
    this.obtenerDepartamento();
    const minYear = new Date().getFullYear() - 70;
    const maxYear = new Date().getFullYear();
    this.yearRange = minYear + ':' + maxYear;
  }

  private cargarDatosFormularioSoat(): void {
    this.dataLocalFormularioSoat = new OnBoardingSoat(
      JSON.parse(localStorage.getItem('onboarding'))
    );

    if (
      this.dataLocalFormularioSoat &&
      this.dataLocalFormularioSoat.datosTomador
    ) {
      for (const key in this.dataLocalFormularioSoat.datosTomador) {
        if (key === 'fechaNacimiento') {
          this.formulario.controls[key].setValue(
            new Date(this.dataLocalFormularioSoat.datosTomador[key])
          );
        } else {
          this.formulario.controls[key].setValue(
            this.dataLocalFormularioSoat.datosTomador[key]
          );
        }
      }
    }
  }

  public getCondicionalInput(item) {
    return item.type != 'select' && item.type != 'date' && item.visible;
  }

  public obtenerTipoDocumento(): void {
    this.parametricosService
      .obtenerParametricas('TipoDocumento', 'soat')
      .subscribe((resp: any) => {
        this.listaFormulario.forEach((el) => {
          if (el.formControlName === 'tipoDocumento') {
            el.opciones = resp;
          }
        });
      });
  }

  obtenerDepartamento(): void {
    this.parametricosService
      .obtenerParametricas('departamentos', 'soat')
      .subscribe((resp: any) => {
        this.listaFormulario.forEach((el) => {
          if (el.formControlName === 'codDepartamento') {
            el.opciones = resp;
          }
        });
      });
  }

  changeSelect(value, item) {
    if (value.idDepartamento) {
      this.obtenerCiudades(value.idDepartamento);
      this.formulario.controls.codMunicipio.enable();
    }
    if (value.idTipoDocumento === 'A') {
      this.formulario.controls['numeroDocumento'].setValidators([
        Validators.required,
        Validators.pattern(patronValidacion.soloNumeros),
        Validators.minLength(9),
        Validators.maxLength(9),
      ]);
      this.formulario.controls['numeroDocumento'].updateValueAndValidity();
    }
    if (value.idTipoDocumento === 'C') {
      this.formulario.controls['numeroDocumento'].setValidators([
        Validators.required,
        Validators.pattern(patronValidacion.soloNumeros),
        Validators.minLength(6),
        Validators.maxLength(10),
      ]);
      this.formulario.controls['numeroDocumento'].updateValueAndValidity();
    }

    if (
      value.idTipoDocumento === 'A' &&
      item.formControlName === 'tipoDocumento'
    ) {
      this.listaFormulario.forEach((element) => {
        if (element.formControlName === 'fechaNacimiento') {
          element.visible = false;
          this.formulario.controls[element.formControlName].setValue(
            '01/01/2001'
          );
          this.formulario.controls[element.formControlName].setValidators([]);
          this.formulario.controls[
            element.formControlName
          ].updateValueAndValidity();
        }
      });
    }
    if (
      value.idTipoDocumento != 'A' &&
      item.formControlName === 'tipoDocumento'
    ) {
      this.listaFormulario.forEach((element) => {
        if (element.formControlName === 'fechaNacimiento') {
          element.visible = true;
          this.formulario.controls[element.formControlName].setValue('');
          this.formulario.controls[element.formControlName].setValidators([
            Validators.required,
            Validation.rangeDate,
          ]);
          this.formulario.controls[
            element.formControlName
          ].updateValueAndValidity();
        }
      });
    }
  }

  obtenerCiudades(id): void {
    this.soatService.obtenerCiudades(id).subscribe((resp: any) => {
      this.listaFormulario.forEach((el) => {
        if (el.formControlName === 'codMunicipio') {
          el.opciones = resp.data;
        }
      });
    });
  }

  volver(data: any): void {
    this.eventoVolver.emit({ componente: 'datosVehiculo' });
  }

  validarInput(event: any, tipoCampo: string): void {
    if (tipoCampo === 'number' && event.code === 'Period') {
      event.preventDefault();
    }

    if (tipoCampo === 'email') {
      if (event.code === 'Space') {
        event.preventDefault();
      }
    }
  }
}
