import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GeneralHttpService } from '../general-http/general-http.service';

const TOKEN_API_INFO = environment.tokenIpInfo;

@Injectable({
  providedIn: 'root',
})
export class IpService {
  private ipCliente: any;

  constructor(private http: GeneralHttpService) {}

  consultarIpCliente(): void {
    this.http
      .get(`?token=${TOKEN_API_INFO}`, 'https://ipinfo.io')
      .subscribe((resp: any) => {
        this.ipCliente = resp.ip;
      });
  }

  getIpCliente = () => this.ipCliente;
}
