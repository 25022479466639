import { Component, OnInit } from '@angular/core';
import { OnboardingService } from 'src/app/onboarding/services/onboarding/onboarding.service';
import { GeneralCoreService } from 'src/app/core/services/general-core/general-core.service';
import { OnBoardingAutos } from 'src/app/onboarding/models/onboarding.model';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { InformacionPagoComponent } from '../../informacion-pago/informacion-pago.component';
import { InformacionPlanesComponent } from '../../informacion-planes/informacion-planes.component';
import { InformacionTomadorBiciComponent } from '../informacion-tomador-bici/informacion-tomador-bici.component';
import { InformacionBiciComponent } from '../informacion-bici/informacion-bici.component';
import { NgFor, NgIf } from '@angular/common';


@Component({
    selector: 'app-onboarding-bici',
    templateUrl: './onboarding-bici.component.html',
    styleUrls: ['./onboarding-bici.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, InformacionBiciComponent, InformacionTomadorBiciComponent, InformacionPlanesComponent, InformacionPagoComponent]
})
export class OnboardingBiciComponent implements OnInit {
  onboardingData: OnBoardingAutos;

  pasos: any = {
    datosBici: true,
    datosTomador: false,
    datosCobertura: false,
    datosPago: false,
    datosFinalizar: false,
  };

  pasosNew: any[] = [
    {
      paso: 'datosBici',
      estado: false,
      visible: true,
      titulo: 'Para empezar vamos a necesitar algunos <b class="fw-bold">datos de tu bici o patineta</b>'
    },
    {
      paso: 'datosTomador',
      estado: false,
      visible: false,
      titulo: 'Ingresa los <b class="fw-bold"> datos del propietario</b>',
      subtitulo: 'Ten en cuenta que es la persona registrada en la <b class="fw-bold"> factura de compra</b>'
    },
    {
      paso: 'datosPlanes',
      estado: false,
      visible: false,
      titulo: '<b class="fw-bold">Elige el plan </b> que más se ajuste a tus necesidades'
    },
    {
      paso: 'datosCobertura',
      estado: false,
      visible: false,
      titulo: 'A medida que seleccioes las coberturas, se irá calculando el valor'
    },
    {
      paso: 'datosPago',
      estado: false,
      visible: false,
      titulo: 'Resumen de tu<b class="fw-bold"> cotización </b>'
    },
    {
      paso: 'datosFinalizar',
      estado: false,
      visible: false,
    },
  ];

  constructor(
    private onboardingService: OnboardingService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.onboardingData = JSON.parse(localStorage.getItem('onboarding'));
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.paso) {
        let tempo = 0;
        this.pasosNew.forEach(e => {
          if (e.paso === params.paso) {
            tempo++;
          }
        });

        if (params.paso === 'datosPago') {
          if (!this.onboardingService.planSeleccionado) {
            this.router.navigate(['/onboarding/bicicleta'], { queryParams: { paso: 'datosPlanes' } });
          }
        }

        if (tempo === 1) {
          if (this.onboardingData) {
            this.pasosNew.forEach(element => {
              element.visible = element.paso === params.paso ? true : false;
            });
          } else {
            this.router.navigate(['/onboarding/bicicleta'], { queryParams: { paso: 'datosBici' } });
          }

          this.pasosNew.forEach(element => {
            element.visible = element.paso === params.paso ? true : false;
          });
        } else {
          this.router.navigate(['/onboarding/bicicleta'], { queryParams: { paso: 'datosBici' } });
        }
      } else {
        if (this.onboardingData) {
          this.router.navigate(['/onboarding/bicicleta'], { queryParams: { paso: this.onboardingData.siguienteComponente } });
        } else {
          this.router.navigate(['/onboarding/bicicleta'], { queryParams: { paso: 'datosBici' } });
        }
      }
    });
  }

  ngOnInit(): void {
  }

  private validarPasoActual(paso: string, siguiente: boolean = false): void {

    if (siguiente) {
      this.router.navigate(['/onboarding/bicicleta'], { queryParams: { paso } });
    } else {
      if (!this.onboardingData[paso]) {
        this.router.navigate(['/onboarding/bicicleta'], { queryParams: { paso: 'datosBici' } });
      }
    }
  }

  guardar(data: any): void {

    this.consultarCoberturasPorPlanes(data);

    if (!this.onboardingData) {
      this.onboardingData = new OnBoardingAutos({});
    } else {
      const dataOnb = JSON.parse(localStorage.getItem('onboarding'));
      this.onboardingData = new OnBoardingAutos(dataOnb);
    }

    this.onboardingData = { ... this.onboardingData, ...data };
    this.onboardingData.idCotizacion = this.onboardingData.idCotizacion;

    localStorage.setItem('onboarding', JSON.stringify(this.onboardingData));
    this.validarPasoActual(data.siguienteComponente, true);
  }

  private consultarCoberturasPorPlanes(data: any): void {
    if (data.datosVehiculo) {
      const requestFork: any = {};
      const responseFork: any = {};
      data.datosPlanes.forEach(element => {
        // tslint:disable-next-line: max-line-length
        requestFork[element.codigoPlan] = this.onboardingService.obtenerCoberturasPorPlan(data.idCotizacion, data.datosVehiculo.ciudadCirculacion.id, element.codigoPlan);
        responseFork[element.codigoPlan] = {};
      });

      forkJoin(requestFork)
        .subscribe((respFork) => {
          const dataOnboarding = JSON.parse(localStorage.getItem('onboarding'));
          localStorage.setItem('onboarding', JSON.stringify({ ...dataOnboarding, ...{ datosCobertura: respFork } }));
        });
    }
  }

  volver(data: any): void {
    this.router.navigate(['/onboarding/bicicleta'], { queryParams: { paso: data.componente } });
  }

  getPasoVisible() {
    return this.pasosNew.find(paso => paso.visible)
  }

}
